import { createSlice } from '@reduxjs/toolkit';

export type EvaluationSliceType = {
  isLoading: boolean;
  isLoadingCategory: boolean;
  totalPages: number;
  forms: {
    id: number;
    name: string;
    type: number;
    requiredSignature: boolean;
    creationDate: string;
    createdBy: string;
    updateDate: string;
    updatedBy: string;
    culturas: { id: number; name: string }[];
    rankings: {
      id: number;
      minimumScore: number;
      maximumScore: number;
      farmerlevel: number;
      numberOfVisits: number;
    }[];
    questions: {
      id: number;
      description: string;
      questionCategoryId: number;
      options: { description: string; score: number }[];
    }[];
    active: boolean;
  }[];
  categories: {
    id: number;
    name: string;
    active: boolean;
    creationDate: string;
    updateDate: string;
  }[];
  error: Object | null;
};

const initialState: EvaluationSliceType = {
  isLoading: false,
  isLoadingCategory: false,
  totalPages: 0,
  forms: [],
  categories: [],
  error: null
};

const evaluationFormsSlice = createSlice({
  name: 'evaluationForms',
  initialState,
  reducers: {
    LIST_EVALUATION_FORMS: (state, action) => ({ ...state, isLoading: true }),
    LIST_EVALUATION_FORMS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      forms: payload.dataList,
      totalPages: payload.pagesCount
    }),
    LIST_EVALUATION_FORMS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    CREATE_EVALUATION_FORM: (state, action) => ({ ...state, isLoading: true }),
    CREATE_EVALUATION_FORM_SUCCESS: (state, { payload }) => ({ ...state, isLoading: false }),
    CREATE_EVALUATION_FORM_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    CHANGE_STATUS_FORM: (state, action) => ({ ...state, isLoading: true }),
    CHANGE_STATUS_FORM_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      forms: state.forms.map(i =>
        i.id !== payload
          ? i
          : {
              ...i,
              active: !i.active
            }
      )
    }),
    CHANGE_STATUS_FORM_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_FORM_CATEGORY: (state, action) => ({ ...state, isLoading: true }),
    LIST_FORM_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      categories: payload.dataList,
      totalPages: payload.pagesCount
    }),
    LIST_FORM_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    CREATE_FORM_CATEGORY: (state, action) => ({ ...state, isLoadingCategory: true }),
    CREATE_FORM_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoadingCategory: false,
      categories: [...state.categories, payload]
    }),
    CREATE_FORM_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoadingCategory: false,
      error: payload.error
    }),
    EDIT_FORM_CATEGORY: (state, action) => ({ ...state, isLoadingCategory: true }),
    EDIT_FORM_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoadingCategory: false,
      categories: state.categories.map(i =>
        i.id !== payload.id
          ? i
          : {
              ...i,
              ...payload
            }
      )
    }),
    EDIT_FORM_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoadingCategory: false,
      error: payload.error
    }),
    DELETE_FORM_CATEGORY: (state, action) => ({ ...state, isLoadingCategory: true }),
    DELETE_FORM_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoadingCategory: false,
      categories: state.categories.filter(({ id }) => id !== payload)
    }),
    DELETE_FORM_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoadingCategory: false,
      error: payload.error
    })
  }
});

const { actions, reducer } = evaluationFormsSlice;

export const {
  LIST_EVALUATION_FORMS,
  LIST_EVALUATION_FORMS_SUCCESS,
  LIST_EVALUATION_FORMS_FAILURE,
  CREATE_EVALUATION_FORM,
  CREATE_EVALUATION_FORM_SUCCESS,
  CREATE_EVALUATION_FORM_FAILURE,
  CHANGE_STATUS_FORM,
  CHANGE_STATUS_FORM_SUCCESS,
  CHANGE_STATUS_FORM_FAILURE,
  LIST_FORM_CATEGORY,
  LIST_FORM_CATEGORY_SUCCESS,
  LIST_FORM_CATEGORY_FAILURE,
  CREATE_FORM_CATEGORY,
  CREATE_FORM_CATEGORY_SUCCESS,
  CREATE_FORM_CATEGORY_FAILURE,
  EDIT_FORM_CATEGORY,
  EDIT_FORM_CATEGORY_SUCCESS,
  EDIT_FORM_CATEGORY_FAILURE,
  DELETE_FORM_CATEGORY,
  DELETE_FORM_CATEGORY_SUCCESS,
  DELETE_FORM_CATEGORY_FAILURE
} = actions;

export default reducer;
