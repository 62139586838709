import styled, { css } from 'styled-components';

export type ButtonProps = {
  buttonType?: 'primary' | 'secondary' | 'icon';
};

const wrapperModifiers = {
  primary: () => css`
    background-color: transparent;
    border: 1px solid ${({ theme: { colors } }) => colors.primary.default};
    border-radius: ${({ theme: { spacing } }) => spacing['2xs']};
    :hover {
      background-color: ${({ theme: { colors } }) => colors.primary.lightest};
    }
  `,
  secondary: () => css`
    background-color: transparent;
    :hover {
      color: ${({ theme: { colors } }) => colors.secondary.default};
    }
  `,
  icon: () => css`
    background: none;
    border-radius: ${({ theme: { spacing } }) => spacing['2xs']};
  `
};

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  table-layout: fixed;

  background-color: ${({ theme: { colors } }) => colors.neutral.lightest};
  border-collapse: collapse;
  border-radius: 8px;

  tbody tr:nth-child(odd) {
    background-color: ${({ theme: { colors } }) =>
      colors.colorWithOpacityGenerator('background.default', 0.7)};
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  height: 60px;
`;

export const TableHeaders = styled.th`
  ${({ theme: { colors, typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      border-bottom: 1px solid ${colors.neutral.default};
    `};
`;

export const TableContent = styled.td`
  ${({ theme: { typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const TableText = styled.p`
  ${({ theme: { colors, typography, spacing } }) =>
    css`
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { spacing } }) => spacing.xs};
`;

export const RegularButton = styled.button<ButtonProps>`
  ${({ theme: { colors, typography, spacing }, buttonType = 'primary' }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${spacing['2xs']};
      color: ${colors.black};
      border: none;

      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      cursor: pointer;

      ${wrapperModifiers[buttonType]};
    `};
`;

export const DepartmentPlusNumber = styled.div`
  ${({ theme: { colors, typography, spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    border-radius: 4px;
    border: 1px solid ${colors.neutral.default};
    background-color: ${colors.neutral.light};
    position: relative;

    :hover {
      background-color: ${colors.neutral.default};
      ${DepartmentPlusList} {
        display: flex;
      }
    }

    p {
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
    }
  `};
`;

export const DepartmentPlusList = styled.ul`
  ${({ theme: { colors, spacing } }) => css`
    display: none;
    width: 192px;
    max-height: 186px;
    flex-direction: column;

    border-radius: 4px;
    background: ${colors.neutral.light};
    position: absolute;
    top: 25px;
    right: 0px;
    z-index: 3;
    transform: translateX(2px);

    overflow-y: auto;
    box-shadow: 0px 2px 10px 2px rgba(141, 151, 158, 0.2);

    p {
      width: 100%;
      white-space: normal;
      pointer-events: none;
      padding: 8px;
    }

    li {
      list-style-type: none;
      :hover {
        background-color: ${colors.neutral.default};
      }
    }
    ::-webkit-scrollbar {
      background-color: ${colors.neutral.light};
      width: ${spacing['2xs']};
      border-radius: ${spacing['2xs']};
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.neutral.default};
      border-radius: ${spacing['2xs']};
    }
  `};
`;
