import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 40px 16px 0px 16px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
  overflow: auto;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
