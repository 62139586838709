import { createSlice } from '@reduxjs/toolkit';

type CustodySliceType = {
  isLoading: boolean;
  custodies: {
    id: number;
    name: string;
    email: string;
    identificationType: number;
    identificationNumber: string;
    phoneNumber: string;
    mobileNumber: string;
    status: number;
    cargo: { id: number; name: string };
    departments: { id: number; name: string }[];
  }[];
  searchedText: string;
  tabIndex: number;
  pageIndex: number;
  pageSize: number;
  totalData: number;
  pagesCount: number;
  error: Object | null;
};

const initialState: CustodySliceType = {
  isLoading: true,
  custodies: [],
  searchedText: '',
  tabIndex: 0,
  pageIndex: 1,
  pageSize: 10,
  totalData: 100,
  pagesCount: 10,
  error: null
};

const custodySlice = createSlice({
  name: 'custodies',
  initialState,
  reducers: {
    LIST_CUSTODIES: (state, action) => ({ ...state, isLoading: true }),
    LIST_CUSTODIES_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      custodies: payload.dataList,
      pageIndex: payload.pageIndex,
      pageSize: payload.pageSize,
      totalData: payload.totalData,
      pagesCount: payload.pagesCount
    }),
    LIST_CUSTODIES_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    APPROVE_CUSTODY: (state, action) => ({ ...state, isLoading: true }),
    APPROVE_CUSTODY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      custodies: state.custodies.filter(custody => custody.id !== payload.id)
    }),
    APPROVE_CUSTODY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DENY_CUSTODY: (state, action) => ({ ...state, isLoading: true }),
    DENY_CUSTODY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      custodies: state.custodies.filter(i => i.id !== payload.id)
    }),
    DENY_CUSTODY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_CUSTODY: (state, action) => ({
      ...state,
      isLoading: true
    }),
    EDIT_CUSTODY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      custodies: state.custodies.map(custody =>
        custody.id !== payload.id ? custody : { ...custody, payload }
      )
    }),
    EDIT_CUSTODY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_CUSTODY: (state, action) => ({ ...state, isLoading: true }),
    DELETE_CUSTODY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      custodies: state.custodies.filter(({ id }) => id !== payload.id)
    }),
    DELETE_CUSTODY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_TAB_INDEX: (state, { payload }) => ({ ...state, tabIndex: payload }),
    EDIT_SEARCHED_TEXT: (state, { payload }) => ({ ...state, searchedText: payload }),
    EDIT_PAGE_INDEX: (state, { payload }) => ({ ...state, pageIndex: payload })
  }
});

const { actions, reducer } = custodySlice;

export const {
  LIST_CUSTODIES,
  LIST_CUSTODIES_SUCCESS,
  LIST_CUSTODIES_FAILURE,
  APPROVE_CUSTODY,
  APPROVE_CUSTODY_SUCCESS,
  APPROVE_CUSTODY_FAILURE,
  DENY_CUSTODY,
  DENY_CUSTODY_SUCCESS,
  DENY_CUSTODY_FAILURE,
  EDIT_CUSTODY,
  EDIT_CUSTODY_SUCCESS,
  EDIT_CUSTODY_FAILURE,
  DELETE_CUSTODY,
  DELETE_CUSTODY_SUCCESS,
  DELETE_CUSTODY_FAILURE,
  EDIT_TAB_INDEX,
  EDIT_SEARCHED_TEXT,
  EDIT_PAGE_INDEX
} = actions;

export default reducer;
