import { all } from 'redux-saga/effects';

import authSaga from './authSaga';
import categoriesSaga from './categoriesSaga';
import custodiesSaga from './custodiesSaga';
import campaignsSaga from './campaignsSaga';
import adminUserSaga from './adminUsersSaga';
import evaluationFormsSaga from './evaluationFormsSaga';
import generalInfoSaga from './generalInfoSaga';
import farmersSaga from './farmersSaga';
import distributorsSaga from './distributorsSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    categoriesSaga(),
    custodiesSaga(),
    campaignsSaga(),
    adminUserSaga(),
    evaluationFormsSaga(),
    generalInfoSaga(),
    farmersSaga(),
    distributorsSaga()
  ]);
}

export default rootSaga;
