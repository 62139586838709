import { useState, useEffect } from 'react';
import { useTranslation, Pagination, EmptyPage, SearchField } from '@basfagro/core';
import * as S from './DistributorsApprovations.styles';

import { TabOptions } from '../Custody/Custody.mock';
import Loading from '../../../assets/Loading.gif';

import { useDistributors, useAuth } from '../../../hooks';
import { Header, TabBar, ConfirmModal, DistributorsTable } from '../../../components';

const DistributorsApprovations = () => {
  const { t } = useTranslation();

  const {
    listDistributors,
    evaluateDistributor,
    deleteDistributor,
    distributors,
    pageIndex,
    pagesCount,
    isLoading,
    tabIndex,
    searchedText,
    editSearchedText,
    editTabIndex,
    editPageIndex
  } = useDistributors();
  const { hasEditPermission } = useAuth();

  const [searchValue, setSearchValue] = useState(searchedText);
  const [status, setStatus] = useState(tabIndex + 3);
  const [page, setPage] = useState(pageIndex);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showApproveModal, setApproveModal] = useState(false);
  const [showDenyModal, setDenyModal] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState({ name: '', id: 0, type: '' });

  useEffect(() => {
    if (searchValue === '') {
      listDistributors(page, 10, status);
    } else {
      listDistributors(page, 10, status, searchValue, () => {
        setPage(prev => (pagesCount < page ? 1 : prev));
      });
    }
    editSearchedText(searchValue);
    editPageIndex(page);
  }, [listDistributors, selectedDistributor, status, page, searchValue]);

  const onPressApprove = (id, name) => {
    setSelectedDistributor({ name, id, type: 'approve' });
    setApproveModal(true);
  };

  const onPressDeny = (id, name) => {
    setSelectedDistributor({ name: name, id: id, type: 'deny' });
    setDenyModal(true);
  };

  const onPressDelete = (id, name) => {
    setSelectedDistributor({ name: name, id: id, type: 'delete' });
    setDeleteModal(true);
  };

  const onChangeTab = id => {
    setStatus(id + 3);
    setPage(1);
    editTabIndex(id);
    setSearchValue('');
  };

  const onPressConfirm = () => {
    const { type, id } = selectedDistributor;
    if (type === 'approve') {
      evaluateDistributor(id, true, () => setApproveModal(false));
      setApproveModal(false);
    }
    if (type === 'deny') {
      evaluateDistributor(id, false, () => setDenyModal(false));
      setDenyModal(false);
    }
    if (type === 'delete') {
      deleteDistributor(id, () => setDeleteModal(false));
      setDeleteModal(false);
    }
  };

  return (
    <S.StyledContainer>
      <Header title={t('distributors.title')} subtitle={t('distributors.subtitle')} />
      <TabBar options={TabOptions()} onPressOption={onChangeTab} selectedOpt={tabIndex} />
      <SearchField
        placeholder={t('distributors.search')}
        value={searchValue}
        onChangeText={setSearchValue}
        marginTop='16px'
        marginBottom='24px'
      />
      {isLoading ? (
        <S.StyledLoading src={Loading} />
      ) : distributors.length ? (
        <>
          <DistributorsTable
            headers={{
              name: t('fields.name'),
              contactPhones: t('fields.phone'),
              department: t('fields.department'),
              email: t('fields.mail')
            }}
            tableData={distributors}
            type={status === 3 ? 'inProcess' : status === 4 ? 'accepted' : 'denied'}
            onPressApprove={onPressApprove}
            onPressDeny={onPressDeny}
            onPressDelete={onPressDelete}
            canEdit={!!hasEditPermission('Distributor')}
          />
          <Pagination pagesTotal={pagesCount} page={page} setPage={setPage} />
        </>
      ) : (
        <EmptyPage />
      )}
      {showDeleteModal && (
        <ConfirmModal
          isVisible={showDeleteModal}
          setVisibility={() => setDeleteModal(prev => !prev)}
          onConfirm={onPressConfirm}
          title={t('modals.distributor.deleteTitle')}
          content={t('modals.distributor.deleteContent', {
            name: selectedDistributor.name
          })}
        />
      )}
      {showApproveModal && (
        <ConfirmModal
          isVisible={showApproveModal}
          setVisibility={() => setApproveModal(prev => !prev)}
          onConfirm={onPressConfirm}
          title={t('modals.distributor.acceptTitle')}
          content={t('modals.distributor.acceptContent', {
            name: selectedDistributor.name
          })}
        />
      )}
      {showDenyModal && (
        <ConfirmModal
          isVisible={showDenyModal}
          setVisibility={() => setDenyModal(prev => !prev)}
          onConfirm={onPressConfirm}
          title={t('modals.distributor.refusalTitle')}
          content={t('modals.distributor.refusalContent', {
            name: selectedDistributor.name
          })}
        />
      )}
    </S.StyledContainer>
  );
};

export default DistributorsApprovations;
