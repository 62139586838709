import axios from 'axios';

const baseURL = process.env.REACT_APP_URL_HOST;

const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const setAuthorization = token => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

export default api;
