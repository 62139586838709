import { useTranslation, Checkbox } from '@basfagro/core';
import * as S from './CheckOptions.styles';

type CheckOptionsProps = {
  label?: string;
  col?: number;
  onSelect: Function;
  value: boolean;
  disabled?: boolean;
};

const CheckOptions = ({
  label,
  col = 16,
  onSelect,
  value,
  disabled = false
}: CheckOptionsProps) => {
  const { t } = useTranslation();
  return (
    <S.Container width={(100 / 16) * col}>
      {{ label } && (
        <S.Label htmlFor={label} disabled={disabled}>
          {label}
        </S.Label>
      )}
      <S.Row>
        <Checkbox
          label={t('evaluationForm.yes')}
          isChecked={value}
          onClickFunc={onSelect}
          disabled={disabled}
        />
        <Checkbox
          label={t('evaluationForm.no')}
          isChecked={!value}
          onClickFunc={onSelect}
          disabled={disabled}
        />
      </S.Row>
    </S.Container>
  );
};

export default CheckOptions;
