import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextField, useTranslation } from '@basfagro/core';

import * as S from './NewFormCategoryModal.styles';

import { useEvaluationForms } from '../../hooks';

import { Modal, FeedbackModal } from '..';

type ModalProps = {
  visibility: boolean;
  toggleVisibility: Function;
  categoryToEdit?: { id: number; name: string };
  error?: boolean;
  hasPermission?: boolean;
};

export const NewFormCategoryModal = ({
  visibility = false,
  toggleVisibility,
  categoryToEdit,
  error,
  hasPermission = false
}: ModalProps) => {
  const { t } = useTranslation();
  const { createFormCategory, editFormCategory } = useEvaluationForms();
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  const { confirmButton, title } = categoryToEdit
    ? {
        confirmButton: t('buttons.confirmButton'),
        title: t('modals.categories.editCategory')
      }
    : {
        confirmButton: t('modals.categories.createCategory'),
        title: t('modals.categories.createCategory')
      };

  const validationSchema = yup.object({
    name: yup.string().required(t('errors.name'))
  });

  const {
    register,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm<{ name: string }>({
    resolver: yupResolver(validationSchema),
    defaultValues: { name: '' }
  });
  const values = watch();

  useEffect(() => {
    if (categoryToEdit) {
      setValue('name', categoryToEdit.name);
    } else {
      setValue('name', '');
    }
    clearErrors();
  }, [categoryToEdit, visibility]);

  const onConfirm = categoryName => {
    if (categoryToEdit) {
      editFormCategory(
        categoryToEdit?.id,
        categoryName.name,
        feedbackText => {
          setFBModal({
            state: true,
            icon: 'IcPositiveFB',
            text: feedbackText
          });
        },
        feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
      );
    } else {
      createFormCategory(
        categoryName.name,
        feedbackText => {
          setFBModal({
            state: true,
            icon: 'IcPositiveFB',
            text: feedbackText
          });
        },
        feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
      );
    }
  };

  return (
    <Modal visibility={visibility} toggleVisibility={toggleVisibility} hasExit>
      <S.ModalWrapper>
        <S.Text>{title}</S.Text>
        <TextField
          label={t('modals.categories.categoryName')}
          placeholder={t('riteCategory.newCategoryModal.namePlaceholder')}
          value={values.name}
          paddingRight={0}
          {...register('name')}
          error={errors.name}
          readOnly={!hasPermission}
        />
        {hasPermission && (
          <S.ButtonsWrapper>
            <S.Button buttonType='secondary' onClick={() => toggleVisibility()}>
              {t('buttons.cancelButton')}
            </S.Button>
            <S.Button buttonType='primary' onClick={handleSubmit(onConfirm)}>
              {confirmButton}
            </S.Button>
          </S.ButtonsWrapper>
        )}
        {!hasPermission && (
          <S.ButtonsWrapper>
            <S.Button buttonType='secondary' onClick={() => toggleVisibility()}>
              {t('evaluationForm.goBack')}
            </S.Button>
          </S.ButtonsWrapper>
        )}
      </S.ModalWrapper>
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => {
            setFBModal({ ...FBModal, state: !FBModal.state });
            toggleVisibility(false);
          }}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </Modal>
  );
};

export default NewFormCategoryModal;
