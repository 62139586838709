import * as yup from 'yup';
import i18n from '../../services/translations';
import { LandPlotModel } from '../../types/models';

export type ModalProps = {
  isVisible: boolean;
  setVisibility: Function;
  onConfirm: Function;
  landplot?: LandPlotModel;
  hasPermission?: boolean;
};

export const validationSchema = yup
  .object({
    name: yup.string().required(i18n.t('validations.required')),
    areaInHectares: yup.string().required(i18n.t('validations.required')),
    cultive: yup.string().required(i18n.t('validations.required')),
    variety: yup.string().required(i18n.t('validations.required'))
  })
  .required();
