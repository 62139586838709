import { useEffect, useState } from 'react';
import { useTranslation, SearchField, EmptyPage } from '@basfagro/core';

import * as S from './Farm.styles';

import { useFarmers } from '../../../hooks';
import { ActionTable, EmployeeModal } from '../../../components';
import { EmployeeModel } from '../../../types/models';

type EmployeesProps = {
  farmID: string;
};

const Employees = ({ farmID }: EmployeesProps) => {
  const { t } = useTranslation();
  const { farmEmployees, listFarmEmployees, selectedFarm } = useFarmers();
  const [EModalVisibility, setEVisibility] = useState(false);
  const [choosenEmployee, setChoosenEmployee] = useState({} as EmployeeModel | undefined);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText === '') {
      listFarmEmployees(farmID);
    } else {
      listFarmEmployees(farmID, searchText);
    }
  }, [listFarmEmployees, searchText, selectedFarm]);

  const onPressEdit = employee => {
    setChoosenEmployee(employee);
    setEVisibility(true);
  };

  return (
    <>
      <S.StyledWrapper>
        <SearchField
          placeholder={t('farmers.searchEmployee')}
          onChangeText={setSearchText}
          marginBottom='16px'
        />
        {farmEmployees.length ? (
          <ActionTable
            headers={{
              name: t('fields.name'),
              email: t('fields.mail')
            }}
            tableData={farmEmployees}
            onPressDelete={() => {}}
            onPressEdit={onPressEdit}
          />
        ) : (
          <EmptyPage />
        )}
      </S.StyledWrapper>
      {EModalVisibility && (
        <EmployeeModal
          isVisible={EModalVisibility}
          setVisibility={setEVisibility}
          employee={choosenEmployee}
        />
      )}
    </>
  );
};

export default Employees;
