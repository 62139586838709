import styled, { css } from 'styled-components';

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 40px 16px 0px 16px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const Title = styled.h1`
  ${({ theme: { colors, typography, spacing } }) => css`
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.lg};
    line-height: 38px;
    color: ${colors.black};
    margin-top: ${spacing.lg};
  `};
`;

export const StyledLoading = styled.img`
  width: 8%;
  height: auto;
  align-self: center;
`;

export const ChartsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ChartTitle = styled.h2`
  ${({ theme: { colors, typography, spacing } }) => css`
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.xm};
    line-height: 28px;
    color: ${colors.black};
    margin-bottom: ${spacing['2xs']};
  `};
`;

export const ChartDivisor = styled.hr`
  border: none;
  height: 1px;
  color: #8c92a9;
  background-color: #8c92a9;
  margin: ${({ theme: { spacing } }) => spacing.sm} 0;
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 445px;
  height: 450px;
  padding: 24px;
  margin-top: 16px;
  background-color: white;
  border: 1px solid ${({ theme: { colors } }) => colors.neutral.default};
  border-radius: 20px;
`;

export const NoDataWarn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const WarnText = styled.p`
  text-align: center;
`;

export const StyledImageCircle = styled.div`
  ${({ theme: { colors } }) => css`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: ${colors.secondary.lightest};
    opacity: 1;
  `};
`;
