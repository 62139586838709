import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useTranslation, TextField, Button } from '@basfagro/core';

import * as S from '../ResetPassword.styles';

import greenLogo from '../../../assets/images/logo/greenSimpleLogo.svg';

import { SendTokenValidation } from '../ResetPassword.utils';
import { sendPasswordTokenCase } from '../../../useCases/resetPasswordUseCase';

const SendToken = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SendTokenValidation),
    defaultValues: { email: '' }
  });

  const onSubmit = formData => {
    sendPasswordTokenCase(formData.email, () => {
      navigate('get-token', { state: formData.email });
    });
  };

  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <img alt='Basf logo' src={greenLogo} />
      </S.LogoWrapper>
      <S.ContentWrapper onSubmit={handleSubmit(onSubmit)}>
        <S.Title>{t('reset.forgotTitle')}</S.Title>
        <S.Subtitle>{t('reset.forgotSubtitle')}</S.Subtitle>
        <TextField
          label={t('fields.mail')}
          placeholder={t('textfields.placeholderLogin')}
          {...register('email')}
          error={errors.email}
        />
        <S.ButtonsRow>
          <Button
            type='button'
            buttonType='secondary'
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            {t('buttons.cancelButton')}
          </Button>
          <Button buttonType='primary' type='submit'>
            {t('buttons.confirmButton')}
          </Button>
        </S.ButtonsRow>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default SendToken;
