import styled, { css } from 'styled-components';

type ContentProps = {
  bold?: boolean;
};

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  border: 1px solid ${({ theme: { colors } }) => colors.neutral.default};
  border-radius: 10px;
  padding: ${({ theme: { spacing } }) => spacing.sm};
  margin-top: 18px;
  background-color: ${({ theme: { colors } }) => colors.white};
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 22%;
  flex-direction: column;
  margin-right: ${({ theme: { spacing } }) => spacing.sm};
  :nth-last-child(-n + 2) {
    margin: 0;
    width: 16%;
  }
`;

export const CardContent = styled.p<ContentProps>`
  ${({ theme: { colors, typography }, bold = false }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.neutral.darkest};
    font-size: ${typography.fontSize.xm};
    font-weight: ${bold ? typography.fontWeight.bold : typography.fontWeight.regular};
  `};
`;
