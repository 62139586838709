import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import {
  LIST_CUSTODIES,
  APPROVE_CUSTODY,
  DENY_CUSTODY,
  EDIT_CUSTODY,
  DELETE_CUSTODY,
  EDIT_TAB_INDEX,
  EDIT_SEARCHED_TEXT,
  EDIT_PAGE_INDEX
} from '../store/slices/custodiesSlice';

export const useCustodies = () => {
  const dispatch = useDispatch();

  const {
    custodies,
    isLoading,
    error,
    tabIndex,
    pageIndex,
    pageSize,
    totalData,
    pagesCount,
    searchedText
  } = useSelector(({ custodies }: RootState) => custodies);

  const listCustodies = useCallback(
    (pgSize, pgIndex, status, name?, successCallback?) =>
      dispatch(LIST_CUSTODIES({ pgSize, pgIndex, status, name, successCallback })),
    [dispatch]
  );

  const approveCustody = useCallback(
    (id, successCallback?) => dispatch(APPROVE_CUSTODY({ id, successCallback })),
    [dispatch]
  );

  const denyCustody = useCallback(
    (id, successCallback?) => dispatch(DENY_CUSTODY({ id, successCallback })),
    [dispatch]
  );

  const editCustody = useCallback(
    (id, newCustody, successCallBack?) =>
      dispatch(EDIT_CUSTODY({ id, newCustody, successCallBack })),
    [dispatch]
  );

  const deleteCustody = useCallback(
    (id, successCallBack?) => dispatch(DELETE_CUSTODY({ id, successCallBack })),
    [dispatch]
  );

  const editTabIndex = useCallback(id => dispatch(EDIT_TAB_INDEX(id)), [dispatch]);

  const editSearchedText = useCallback(text => dispatch(EDIT_SEARCHED_TEXT(text)), [dispatch]);

  const editPageIndex = useCallback(page => dispatch(EDIT_PAGE_INDEX(page)), [dispatch]);

  return {
    custodies,
    isLoading,
    error,
    tabIndex,
    pageIndex,
    pageSize,
    totalData,
    pagesCount,
    searchedText,
    listCustodies,
    approveCustody,
    denyCustody,
    editCustody,
    deleteCustody,
    editTabIndex,
    editSearchedText,
    editPageIndex
  };
};
