import { useTranslation, Button } from '@basfagro/core';

import * as S from './FeedbackModal.styles';
import { Modal, Icon } from '..';

type FeedbackModalProps = {
  icon?: string;
  iconColor?: string;
  text: string;
  isVisible: boolean;
  setVisibility: Function;
};

const FeedbackModal = ({
  icon = 'IcPositiveFB',
  iconColor,
  text,
  isVisible,
  setVisibility
}: FeedbackModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal visibility={isVisible} toggleVisibility={setVisibility}>
      <S.Wrapper>
        <Icon icon={icon} color={iconColor} width={42} height={42} />
        <S.ModalText>{text}</S.ModalText>
        <Button buttonType='primary' col={6} height='42px' onClick={() => setVisibility()}>
          {t('buttons.confirmButton')}
        </Button>
      </S.Wrapper>
    </Modal>
  );
};

export default FeedbackModal;
