import styled, { css, CSSProperties } from 'styled-components';

type RowProp = { flexEnd?: boolean; marginTop?: number };
type PontuationTFProp = { error?: boolean };

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 40px 16px 16px 16px;
  position: relative;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
`;

export const StyledRow = styled.div<RowProp>`
  ${({ flexEnd, marginTop = 0 }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: ${flexEnd ? 'flex-end' : 'flex-start'};
    margin-top: ${marginTop}px;
  `}
`;

export const ModalContainer = styled.div`
  width: 820px;
  max-height: calc(100vh - 100px);
  overflow: auto;
`;

export const ModalButtonRow = styled(StyledRow)`
  justify-content: center;
`;

export const PontuationsRow = styled(StyledRow)`
  ${({ theme: { colors } }) => css`
    align-items: center;
    background-color: white;
    padding: 16px 12px 16px 12px;
    border-bottom: 1px solid ${colors.neutral.default};
    :nth-child(odd) {
      background-color: ${colors.colorWithOpacityGenerator('background.default', 0.4)};
    }
  `}
`;

export const QuestionsContainer = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
`;

export const QuestionsTableRow = styled.div`
  ${({ theme: { spacing, colors } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${spacing.sm} ${spacing.xs};
    border-bottom: 1px solid ${colors.neutral.default};

    :last-of-type {
      margin-bottom: ${spacing.md};
    }
    :nth-child(even) {
      background-color: ${({ theme: { colors } }) => colors.white};
    }
  `}
`;

export const QuestionsTableCell = styled.div<CSSProperties>`
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : 35)}%;
  flex-wrap: wrap;
`;

export const QuestionsTableText = styled.p`
  ${({ theme: { colors, typography } }) => css`
    display: flex;
    white-space: normal;
    text-overflow: ellipsis;
    font-weight: ${typography.fontWeight.regular};
    font-size: ${typography.fontSize.xm};
    line-height: 28px;
    color: ${colors.neutral.darkest};
    cursor: default;
  `};
`;

export const QuestionsTableHeader = styled.p`
  ${({ theme: { colors, typography } }) => css`
    display: flex;
    white-space: normal;
    text-overflow: ellipsis;
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.md};
    line-height: 32px;
    color: ${colors.neutral.darkest};
    cursor: default;
  `};
`;

export const PontuationsContainer = styled.div`
  display: flex;
  margin-right: 12px;
  margin-top: 2px;
  :last-child {
    margin-right: 0px;
  }
`;

export const PontuationsText = styled(QuestionsTableHeader)`
  font-size: ${({ theme: { typography } }) => typography.fontSize.xs};
`;

export const NumberContainer = styled.div`
  ${({ theme: { colors, typography, spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    width: fit-content;
    height: 26px;

    border-radius: ${spacing['2xs']};
    background-color: ${colors.secondary.default};

    color: ${colors.white};
    text-align: center;
    text-overflow: ellipsis;
    font-weight: ${typography.fontWeight.regular};
    font-size: ${typography.fontSize.xm};

    margin-left: ${spacing['2xs']};
    margin-right: ${spacing.md};
    padding: 2px;

    :last-child {
      margin-right: 0;
    }
  `}
`;

export const Bubble = styled.div`
  ${({ theme: { colors, spacing } }) => css`
    display: flex;
    flex: 1;
    min-height: 68px;
    padding: 15px;
    margin: ${spacing.lg} 0;
    border-radius: 20px;
    background-color: ${colors.colorWithOpacityGenerator('neutral.default', 0.6)};
    :last-child {
      margin-left: ${spacing.xs};
    }
  `};
`;

export const RegularButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: ${({ theme: { spacing } }) => spacing.lg};

  border: none;
  background: none;
  cursor: pointer;
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing.xs};
`;

export const SectionTitle = styled.h1`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.lg};
    font-weight: ${typography.fontWeight.bold};
    line-height: 36px;
  `};
`;

export const SectionSubTitle = styled.h2`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.regular};
    line-height: 28px;
  `};
`;

export const FormDivisor = styled.hr`
  ${({ theme: { spacing, colors } }) => css`
    border: none;
    height: 1px;
    color: ${colors.neutral.dark};
    background-color: ${colors.neutral.dark};
    margin: ${spacing.sm} 0;
  `};
`;

export const PontuationTextField = styled.input<PontuationTFProp>`
  ${({ theme: { colors, typography, spacing }, error }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 26px;
    border: none;
    border-radius: ${spacing['2xs']};
    background-color: ${error ? colors.tertiary.default : colors.secondary.default};

    text-align: center;
    text-overflow: ellipsis;
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.xm};
    color: ${colors.white};

    outline: none;
    :last-child {
      margin-right: 0;
    }
  `}
`;

export const PontuationTFWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  height: 44px;
  margin-top: 20px;
`;

export const StyledError = styled.h2`
  ${({ theme: { colors, typography, spacing } }) => css`
    width: fit-content;

    color: ${colors.tertiary.default};
    font-size: ${typography.fontSize.xm};
    font-weight: ${typography.fontWeight.semiBold};
    line-height: 28px;
    
    background-color: ${colors.colorWithOpacityGenerator('tertiary.lightest', 0.3)}};
    
    padding: ${spacing.xs};
    border-radius: ${spacing.xs};
  `};
`;
