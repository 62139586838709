import customYup from '../utils/customValidation';
import i18n from '../services/translations';
import { identificationTypes } from './generalInfo';

export const getValidationByDocumentType = documentType => {
  switch (documentType) {
    case identificationTypes[0].name: // CC
      return customYup.string().colombiaCC().required(i18n.t('clearfield.form.requiredField'));
    case identificationTypes[1].name: // CE
      return customYup.string().colombiaCE().required(i18n.t('clearfield.form.requiredField'));
    case identificationTypes[2].name: // PASSPORT
      return customYup.string().passport().required(i18n.t('clearfield.form.requiredField'));
    default:
      return customYup.string().required(i18n.t('clearfield.form.requiredField'));
  }
};
