import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, useTranslation, Select, SearchSelect, Button } from '@basfagro/core';
import * as S from './NewUserModal.styles';
import {
  UserType,
  userDefaultValues,
  validationSchema,
  permissions,
  adminTypes
} from './NewUserModal.utils';
import { Modal } from '../';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdminUser } from '../../types/models';

type NewUserModalProps = {
  visibility: boolean;
  toggleVisibility: Function;
  onSave: Function;
  user?: AdminUser;
  hasPermission?: boolean;
};

const TYPE_ADMIN = 1;
const TYPE_ADMIN_AP = 2;

export const NewUserModal = ({
  visibility = false,
  toggleVisibility,
  onSave,
  user,
  hasPermission = false
}: NewUserModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<UserType>({
    resolver: yupResolver(validationSchema),
    defaultValues: userDefaultValues
  });

  useEffect(() => {
    register('role');
    register('editPermission');
    register('viewPermission');
  }, [register]);

  useEffect(() => {
    if (user) {
      setValue('name', user?.name || '');
      setValue('email', user?.email || '');
      setValue('phoneNumber', user?.phoneNumber);
      setValue(
        'role',
        user?.administratorType === TYPE_ADMIN ? 'Administrator' : 'Administrator AP'
      );
      setValue(
        'editPermission',
        user?.permissions
          ?.filter(({ action }) => action === 2)
          .map(p => permissions.find(({ idEdit }) => idEdit === p.id)?.name) || []
      );
      setValue(
        'viewPermission',
        user?.permissions
          ?.filter(({ action }) => action === 1)
          .map(p => permissions.find(({ idView }) => idView === p.id)?.name) || []
      );
    } else {
      reset(userDefaultValues);
    }
  }, [user]);

  const values = watch();

  const EisSelected = element => {
    if (values.editPermission.includes(element)) {
      setValue(
        'editPermission',
        values.editPermission.filter(value => value !== element)
      );
    } else {
      setValue('editPermission', [...values.editPermission, element]);
      if (values.viewPermission.includes(element)) {
        setValue(
          'viewPermission',
          values.viewPermission.filter(value => value !== element)
        );
      }
    }
  };

  const VisSelected = element => {
    values.viewPermission.includes(element)
      ? setValue(
          'viewPermission',
          values.viewPermission.filter(value => value !== element)
        )
      : setValue('viewPermission', [...values.viewPermission, element]);
  };

  useEffect(() => {
    if (typeID() === TYPE_ADMIN_AP) {
      setValue(
        'editPermission',
        permissions.map(({ name }) => name)
      );
      setValue('viewPermission', []);
    }
  }, [values.role]);

  const permissionsIDs = () => {
    return values.editPermission
      .map(p => permissions.find(i => p === i.name)?.idEdit)
      .concat(values.viewPermission.map(p => permissions.find(i => p === i.name)?.idView));
  };

  const typeID = () => {
    return adminTypes.find(type => type.name === values.role)?.id;
  };

  const onPressSave = data => {
    if (user) {
      onSave(user?.id, {
        phoneNumber: data.phoneNumber,
        administratorType: typeID(),
        permissionsId: permissionsIDs()
      });
    } else {
      onSave({
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        administratorType: typeID(),
        permissionsId: permissionsIDs(),
        language: localStorage.getItem('i18nextLng') || 'en'
      });
    }
  };

  return (
    <Modal
      visibility={visibility}
      toggleVisibility={() => {
        toggleVisibility();
        reset(userDefaultValues);
      }}
      hasExit
    >
      <S.StyledWrapper>
        <S.StyledRow>
          <TextField
            label={t('fields.name')}
            placeholder={t('textfields.typeName')}
            col={8}
            {...register('name')}
            error={errors.name}
            readOnly={!!user}
          />
          <TextField
            label={t('fields.mail')}
            placeholder={t('textfields.typeEmail')}
            col={8}
            {...register('email')}
            error={errors.email}
            readOnly={!!user}
          />
        </S.StyledRow>
        <S.StyledRow>
          <TextField
            label={t('fields.phone')}
            placeholder={t('textfields.typePhoneNumber')}
            col={8}
            {...register('phoneNumber')}
            error={errors.phoneNumber}
            readOnly={!hasPermission}
          />
          <Select
            label={t('fields.role')}
            col={8}
            optionsList={adminTypes.map(({ name }) => name)}
            onSelect={v => setValue('role', v, { shouldValidate: true })}
            value={values.role}
            error={errors.role}
            disabled={!hasPermission}
          />
        </S.StyledRow>
        <S.StyledRow>
          <SearchSelect
            optionsList={permissions
              .map(({ name }) => name)
              .filter(element => !values.editPermission.includes(element))}
            selectedOptions={typeID() === TYPE_ADMIN_AP ? [] : values.editPermission}
            onSelect={e => {
              if (hasPermission) EisSelected(e);
            }}
            label={t('fields.editPermission')}
            placeholder={t('fields.selectPermission')}
            disabledPlaceholder={t('fields.allPermissions')}
            disabled={typeID() === TYPE_ADMIN_AP || !hasPermission}
          />
        </S.StyledRow>
        <S.StyledRow>
          <SearchSelect
            optionsList={permissions
              .map(({ name }) => name)
              .filter(
                element =>
                  !values.editPermission.includes(element) &&
                  !values.viewPermission.includes(element)
              )}
            selectedOptions={typeID() === TYPE_ADMIN_AP ? [] : values.viewPermission}
            onSelect={e => {
              if (hasPermission) VisSelected(e);
            }}
            label={t('fields.viewPermission')}
            placeholder={t('fields.selectPermission')}
            disabledPlaceholder={t('fields.allPermissions')}
            disabled={typeID() === TYPE_ADMIN_AP || !hasPermission}
          />
        </S.StyledRow>
        <S.StyledButtonContainer>
          {hasPermission && (
            <Button
              buttonType='primary'
              type='submit'
              height='42px'
              onClick={handleSubmit(onPressSave)}
            >
              {t('buttons.save')}
            </Button>
          )}
          {!hasPermission && (
            <Button buttonType='secondary' height='42px' onClick={() => toggleVisibility()}>
              {t('evaluationForm.goBack')}
            </Button>
          )}
        </S.StyledButtonContainer>
      </S.StyledWrapper>
    </Modal>
  );
};
