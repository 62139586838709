import * as S from './DashboardCard.styles';

import { Icon } from '..';

export type DashboardCardProps = {
  icon: string;
  title: string;
  count: string;
  hasMargin?: number;
};

const DashboardCard = ({ icon, title, count, hasMargin }: DashboardCardProps) => {
  return (
    <S.CardWrapper hasMargin={hasMargin}>
      <Icon icon={icon} />
      <S.Text bold>{title}</S.Text>
      <S.Text>{count}</S.Text>
    </S.CardWrapper>
  );
};

export default DashboardCard;
