import styled, { css } from 'styled-components';
import { ImgHTMLAttributes } from 'react';

export type RoundAreaProps = {
  isProfile?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 0 36px 0px 0;
`;

export const TitleArea = styled.div`
  display: block;
`;
export const Title = styled.p`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: 32px;
    font-weight: ${typography.fontWeight.bold};
    line-height: 48px;
  `};
`;

export const Subtitle = styled.p`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.regular};
    line-height: 28px;
  `};
`;

export const ProfileArea = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RoundArea = styled.div<RoundAreaProps>`
  ${({ theme: { colors }, isProfile }) => css`
    display: flex;
    align-items: center;
    justify-content: ${isProfile ? 'space-between' : 'center'};
    height: 44px;
    width: ${isProfile ? 340 : 44}px;
    margin-right: ${isProfile ? 45 : 0}px;
    padding-left: ${isProfile ? 5 : 0}px;
    padding-right: ${isProfile ? 15 : 0}px;
    border-radius: 22px;
    background-color: ${colors.secondary.lightest};
  `};
`;

export const ProfilePhoto = styled.img<ImgHTMLAttributes<HTMLImageElement>>`
  height: 36px;
  width: 36px;
  border-radius: 18px;
  margin-right: 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export const ProfileName = styled.p`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.sm};
    font-weight: ${typography.fontWeight.bold};
    align-self: center;
  `};
`;
