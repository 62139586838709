import { call, put, takeEvery } from 'redux-saga/effects';

import * as Slice from '../slices/generalInfoSlice';

import api from '../../services/api';

type GeneralParams = {
  payload: {
    ID: number;
    pgIndex: number;
    successCallback: Function;
    failureCallback: Function;
    clearCities: boolean;
    name: string;
  };
};

function* listCultures({ payload: { successCallback = () => {} } }: GeneralParams) {
  try {
    const response = yield call(api.get, '/cultura');
    const { data } = response;

    yield put(Slice.LIST_CULTURES_SUCCESS(data));
    successCallback();
  } catch (error) {
    yield put(Slice.LIST_CULTURES_FAILURE({ error }));
  }
}

function* listProducts({ payload: { ID, successCallback = () => {} } }: GeneralParams) {
  try {
    const response = yield call(api.get, `/cultura/${ID}/products`);
    const { data } = response;

    yield put(Slice.LIST_PRODUCTS_SUCCESS(data));
    successCallback();
  } catch (error) {
    yield put(Slice.LIST_PRODUCTS_FAILURE({ error }));
  }
}

function* listCities({
  payload: { ID, pgIndex, clearCities, name, successCallback = () => {} }
}: GeneralParams) {
  try {
    const searchName = name ? `&Name=${name}` : '';
    const response = yield call(
      api.get,
      `/department/cities?DepartmentId=${ID}&PageIndex=${pgIndex}${searchName}&Active=true&PageSize=500`
    );
    const { data } = response;

    clearCities
      ? yield put(Slice.LIST_CITIES_SUCCESS({ clearCities, data }))
      : yield put(Slice.LIST_CITIES_SUCCESS({ data }));

    successCallback(data.dataList);
  } catch (error) {
    yield put(Slice.LIST_CITIES_FAILURE({ error }));
  }
}

function* listVarieties({
  payload: { ID, successCallback = () => {}, failureCallback = () => {} }
}: GeneralParams) {
  try {
    const { data } = yield call(api.get, `/cultura/${ID}/varieties`);
    yield put(Slice.LIST_VARIETIES_SUCCESS(data));
    successCallback(data);
  } catch (error) {
    yield put(Slice.LIST_VARIETIES_FAILURE({ error }));
    failureCallback();
  }
}

export default function* watcher() {
  yield takeEvery(Slice.LIST_CULTURES, listCultures);
  yield takeEvery(Slice.LIST_PRODUCTS, listProducts);
  yield takeEvery(Slice.LIST_CITIES, listCities);
  yield takeEvery(Slice.LIST_VARIETIES, listVarieties);
}
