import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const WrapperDiv = styled.div`
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 242px;
  max-width: 242px;
  height: 100vh;
  overflow: auto;
  background-color: ${({ theme: { colors } }) => colors.primary.default};
  border-radius: 0px 32px 0px 0px;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

export const Logo = styled.img`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 150px;
  height: auto;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: auto;
  position: relative;
`;

export const OptionsAndHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 42px;
  cursor: pointer;
`;

export const LogoutTitle = styled.p`
  ${({ theme: { colors, typography } }) => css`
    margin-left: 8px;
    font-size: ${typography.fontSize.sm};
    font-weight: ${typography.fontWeight.bold};
    color: ${colors.white};
  `}
`;

export const AnimatedDiv = styled(motion.div)`
  ${({ theme: { colors } }) => css`
    width: 85%;
    height: 42px;
    border-style: solid;
    border-left-color: ${colors.secondary.default};
    border-left-width: 8px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-radius: 8px 0px 0px 8px;
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    right: 0;
  `};
`;
