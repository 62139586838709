import { createSlice } from '@reduxjs/toolkit';

type CultureType = {
  id: number;
  name: string;
  varieties: {
    id: number;
    name: string;
    cultivation: string;
  }[];
};

type ProductType = {
  id: number;
  name: string;
  crop: string;
};

type CityType = {
  id: number;
  name: string;
  score: number;
  department: { id: number; name: string };
};

type VarietyType = {
  id: number;
  name: string;
};

type GeneralInfoType = {
  isLoading: boolean;
  cultures: CultureType[];
  varieties: VarietyType[];
  products: ProductType[];
  cities: CityType[];
  citiesPagesCount: number;
  error: Object | null;
};

const initialState: GeneralInfoType = {
  isLoading: true,
  cultures: [],
  varieties: [],
  products: [],
  cities: [],
  citiesPagesCount: 0,
  error: null
};

const generalInfoSlice = createSlice({
  name: 'generalInfo',
  initialState,
  reducers: {
    LIST_CULTURES: (state, action) => ({ ...state, isLoading: true }),
    LIST_PRODUCTS: (state, action) => ({ ...state, isLoading: true }),
    LIST_CULTURES_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      cultures: payload
    }),
    LIST_CULTURES_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_PRODUCTS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      products: payload
    }),
    LIST_PRODUCTS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_CITIES: (state, action) => ({ ...state, isLoading: true }),
    LIST_CITIES_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      cities: payload.clearCities
        ? [...payload.data.dataList]
        : [...state.cities, ...payload.data.dataList],
      citiesPagesCount: payload.data.pagesCount
    }),
    LIST_CITIES_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_VARIETIES: (state, action) => ({ ...state, isLoading: true }),
    LIST_VARIETIES_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      varieties: payload
    }),
    LIST_VARIETIES_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    })
  }
});

const { actions, reducer } = generalInfoSlice;

export const {
  LIST_CULTURES,
  LIST_CULTURES_SUCCESS,
  LIST_CULTURES_FAILURE,
  LIST_PRODUCTS,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAILURE,
  LIST_CITIES,
  LIST_CITIES_SUCCESS,
  LIST_CITIES_FAILURE,
  LIST_VARIETIES,
  LIST_VARIETIES_SUCCESS,
  LIST_VARIETIES_FAILURE
} = actions;

export default reducer;
