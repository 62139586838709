import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { maskJs } from 'mask-js';
import * as yup from 'yup';

import { TextField, useTranslation, Button, Select, Checkbox } from '@basfagro/core';

import * as S from './FarmerProfile.styles';

import { FeedbackModal } from '../../../components';
import { validationSchema, FarmerType, farmerDefaultValues } from './FarmerProfile.utils';

import { useGeneralInfo, useFarmers, useAuth } from '../../../hooks';
import { identificationTypes, departmentsList } from '../../../utils/generalInfo';
import { getValidationByDocumentType } from '../../../utils/helpers';

export const PersonalData = () => {
  const { t } = useTranslation();
  const { id: farmerID } = useParams();
  const { listCities, cities } = useGeneralInfo();
  const { selectedFarmer, farmerLoading, editFarmer, getDetailedFarmer, listEmployees, listFarms } =
    useFarmers();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Farmer');
  const [schema, setSchema] = useState(yup.object(validationSchema).required());
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });
  const [changedD, setChangedD] = useState(false);

  useEffect(() => {
    getDetailedFarmer(farmerID, farmer => {
      reset({
        name: farmer.name,
        email: farmer.email,
        legalPerson: !!farmer.companyName?.length,
        companyName: farmer.companyName || '',
        identificationNumberType:
          identificationTypes.find(({ id }) => id === farmer.identificationNumberType)?.name ||
          'CC',
        identificationNumber: farmer.identificationNumber,
        phoneNumbers: {
          phoneNumber: farmer.phoneNumber,
          mobileNumber: farmer.mobileNumber
        },
        address: {
          fullAddress: farmer.fullAddress,
          city: farmer.city.name,
          department: farmer.city.department.name
        }
      });
      listEmployees(farmer.id);
      listFarms(farmer.id);
      listCities(farmer.city.department.id, 1, true);
    });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<FarmerType>({
    resolver: yupResolver(schema),
    defaultValues: farmerDefaultValues
  });

  const values = watch();

  const selectedDepartment = useMemo(() => {
    if (values.address.department) {
      return departmentsList.find(({ name }) => name === values.address.department)?.id || -1;
    }
  }, [values.address.department, farmerLoading]);

  useEffect(() => {
    if (!farmerLoading) {
      if (selectedDepartment) listCities(selectedDepartment, 1, true);
      if (changedD) {
        setValue('address.city', t('fields.selectCity'));
      }
    }
  }, [selectedDepartment, farmerLoading]);

  useEffect(() => {
    setSchema(
      yup
        .object({
          ...validationSchema,
          identificationNumber: getValidationByDocumentType(values.identificationNumberType)
        })
        .required()
    );
  }, [values.identificationNumberType]);

  useEffect(() => {
    if (values.legalPerson === undefined) return;
    if (!values.legalPerson) setValue('companyName', '');
  }, [values.legalPerson]);

  const onSave = formValues => {
    const editedFarmer = {
      companyName: formValues.companyName,
      identificationType: identificationTypes.find(
        ({ name }) => name === formValues.identificationNumberType
      )?.id,
      identificationNumber: formValues.identificationNumber,
      phoneNumbers: {
        phoneNumber: formValues.phoneNumbers.phoneNumber,
        mobileNumber: formValues.phoneNumbers.mobileNumber
      },
      address: {
        fullAddress: formValues.address.fullAddress,
        cityId: cities.find(({ name }) => name === formValues.address.city)?.id
      }
    };
    editFarmer(
      selectedFarmer.id,
      editedFarmer,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <S.StyledWrapper>
      {!farmerLoading && (
        <>
          <S.ContentRow>
            <TextField
              label={t('farmers.nameField')}
              placeholder={t('textfields.companyName')}
              value={values.companyName}
              {...register('companyName')}
              error={errors.companyName}
              readOnly={!hasPermission || !values.legalPerson}
            />
          </S.ContentRow>
          <S.ContentRow style={{ marginBottom: '14px' }}>
            <Checkbox
              label={t('farmers.legalPerson')}
              fontSize='xs'
              labelWeight='bold'
              {...register('legalPerson')}
              disabled={!hasPermission}
            />
          </S.ContentRow>
          <S.ContentRow>
            <TextField label={t('farmers.farmerField')} col={8} value={values.name} readOnly />
            <TextField label={t('fields.mail')} value={values.email} readOnly col={8} />
          </S.ContentRow>
          <S.ContentRow>
            <Select
              label={t('fields.docType')}
              optionsList={identificationTypes.map(({ name }) => name)}
              col={8}
              value={values.identificationNumberType}
              onSelect={v => {
                setValue('identificationNumberType', v);
              }}
              error={errors.identificationNumberType}
              disabled={!hasPermission}
            />
            <TextField
              label={t('fields.docNumber')}
              value={values.identificationNumber}
              col={8}
              {...register('identificationNumber')}
              error={errors.identificationNumber}
              readOnly={!hasPermission}
            />
          </S.ContentRow>
          <S.ContentRow>
            <TextField
              label={t('fields.phone')}
              placeholder={t('textfields.typePhoneNumber')}
              col={8}
              value={maskJs('999 9999', values.phoneNumbers.phoneNumber)}
              {...register('phoneNumbers.phoneNumber')}
              error={errors.phoneNumbers?.phoneNumber}
              readOnly={!hasPermission}
            />
            <TextField
              label={t('fields.cellphone')}
              value={maskJs('999 999 9999', values.phoneNumbers.mobileNumber)}
              col={8}
              {...register('phoneNumbers.mobileNumber')}
              error={errors.phoneNumbers?.mobileNumber}
              readOnly={!hasPermission}
            />
          </S.ContentRow>
          <S.ContentRow>
            <Select
              label={t('fields.department')}
              optionsList={departmentsList.map(({ name }) => name)}
              col={8}
              value={values.address.department}
              onSelect={v => {
                setValue('address.department', v);
                setChangedD(true);
              }}
              error={errors.address?.department}
              disabled={!hasPermission}
            />
            <Select
              label={t('farmers.city')}
              optionsList={cities.map(({ name }) => name)}
              col={8}
              value={values.address.city}
              onSelect={v => setValue('address.city', v)}
              error={errors.address?.city}
              disabled={!hasPermission}
            />
          </S.ContentRow>
          <S.ContentRow>
            <TextField
              label={t('fields.address')}
              value={values.address.fullAddress}
              {...register('address.fullAddress')}
              error={errors.address?.fullAddress}
              readOnly={!hasPermission}
            />
          </S.ContentRow>
          {hasPermission && (
            <Button
              buttonType='primary'
              height='42px'
              marginBottom='16px'
              col={4}
              style={{ alignSelf: 'flex-end' }}
              onClick={handleSubmit(onSave)}
            >
              {t('buttons.save')}
            </Button>
          )}
        </>
      )}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => setFBModal({ ...FBModal, state: !FBModal.state })}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </S.StyledWrapper>
  );
};

export default PersonalData;
