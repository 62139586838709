import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation, TextField, Button } from '@basfagro/core';

import * as S from '../ResetPassword.styles';
import { NewPasswordValidate } from '../ResetPassword.utils';

import { resetPasswordCase } from '../../../useCases/resetPasswordUseCase';

import greenLogo from '../../../assets/images/logo/greenSimpleLogo.svg';

const Reset = () => {
  const navigate = useNavigate();
  const email = useLocation().state as any;
  const { t } = useTranslation();

  useEffect(() => {
    if (!email) {
      navigate('/', { replace: true });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(NewPasswordValidate),
    defaultValues: { password: '', confirmPassword: '' }
  });

  const onSubmit = formData => {
    resetPasswordCase(email, formData.password, () => {
      navigate('/', { replace: true });
    });
  };

  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <img alt='Basf logo' src={greenLogo} />
      </S.LogoWrapper>
      <S.ContentWrapper onSubmit={handleSubmit(onSubmit)}>
        <S.Title>{t('reset.createTitle')}</S.Title>
        <S.Subtitle>{t('reset.createPasswordSubtitle')}</S.Subtitle>
        <TextField
          label={t('reset.password')}
          placeholder={t('reset.passwordPlaceholder')}
          type='password'
          {...register('password')}
          error={errors.password}
        />
        <TextField
          label={t('reset.passwordConfirmation')}
          placeholder={t('reset.passwordPlaceholder')}
          type='password'
          {...register('confirmPassword')}
          error={errors.confirmPassword}
        />
        <S.ButtonsRowReset>
          <Button
            type='button'
            buttonType='secondary'
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            {t('buttons.cancelButton')}
          </Button>
          <Button buttonType='primary' type='submit'>
            {t('buttons.confirmButton')}
          </Button>
        </S.ButtonsRowReset>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Reset;
