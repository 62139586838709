import { createSlice } from '@reduxjs/toolkit';

export type CategoriesSliceType = {
  isLoading: boolean;
  isLoadingList: boolean;
  totalPages: number;
  categories: { id: number; name: string; icon: number; createdBy: string; creationDate: string }[];
  error: Object | null;
};

const initialState: CategoriesSliceType = {
  isLoading: true,
  isLoadingList: false,
  totalPages: 0,
  categories: [],
  error: null
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    LIST_CATEGORY: (state, action) => ({ ...state, isLoadingList: true }),
    CREATE_CATEGORY: (state, action) => ({ ...state, isLoading: true }),
    EDIT_CATEGORY: (state, action) => ({ ...state, isLoading: true }),
    DELETE_CATEGORY: (state, action) => ({ ...state, isLoading: true }),
    CREATE_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      categories: [
        ...state.categories,
        {
          ...payload,
          id: state.categories.length + 1
        }
      ]
    }),
    CREATE_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      categories: state.categories.map(i =>
        i.id !== payload.id
          ? i
          : {
              ...i,
              ...payload
            }
      )
    }),
    EDIT_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      categories: state.categories.filter(({ id }) => id !== payload)
    }),
    DELETE_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_CATEGORY_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoadingList: false,
      ...payload
    }),
    LIST_CATEGORY_FAILURE: (state, { payload }) => ({
      ...state,
      isLoadingList: false,
      totalPages: 0,
      error: payload.error
    })
  }
});

const { actions, reducer } = categoriesSlice;

export const {
  LIST_CATEGORY,
  LIST_CATEGORY_SUCCESS,
  LIST_CATEGORY_FAILURE,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAILURE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE
} = actions;
export default reducer;
