import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import {
  LIST_EVALUATION_FORMS,
  CREATE_EVALUATION_FORM,
  LIST_FORM_CATEGORY,
  CREATE_FORM_CATEGORY,
  EDIT_FORM_CATEGORY,
  DELETE_FORM_CATEGORY,
  CHANGE_STATUS_FORM
} from '../store/slices/evaluationFormsSlice';

export const useEvaluationForms = () => {
  const dispatch = useDispatch();

  const { forms, isLoading, error, totalPages, categories } = useSelector(
    ({ evaluationForms }: RootState) => evaluationForms
  );

  const listEvaluationForms = useCallback(
    (pgIndex, pgSize, formName?, sortBy?, successCallback?) => {
      dispatch(LIST_EVALUATION_FORMS({ pgIndex, pgSize, formName, sortBy, successCallback }));
    },
    [dispatch]
  );

  const createEvaluationForm = useCallback(
    (newForm, successCallback?) => {
      dispatch(CREATE_EVALUATION_FORM({ newForm, successCallback }));
    },
    [dispatch]
  );

  const changeStatusForm = useCallback(
    (formID, status, successCallback?) => {
      dispatch(CHANGE_STATUS_FORM({ formID, status, successCallback }));
    },
    [dispatch]
  );

  const listFormCategories = useCallback(
    (pgIndex, pgSize?, searchText?, sortBy?, successCallback?) => {
      dispatch(LIST_FORM_CATEGORY({ pgIndex, pgSize, searchText, sortBy, successCallback }));
    },
    [dispatch]
  );

  const createFormCategory = useCallback(
    (newCategory, successCallback?, failureCallback?) => {
      dispatch(CREATE_FORM_CATEGORY({ newCategory, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const editFormCategory = useCallback(
    (categoryID, newCategory, successCallback?, failureCallback?) => {
      dispatch(EDIT_FORM_CATEGORY({ categoryID, newCategory, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const deleteFormCategory = useCallback(
    (categoryID, successCallback?, failureCallback?) => {
      dispatch(DELETE_FORM_CATEGORY({ categoryID, successCallback, failureCallback }));
    },
    [dispatch]
  );

  return {
    forms,
    categories,
    isLoading,
    error,
    totalPages,
    listEvaluationForms,
    createEvaluationForm,
    changeStatusForm,
    listFormCategories,
    createFormCategory,
    editFormCategory,
    deleteFormCategory
  };
};
