import styled, { css } from 'styled-components';

type filterButtonProps = {
  hasFilter?: boolean;
};

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const ButtonFilter = styled.button<filterButtonProps>`
  ${({ hasFilter, theme: { colors } }) =>
    css`
      background-color: ${hasFilter ? colors.secondary.default : colors.secondary.lightest};
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: none;

  width: 44px;
  height: 44px;
  cursor: pointer;
`;

export const ContentFilter = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.white};
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px;
    right: 0px;
    width: 375px;
    height: auto;
    border-radius: 8px;
    padding: 16px 20px;
    z-index: 2;
    box-shadow: 0 1px 8px ${colors.neutral.dark};
  `}
`;

export const AreaCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  ${({ theme: { spacing } }) => css`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: ${spacing.md};
    height: ${spacing.md};
    background: none;
  `}
`;

export const HeaderFilterTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeaderText = styled.p`
  ${({ theme: { colors, typography } }) => css`
    font-size: ${typography.fontSize.lg};
    color: ${colors.black};
    font-weight: ${typography.fontWeight.bold};
  `}
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme: { spacing } }) => spacing.sm};
`;

export const FormTitleText = styled.p`
  ${({ theme: { colors, typography } }) => css`
    font-size: ${typography.fontSize.md};
    color: ${colors.black};
    font-weight: ${typography.fontWeight.regular};
  `}
`;
