import { useEffect, useMemo, useState, useCallback } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { TextField, MultiSelect, Select, Button, useTranslation } from '@basfagro/core';

import * as S from './CampaignForm.styles';
import {
  UseFormType,
  screenDefaultValues,
  validationSchema,
  buttonOptions
} from './CampaignForm.utils';

import { useCategories, useCampaigns, useGeneralInfo, useAuth } from '../../../hooks';
import { checkActivityCase } from '../../../useCases/checkActivityUseCase';

import {
  Header,
  ScreenSlider,
  DaysInput,
  CheckOptions,
  ButtonSelector,
  RecomendationsInput,
  ConfirmModal,
  FeedbackModal
} from '../../../components';

const Campaigns = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { categories, listCategories } = useCategories();
  const { createCampaigns, getDetailedCampaign, updateCampaign, resetCampaigns } = useCampaigns();
  const { cultures, products, listCultures, listProducts } = useGeneralInfo();
  const { hasEditPermission } = useAuth();

  const hasPermission = hasEditPermission('Administrator');

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm<UseFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      cultive: '',
      variety: '',
      screens: [{ ...screenDefaultValues }]
    }
  });

  const { append, remove } = useFieldArray({ control, name: 'screens' });

  const values = watch();
  const [selectedScreen, setSelectedScreen] = useState<number>(0);
  const [editableCampaign, setEditableCampaign] = useState<any>(null);
  const [goBackModalVisible, setGoBackVisibility] = useState<boolean>(false);
  const [createModalVisible, setCreateModalVisibility] = useState<boolean>(false);
  const [germinationMVisibility, setGerminationMVisibility] = useState<boolean>(false);
  const [alertModalVisibility, setAlertModalVisibility] = useState<boolean>(false);

  const screenValues = values.screens?.[selectedScreen];

  const categoriesOptions = useMemo(() => categories.map(({ name }) => name), [categories]);

  const culturesOptions = useMemo(() => cultures.map(({ name }) => name), [cultures]);

  const { varieties, varietyOptions } = useMemo(() => {
    const varieties = cultures.find(({ name }) => name === values.cultive)?.varieties || [];
    return { varieties, varietyOptions: varieties.map(({ name }) => name) };
  }, [cultures, values.cultive]);

  const productOptions = useMemo(() => products.map(({ name }) => name), [products]);

  const goBack = useCallback(() => {
    resetCampaigns();
    navigate('/dashboard/admin/campaigns', { replace: true });
  }, [resetCampaigns, navigate]);

  const checkVariety = () => {
    const varietyId = varieties.find(({ name }) => name === values.variety)?.id || 0;
    checkActivityCase(
      varietyId,
      hasCampaign => {
        if (hasCampaign && !id) {
          setCreateModalVisibility(true);
        } else {
          onPressSave(values);
        }
      },
      e => {
        toast.error(t('feedback.failure'));
      }
    );
  };

  const checkGermination = useMemo(
    () => values.screens.filter(({ isGermination }) => isGermination === true).length,
    [values.screens]
  );

  const onPressSave = formValues => {
    if (!id) {
      const newCampaign = {
        forceCreation: true,
        description: formValues.name,
        varietyId: varieties.find(({ name }) => name === formValues.variety)?.id,
        rites: formValues.screens.map((rite, index) => ({
          description: `${t('riteCampaign.category')} ${index + 1}`,
          minDays: rite.minDays,
          maxDays: rite.maxDays,
          visitRequired: rite.visitRequired,
          finalDateRequired: rite.finalDateRequired,
          isEmergency: rite.isGermination,
          categoryId: categories.find(({ name }) => name === rite.category)?.id,
          sequence: index + 1,
          buttonsId: rite.buttons,
          recommendations: rite.recomendations.map(r => ({ description: r })),
          details: products
            .filter(({ name }) => rite.products.includes(name))
            .map(({ id }) => ({ type: 1, value: id }))
        }))
      };
      return createCampaigns(newCampaign, goBack);
    }
    const updatedCampaign = {
      description: formValues.name,
      rites: formValues.screens.map((rite, index) => ({
        ...editableCampaign?.rites[index],
        minDays: rite.minDays,
        maxDays: rite.maxDays,
        visitRequired: rite.visitRequired,
        finalDateRequired: rite.finalDateRequired,
        isEmergency: rite.isGermination,
        recommendations: rite.recomendations.map(r => ({ description: r })),
        details: products
          .filter(({ name }) => rite.products.includes(name))
          .map(({ id }) => ({ type: 1, value: id })),
        buttons: rite.buttons.map(b => ({
          riteId: editableCampaign?.rites[index].id,
          buttonId: b
        }))
      }))
    };

    return updateCampaign(id, updatedCampaign, goBack);
  };

  useEffect(() => {
    listCategories();
    listCultures();
    register('cultive');
    register('variety');
    register(`screens.0.buttons`);
    register(`screens.0.recomendations`);
    register(`screens.0.products`);
  }, [register, listCategories, listCultures]);

  const onAddNewScreen = () => {
    if (hasPermission) {
      append({ ...screenDefaultValues });
      const index = values.screens?.length;
      register(`screens.${index}.buttons`);
      register(`screens.${index}.recomendations`);
      register(`screens.${index}.products`);
      setValue(`screens.${index}.buttons`, []);
      setValue(`screens.${index}.recomendations`, []);
      setValue(`screens.${index}.products`, []);
      setSelectedScreen(index);
    }
  };

  const onDeleteScreen = index => {
    if (hasPermission) {
      setSelectedScreen(index - 1 < 0 ? 0 : index - 1);
      remove(index);
    }
  };

  const onCheckGermination = () => {
    values.screens.forEach(({ isGermination }, i) => {
      if (isGermination) {
        setValue(`screens.${i}.isGermination`, !values.screens[i].isGermination);
      }
    });
    setValue(`screens.${selectedScreen}.isGermination`, !screenValues?.isGermination);
    setGerminationMVisibility(false);
  };

  useEffect(() => {
    if (!id) return;
    getDetailedCampaign(id, campaignToEdit => {
      setEditableCampaign(campaignToEdit);
      const cultive = cultures.find(({ name }) => name === campaignToEdit.cultivationName);
      listProducts(cultive?.id);
      reset({
        name: campaignToEdit.description,
        cultive: campaignToEdit.cultivationName,
        variety: campaignToEdit.varietyName,
        screens: campaignToEdit.rites.map(i => ({
          minDays: i.minDays || 0,
          maxDays: i.maxDays || 0,
          visitRequired: i.visitRequired,
          finalDateRequired: i.finalDateRequired,
          isGermination: i.isEmergency,
          category: i.categoryName,
          buttons: i.buttons.map(({ id }) => id),
          recomendations: i.recommendations.map(({ description }) => description),
          products: i.details.map(({ name }) => name)
        }))
      });
      campaignToEdit.rites.forEach((i, index) => {
        register(`screens.${index}.buttons`);
        register(`screens.${index}.recomendations`);
        register(`screens.${index}.products`);
        setValue(
          `screens.${index}.buttons`,
          i.buttons.map(({ id }) => id)
        );
        setValue(
          `screens.${index}.recomendations`,
          i.recommendations.map(({ description }) => description)
        );
        setValue(
          `screens.${index}.products`,
          i.details.map(({ name }) => name)
        );
      });
    });
  }, [id, getDetailedCampaign, reset, register, setValue]);

  useEffect(() => {
    if (!values.cultive) return;
    const selectedVeriety = cultures.find(({ name }) => name === values.cultive);
    listProducts(selectedVeriety?.id);
  }, [listProducts, cultures, values.cultive]);

  return (
    <>
      <S.StyledContainer>
        <Header title={t('riteCampaign.newCampaign')} />
        <S.StyledRow>
          <TextField
            placeholder={t('riteCampaign.campaignNamePlaceholder')}
            label={t('riteCampaign.campaignName')}
            {...register('name')}
            col={6}
            error={errors.name}
            readOnly={!hasPermission}
          />
          <Select
            label={t('riteCampaign.selectCrop')}
            onSelect={v => setValue('cultive', v, { shouldValidate: true })}
            value={values.cultive}
            optionsList={culturesOptions}
            col={5}
            error={errors.cultive}
            disabled={!hasPermission || !!id}
          />
          <Select
            label={t('riteCampaign.selectVariety')}
            onSelect={v => setValue('variety', v, { shouldValidate: true })}
            value={values.variety}
            optionsList={varietyOptions}
            col={5}
            error={errors.variety}
            disabled={!hasPermission || !!id}
          />
        </S.StyledRow>
        <ScreenSlider
          screens={values.screens?.map((_, i) => `${t('riteCampaign.screen')} ${i + 1}`) || []}
          selectedScreen={selectedScreen}
          onChange={setSelectedScreen}
          onAdd={onAddNewScreen}
          onDelete={onDeleteScreen}
          errors={Object.keys(errors?.screens || {})}
          disabled={!!id}
        />
        <S.StyledRow alignStart>
          <Select
            label={t('riteCampaign.category')}
            onSelect={v =>
              setValue(`screens.${selectedScreen}.category`, v, { shouldValidate: true })
            }
            value={screenValues?.category}
            optionsList={categoriesOptions}
            col={8}
            error={errors.screens?.[selectedScreen]?.category}
            disabled={!!id}
          />
          <CheckOptions
            col={3}
            label={t('riteCampaign.isGermination')}
            value={screenValues?.isGermination}
            onSelect={() => {
              if (!screenValues?.isGermination && checkGermination) {
                setGerminationMVisibility(true);
              } else {
                setValue(`screens.${selectedScreen}.isGermination`, !screenValues?.isGermination);
              }
            }}
            disabled={!hasPermission}
          />
          <DaysInput
            label={t('riteCampaign.timeAfterEmergency')}
            col={4}
            firstValue={screenValues?.minDays?.toString()}
            lastValue={screenValues?.maxDays?.toString()}
            onChangeFirst={v =>
              setValue(`screens.${selectedScreen}.minDays`, v, {
                shouldValidate: true
              })
            }
            onChangeLast={v =>
              setValue(`screens.${selectedScreen}.maxDays`, v, {
                shouldValidate: true
              })
            }
            firstError={errors.screens?.[selectedScreen]?.minDays}
            lastError={errors.screens?.[selectedScreen]?.maxDays}
            disabled={!hasPermission}
          />
        </S.StyledRow>
        <S.StyledRow alignStart>
          <ButtonSelector
            col={8}
            values={screenValues?.buttons || []}
            onSelect={v => {
              setValue(`screens.${selectedScreen}.buttons`, v, { shouldValidate: true });
            }}
            options={buttonOptions}
            error={(errors.screens?.[selectedScreen]?.buttons as any)?.message}
            disabled={!hasPermission}
          />
          <CheckOptions
            col={3}
            label={t('riteCampaign.requiresVisit')}
            value={screenValues?.visitRequired}
            onSelect={() =>
              setValue(`screens.${selectedScreen}.visitRequired`, !screenValues?.visitRequired)
            }
            disabled={!hasPermission}
          />
          <CheckOptions
            col={4}
            label={t('riteCampaign.needFinalDate')}
            value={screenValues?.finalDateRequired}
            onSelect={() =>
              setValue(
                `screens.${selectedScreen}.finalDateRequired`,
                !screenValues?.finalDateRequired
              )
            }
            disabled={!hasPermission}
          />
        </S.StyledRow>
        <S.StyledRow alignStart>
          <RecomendationsInput
            col={8}
            recomendations={screenValues?.recomendations || []}
            onSave={v => setValue(`screens.${selectedScreen}.recomendations`, v)}
            disabled={!hasPermission}
          />
          {(!!productOptions.length || hasPermission) && (
            <MultiSelect
              options={productOptions}
              selectedOptions={screenValues?.products || []}
              onSelect={v => setValue(`screens.${selectedScreen}.products`, v)}
              label={
                hasPermission
                  ? t('riteCampaign.productsRecomendation')
                  : t('riteCampaign.recommendedProducts')
              }
              singleColumn
              col={8}
              error={errors.screens?.[selectedScreen]?.products}
              disabled={!hasPermission}
              placeholder={t('fields.selectProduct')}
            />
          )}
        </S.StyledRow>
        <S.StyledRow flexEnd>
          {hasPermission && (
            <>
              <Button
                buttonType='secondary'
                height='48px'
                marginLeft='20px'
                marginTop='20px'
                col={4}
                onClick={() => setGoBackVisibility(true)}
              >
                {t('buttons.discardChanges')}
              </Button>
              <Button
                type='submit'
                buttonType='primary'
                height='48px'
                marginLeft='20px'
                marginTop='20px'
                col={4}
                onClick={
                  checkGermination
                    ? handleSubmit(id ? onPressSave : checkVariety)
                    : () => setAlertModalVisibility(prev => !prev)
                }
              >
                {t('buttons.saveChanges')}
              </Button>
            </>
          )}
          {!hasPermission && (
            <Button
              buttonType='secondary'
              height='48px'
              marginLeft='20px'
              marginTop='20px'
              col={4}
              onClick={goBack}
            >
              {t('evaluationForm.goBack')}
            </Button>
          )}
        </S.StyledRow>
      </S.StyledContainer>
      {goBackModalVisible && (
        <ConfirmModal
          title={
            id
              ? t('modals.campaigns.discardChangesTitle')
              : t('modals.campaigns.discardCampaignTitle')
          }
          content={
            id
              ? t('modals.campaigns.discardChangesContent', { name: values.name })
              : t('modals.campaigns.discardCampaignContent')
          }
          isVisible={goBackModalVisible}
          setVisibility={setGoBackVisibility}
          onConfirm={goBack}
        />
      )}
      {createModalVisible && (
        <ConfirmModal
          title={t('modals.campaigns.activateTitle')}
          content={t('modals.campaigns.activateAnother')}
          isVisible={createModalVisible}
          setVisibility={setCreateModalVisibility}
          onConfirm={() => onPressSave(values)}
        />
      )}
      {germinationMVisibility && (
        <ConfirmModal
          title={t('modals.campaigns.checkGerminationTitle')}
          content={t('modals.campaigns.checkGerminationContent')}
          isVisible={germinationMVisibility}
          setVisibility={setGerminationMVisibility}
          onConfirm={() => onCheckGermination()}
        />
      )}
      {alertModalVisibility && (
        <FeedbackModal
          isVisible={alertModalVisibility}
          setVisibility={setAlertModalVisibility}
          text={t('riteCampaign.selectGermination')}
          icon='IcAlert'
          iconColor='secondary'
        />
      )}
    </>
  );
};

export default Campaigns;
