import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import * as Slice from '../store/slices/distributorsSlice';

export const useDistributors = () => {
  const dispatch = useDispatch();

  const {
    distributors,
    selectedDistributor,
    tabIndex,
    isLoading,
    pageIndex,
    pagesCount,
    totalDistributors,
    error,
    searchedText
  } = useSelector(({ distributors }: RootState) => distributors);

  const listDistributors = useCallback(
    (pageIndex, pageSize, status, searchText?, successCallBack?) =>
      dispatch(
        Slice.LIST_DISTRIBUTORS({ pageIndex, pageSize, status, searchText, successCallBack })
      ),
    [dispatch]
  );

  const evaluateDistributor = useCallback(
    (id, evaluate, successCallback?) =>
      dispatch(Slice.EVALUATE_DISTRIBUTOR({ id, evaluate, successCallback })),
    [dispatch]
  );

  const deleteDistributor = useCallback(
    (id, successCallBack?) => dispatch(Slice.DELETE_DISTRIBUTOR({ id, successCallBack })),
    [dispatch]
  );

  const getDetailedDistributor = useCallback(
    (id, successCallBack?) => dispatch(Slice.GET_DETAILED_DISTRIBUTOR({ id, successCallBack })),
    [dispatch]
  );

  const editDistributor = useCallback(
    (newDistributor, successCallBack?) =>
      dispatch(Slice.EDIT_DISTRIBUTOR({ newDistributor, successCallBack })),
    [dispatch]
  );

  const editTabIndex = useCallback(id => dispatch(Slice.EDIT_TAB_INDEX(id)), [dispatch]);

  const editSearchedText = useCallback(
    text => dispatch(Slice.EDIT_SEARCHED_TEXT(text)),
    [dispatch]
  );

  const editPageIndex = useCallback(page => dispatch(Slice.EDIT_PAGE_INDEX(page)), [dispatch]);

  return {
    distributors,
    isLoading,
    searchedText,
    tabIndex,
    pageIndex,
    pagesCount,
    totalDistributors,
    error,
    listDistributors,
    evaluateDistributor,
    deleteDistributor,
    getDetailedDistributor,
    selectedDistributor,
    editDistributor,
    editTabIndex,
    editSearchedText,
    editPageIndex
  };
};
