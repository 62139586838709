import { createSlice } from '@reduxjs/toolkit';
import {
  FarmerModel,
  FarmModel,
  DetailedFarmModel,
  LandPlotModel,
  EmployeeModel
} from '../../types/models';

type farmerSliceType = {
  farmers: { id: number; name: string; department: string; city: string }[];
  selectedFarmer: FarmerModel;
  farms: FarmModel[];
  selectedFarm: DetailedFarmModel;
  landplots: LandPlotModel[];
  employees: EmployeeModel[];
  farmEmployees: EmployeeModel[];
  pagesCount: number;
  farmerLoading: boolean;
  isLoading: boolean;
  isLoadingFarms: boolean;
  totalFarmers: number;
  farmerTabIndex: number;
  farmerPageIndex: number;
  searchedFarmer: string;
  searchedFarm: string;
};

const initialState: farmerSliceType = {
  farmers: [],
  selectedFarmer: {
    id: 0,
    name: '',
    email: '',
    companyName: '',
    identificationNumberType: 0,
    identificationNumber: '',
    fullAddress: '',
    city: {
      id: 0,
      name: '',
      score: 0,
      department: { id: 0, name: '' }
    },
    phoneNumber: '',
    mobileNumber: ''
  },
  farms: [],
  selectedFarm: {
    id: 0,
    name: '',
    areaInHectares: 0,
    city: {
      id: 0,
      name: '',
      score: 0,
      department: {
        id: 0,
        name: ''
      }
    },
    location: [
      {
        latitude: 0,
        longitude: 0
      }
    ]
  },
  landplots: [],
  employees: [],
  farmEmployees: [],
  pagesCount: 0,
  farmerLoading: false,
  isLoading: false,
  isLoadingFarms: false,
  totalFarmers: 100,
  farmerTabIndex: 0,
  farmerPageIndex: 1,
  searchedFarmer: '',
  searchedFarm: ''
};

const farmersSlice = createSlice({
  name: 'farmers',
  initialState,
  reducers: {
    LIST_FARMERS: (state, action) => ({ ...state, isLoading: true }),
    LIST_FARMERS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farmers: payload.farmerList,
      pagesCount: payload.pagesCount,
      totalFarmers: payload.totalData
    }),
    LIST_FARMERS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    GET_DETAILED_FARMER: (state, action) => ({ ...state, farmerLoading: true }),
    GET_DETAILED_FARMER_SUCCESS: (state, { payload }) => ({
      ...state,
      farmerLoading: false,
      selectedFarmer: payload
    }),
    GET_DETAILED_FARMER_FAILURE: (state, { payload }) => ({
      ...state,
      farmerLoading: false,
      error: payload.error
    }),
    EDIT_FARMER: (state, action) => ({ ...state, isLoading: true }),
    EDIT_FARMER_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farmers: state.farmers.map(i =>
        i.id !== payload.selectedID
          ? i
          : {
              ...i,
              ...payload.editedFarmer
            }
      )
    }),
    EDIT_FARMER_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_FARMER: (state, action) => ({ ...state, isLoading: true }),
    DELETE_FARMER_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farmers: state.farmers.filter(i => i.id !== payload.selectedID)
    }),
    DELETE_FARMER_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_FARMS: (state, action) => ({ ...state, isLoadingFarms: true }),
    LIST_FARMS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoadingFarms: false,
      farms: payload
    }),
    LIST_FARMS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoadingFarms: false,
      error: payload.error
    }),
    GET_DETAILED_FARM: (state, action) => ({ ...state, isLoading: true }),
    GET_DETAILED_FARM_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      selectedFarm: payload
    }),
    GET_DETAILED_FARM_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_FARM: (state, action) => ({ ...state, isLoading: true }),
    EDIT_FARM_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farms: state.farms.map(i =>
        i.id !== payload.selectedID
          ? i
          : {
              ...i,
              ...payload.editedFarm
            }
      )
    }),
    EDIT_FARM_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_FARM: (state, action) => ({ ...state, isLoading: true }),
    DELETE_FARM_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farms: state.farms.filter(i => i.id !== payload.selectedID)
    }),
    DELETE_FARM_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_LANDPLOTS: (state, action) => ({ ...state, isLoading: true }),
    LIST_LANDPLOTS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      landplots: payload
    }),
    LIST_LANDPLOTS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_LANDPLOT: (state, action) => ({ ...state, isLoading: true }),
    EDIT_LANDPLOT_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      landplots: state.landplots.map(i =>
        i.id !== payload.selectedID
          ? i
          : {
              ...i,
              ...payload.editedLandplot
            }
      )
    }),
    EDIT_LANDPLOT_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_LANDPLOT: (state, action) => ({ ...state, isLoading: true }),
    DELETE_LANDPLOT_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      landplots: state.landplots.filter(i => i.id !== payload.selectedID)
    }),
    DELETE_LANDPLOT_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_EMPLOYEES: (state, action) => ({ ...state, isLoading: true }),
    LIST_EMPLOYEES_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      employees: payload
    }),
    LIST_EMPLOYEES_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    LIST_FARM_EMPLOYEES: (state, action) => ({ ...state, isLoading: true }),
    LIST_FARM_EMPLOYEES_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farmEmployees: payload
    }),
    LIST_FARM_EMPLOYEES_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_EMPLOYEE: (state, action) => ({ ...state, isLoading: true }),
    EDIT_EMPLOYEE_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      employees: state.employees.map(i =>
        i.id !== payload.selectedID ? i : payload.editedEmployee
      )
    }),
    EDIT_EMPLOYEE_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_EMPLOYEE: (state, action) => ({ ...state, isLoading: true }),
    DELETE_EMPLOYEE_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      farmEmployees: state.farmEmployees.filter(i => i.id !== payload.selectedID),
      employees: state.employees.filter(i => i.id !== payload.selectedID)
    }),
    DELETE_EMPLOYEE_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_FARMER_TAB_INDEX: (state, { payload }) => ({ ...state, farmerTabIndex: payload }),
    EDIT_FARMER_PAGE_INDEX: (state, { payload }) => ({ ...state, farmerPageIndex: payload }),
    EDIT_SEARCHED_FARMER: (state, { payload }) => ({ ...state, searchedFarmer: payload }),
    EDIT_SEARCHED_FARM: (state, { payload }) => ({ ...state, searchedFarm: payload })
  }
});

const { actions, reducer } = farmersSlice;

export const {
  LIST_FARMERS,
  LIST_FARMERS_SUCCESS,
  LIST_FARMERS_FAILURE,
  GET_DETAILED_FARMER,
  GET_DETAILED_FARMER_SUCCESS,
  GET_DETAILED_FARMER_FAILURE,
  LIST_FARMS,
  LIST_FARMS_SUCCESS,
  LIST_FARMS_FAILURE,
  GET_DETAILED_FARM,
  GET_DETAILED_FARM_SUCCESS,
  GET_DETAILED_FARM_FAILURE,
  DELETE_FARM,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_FAILURE,
  LIST_LANDPLOTS,
  LIST_LANDPLOTS_SUCCESS,
  LIST_LANDPLOTS_FAILURE,
  LIST_EMPLOYEES,
  LIST_EMPLOYEES_SUCCESS,
  LIST_EMPLOYEES_FAILURE,
  EDIT_FARMER,
  EDIT_FARMER_SUCCESS,
  EDIT_FARMER_FAILURE,
  DELETE_FARMER,
  DELETE_FARMER_SUCCESS,
  DELETE_FARMER_FAILURE,
  EDIT_FARM,
  EDIT_FARM_SUCCESS,
  EDIT_FARM_FAILURE,
  EDIT_LANDPLOT,
  EDIT_LANDPLOT_SUCCESS,
  EDIT_LANDPLOT_FAILURE,
  DELETE_LANDPLOT,
  DELETE_LANDPLOT_SUCCESS,
  DELETE_LANDPLOT_FAILURE,
  LIST_FARM_EMPLOYEES,
  LIST_FARM_EMPLOYEES_SUCCESS,
  LIST_FARM_EMPLOYEES_FAILURE,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  EDIT_FARMER_TAB_INDEX,
  EDIT_FARMER_PAGE_INDEX,
  EDIT_SEARCHED_FARMER,
  EDIT_SEARCHED_FARM
} = actions;

export default reducer;
