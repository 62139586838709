import { createGlobalStyle } from 'styled-components';

type ThemeType = {
  colors: {
    [keys: string]: {
      [keys: string]: string;
    };
    colorWithOpacityGenerator: any;
  };
  typography: Object;
  breakpoints: Object;
  spacing: Object;
};

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
  *, *::after, *::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Roboto;
  }

  #root {
    display: flex;
    flex-direction: row;
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.colors.colorWithOpacityGenerator('neutral.default', 0.7)};
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.neutral.default};
  }

`;

export default GlobalStyles;
