import styled, { css } from 'styled-components';
import { colors } from '@basfagro/core';

import * as Icons from '../../assets/images/icons';

export type IconsKeys = keyof typeof Icons;

export type IconProps = {
  height?: number;
  width?: number;
  color?: string;
  icon?: string;
};

const preDefinedColors = {
  white: colors.white,
  black: colors.black,
  grey: colors.neutral.default,
  primary: colors.primary.default,
  secondary: colors.secondary.default,
  tertiary: colors.tertiary.default
};

const Icon = styled.svg.attrs<IconProps>(({ icon = 'IcCottage', color }) => ({
  as: Icons[icon],
  stroke: preDefinedColors[color ?? 'primary']
}))<IconProps>`
  ${({ width, height }) => css`
    width: ${width ?? 25}px;
    height: ${height ?? 25}px;
    min-width: ${width ?? 25}px;
    min-height: ${height ?? 25}px;
  `}
`;

export default Icon;
