import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import { useTranslation, EmptyPage, SearchField } from '@basfagro/core';

import * as S from './FarmerProfile.styles';
import { StyledLoading } from '../Farmers.styles';

import { useFarmers, useAuth } from '../../../hooks';
import Loading from '../../../assets/Loading.gif';

import { ActionTable, ConfirmModal, FeedbackModal } from '../../../components';

const Farms = () => {
  const { t } = useTranslation();
  const { id: farmerID } = useParams();
  const navigate = useNavigate();
  const {
    farms,
    selectedFarmer,
    listFarms,
    isLoadingFarms,
    searchedFarm,
    getDetailedFarm,
    deleteFarm,
    listLandPlots,
    editSearchedFarmText
  } = useFarmers();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Farmer');
  const [searchValue, setSearchValue] = useState(searchedFarm);
  const [DModalVisibility, setDModalVisibility] = useState(false);
  const [farmToDelete, setFarmToDelete] = useState({} as { id: number; name: string } | undefined);
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  useEffect(() => {
    const id = selectedFarmer.id ? selectedFarmer.id : farmerID;
    if (searchValue !== '') {
      listFarms(id, searchValue);
    } else {
      listFarms(id);
    }
    editSearchedFarmText(searchValue);
  }, [searchValue, listFarms, selectedFarmer.id]);

  const onPressDelete = farm => {
    setDModalVisibility(true);
    setFarmToDelete({ id: farm.id, name: farm.name });
  };

  const onConfirmDelete = () => {
    setDModalVisibility(false);
    deleteFarm(
      farmToDelete?.id,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <S.StyledWrapper>
      <SearchField
        placeholder={t('farmers.searchFarm')}
        value={searchValue}
        onChangeText={setSearchValue}
        marginTop='16px'
        marginBottom='16px'
      />
      {isLoadingFarms ? (
        <StyledLoading src={Loading} />
      ) : farms.length ? (
        <ActionTable
          headers={{
            name: t('fields.name'),
            areaInHectares: t('farmers.area'),
            city: t('farmers.city')
          }}
          tableData={farms}
          onPressEdit={farm =>
            getDetailedFarm(farm.id, () => listLandPlots(farm.id, '', () => navigate(`${farm.id}`)))
          }
          onPressDelete={onPressDelete}
          hasPermission={!!hasPermission}
        />
      ) : (
        <EmptyPage />
      )}
      {DModalVisibility && (
        <ConfirmModal
          isVisible={DModalVisibility}
          setVisibility={() => setDModalVisibility(prev => !prev)}
          onConfirm={onConfirmDelete}
          title={t('modals.farms.farmTitle')}
          content={t('modals.farms.farmContent', { name: farmToDelete?.name })}
        />
      )}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => setFBModal({ ...FBModal, state: !FBModal.state })}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </S.StyledWrapper>
  );
};

export default Farms;
