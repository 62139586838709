import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation, TextField, Button, Select } from '@basfagro/core';

import * as S from './Farm.styles';
import { validationSchema } from './Farm.utils';

import { Header, TabBar, FeedbackModal } from '../../../components';
import { departmentsList } from '../../../utils/generalInfo';

import { useGeneralInfo, useFarmers, useAuth } from '../../../hooks';

import LandPlots from './Landplots';
import Employees from './Employees';

const Farm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { farm: farmID } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [changedD, setChangedD] = useState(false);
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  const { listCities, cities } = useGeneralInfo();
  const { editFarm, selectedFarm, getDetailedFarm, isLoading } = useFarmers();

  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Farmer');

  const defaultValues = {
    name: selectedFarm.name,
    areaInHectares: selectedFarm.areaInHectares,
    address: {
      city: selectedFarm.city.name,
      department: selectedFarm.city.department.name
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues
  });

  const values = watch();

  useEffect(() => {
    getDetailedFarm(farmID, farm => {
      reset({
        name: farm.name,
        areaInHectares: farm.areaInHectares,
        address: {
          city: farm.city.name,
          department: farm.city.department.name
        }
      });
    });
  }, []);

  const selectedDepartment = useMemo(() => {
    if (values.address.department) {
      return departmentsList.find(({ name }) => name === values.address.department)?.id || -1;
    }
  }, [values.address.department]);

  useEffect(() => {
    if (selectedDepartment) listCities(selectedDepartment, 1, true);
    if (changedD) {
      setValue('address.city', t('fields.selectCity'));
    }
  }, [selectedDepartment]);

  const onSave = formValues => {
    const editedFarm = {
      name: formValues.name,
      areaInHectares: formValues.areaInHectares,
      cityId: cities.find(({ name }) => name === formValues.address.city)?.id,
      location: selectedFarm.location
    };
    editFarm(
      selectedFarm.id,
      editedFarm,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  const renderTab = () => {
    switch (selectedTab) {
      case 0:
        return <LandPlots farmID={farmID || '0'} />;
      case 1:
        return <Employees farmID={farmID || '0'} />;
    }
  };

  return (
    <S.StyledContainer>
      <Header title={selectedFarm.name} subtitle={t('farmers.farmSubtitle')} />
      <Button buttonType='tertiary' onClick={() => navigate(-1)}>
        {`< ${t('evaluationForm.goBack')}`}
      </Button>
      <S.ContentRow>
        <TextField
          label={t('farmers.farmIdentification')}
          value={values.name}
          {...register('name')}
          error={errors.name}
          readOnly={!hasPermission}
        />
        <TextField label={t('farmers.area')} value={selectedFarm.areaInHectares} disabled />
      </S.ContentRow>
      <S.ContentRow>
        <Select
          label={t('fields.department')}
          optionsList={departmentsList.map(({ name }) => name)}
          col={8}
          value={values.address.department}
          onSelect={v => {
            setValue('address.department', v);
            setChangedD(true);
          }}
          error={errors.address?.department}
          disabled={!hasPermission}
        />
        <Select
          label={t('farmers.city')}
          optionsList={cities.map(({ name }) => name)}
          col={8}
          value={values.address.city}
          onSelect={v => setValue('address.city', v)}
          error={errors.address?.city}
          disabled={!hasPermission}
        />
      </S.ContentRow>
      {hasPermission && (
        <S.ContentRow>
          <Button
            buttonType='secondary'
            height='48px'
            col={4}
            onClick={() => {
              reset(defaultValues);
            }}
          >
            {t('buttons.discardChanges')}
          </Button>
          <Button
            buttonType='primary'
            height='48px'
            col={4}
            marginRight='16px'
            onClick={handleSubmit(onSave)}
          >
            {t('buttons.saveChanges')}
          </Button>
        </S.ContentRow>
      )}
      <TabBar
        options={[
          { id: 0, name: t('farmers.lots') },
          { id: 1, name: t('farmers.employees') }
        ]}
        onPressOption={setSelectedTab}
      />
      {renderTab()}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => setFBModal({ ...FBModal, state: !FBModal.state })}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </S.StyledContainer>
  );
};

export default Farm;
