import { useState, useEffect } from 'react';
import { Icon } from '..';

import * as S from './ActionTable.styles';

type HeaderType = {
  [keys: string]: string;
};

type TableRowType = {
  data: {
    [keys: string]: string | number | object | boolean | object[];
  };
  headers: HeaderType;
  formatRow?: {
    [keys: string]: Function;
  };
  formatSubRow?: {
    [keys: string]: Function;
  };
  subHeaders?: HeaderType;
  subOptionsKey?: string;
  onPressEdit: Function;
  onPressDelete: Function;
  isOdd: boolean;
  hasPermission?: boolean;
};

const TableRow = ({
  headers,
  formatRow,
  formatSubRow,
  subHeaders = {},
  onPressEdit,
  onPressDelete,
  isOdd,
  subOptionsKey = 'subOptions',
  data: { [subOptionsKey]: options = [], ...data },
  hasPermission = false
}: TableRowType) => {
  const subOptions = Array.isArray(options) ? options : [];
  const [isExtended, setIsExtended] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (isExtended) {
      setTimeout(() => setShowContent(true), 200);
    } else {
      setShowContent(false);
    }
  }, [isExtended]);

  const rowKeys = Object.keys(subHeaders);
  const headersKeys = Object.keys(headers);
  return (
    <>
      <S.TableRow isOdd={isOdd}>
        {headersKeys.map(info => (
          <S.TableContent key={info}>
            {formatRow?.[info] ? formatRow[info](data[info]) : data[info]}
          </S.TableContent>
        ))}
        <S.ContentRow hasPermission={hasPermission}>
          {!!subOptions?.length && (
            <S.RegularButton onClick={() => setIsExtended(prev => !prev)}>
              <Icon icon={isExtended ? 'IcChevronUp' : 'IcChevronDown'} color='secondary' />
            </S.RegularButton>
          )}
          {hasPermission && (
            <>
              <S.RegularButton onClick={() => onPressEdit(data)}>
                <Icon icon='IcEdit' color='secondary' />
              </S.RegularButton>
              <S.RegularButton onClick={() => onPressDelete(data)}>
                <Icon icon='IcTrash' color='secondary' />
              </S.RegularButton>
            </>
          )}
          {!hasPermission && (
            <S.RegularButton onClick={() => onPressEdit(data)}>
              <Icon icon='IcEye' color='secondary' />
            </S.RegularButton>
          )}
        </S.ContentRow>
      </S.TableRow>
      <S.StyledSubOptionsContainer
        isOdd={isOdd}
        animate={{
          height: `${!isExtended ? 0 : 60 * (1 + (subOptions?.length || 0))}px`
        }}
        initial={{
          height: '0px'
        }}
        transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
      >
        {showContent && (
          <S.StyledSubOptions colSpan={rowKeys.length + 1}>
            <S.StyledSubOptionsRow>
              {Object.values(subHeaders).map(header => (
                <S.StyledSubOptionsTitle key={header} width={100 / rowKeys?.length}>
                  {header}
                </S.StyledSubOptionsTitle>
              ))}
            </S.StyledSubOptionsRow>
            {subOptions?.map(subData => (
              <S.StyledSubOptionsRow key={crypto.randomUUID()}>
                {Object.keys(subHeaders).map(key => (
                  <S.StyledSubOptionsData key={key} width={100 / rowKeys?.length}>
                    {formatSubRow?.[key] ? formatSubRow[key](subData[key]) : subData[key]}
                  </S.StyledSubOptionsData>
                ))}
              </S.StyledSubOptionsRow>
            ))}
          </S.StyledSubOptions>
        )}
      </S.StyledSubOptionsContainer>
    </>
  );
};

export default TableRow;
