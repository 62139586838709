import styled, { css } from 'styled-components';
import RPBackground from '../../assets/images/RPBackground.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url(${RPBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${({ theme: { colors } }) => colors.primary.dark};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  top: 4%;
  @media (min-height: 520px) {
    position: absolute;
  }
`;

export const ContentWrapper = styled.form`
  ${({ theme: { colors, spacing } }) => css`
    display: flex;
    flex-direction: column;
    padding: ${spacing.lg};
    width: 472px;
    height: fit-content;
    min-height: 420px;
    background-color: ${colors.white};
    border-radius: ${spacing.xs};
    box-shadow: 0px 15px 55px rgba(157, 163, 180, 0.16);
  `};
`;

export const Title = styled.h1`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: 32px;
    font-weight: ${typography.fontWeight.bold};
    line-height: 48px;
  `};
`;

export const Subtitle = styled.h2`
  ${({ theme: { colors, typography, spacing } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.regular};
    line-height: 28px;
    margin-bottom: ${spacing.md};
    text-align: justify;
  `};
`;

export const TimeText = styled.p`
  ${({ theme: { colors, typography, spacing } }) => css`
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.bold};
    color: ${colors.neutral.dark};
    padding: ${spacing.sm} 0;
  `};
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  height: 42px;
  gap: ${({ theme: { spacing } }) => spacing.md};
`;
export const ButtonsRowReset = styled(ButtonsRow)`
  margin-top: ${({ theme: { spacing } }) => spacing.sm};
`;
