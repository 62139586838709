import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';
import { CategoryModel } from '../../types/models';

import {
  LIST_CATEGORY,
  LIST_CATEGORY_SUCCESS,
  LIST_CATEGORY_FAILURE,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAILURE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE
} from '../slices/categoriesSlice';

import api from '../../services/api';

type CategoryParams = {
  payload: {
    page?: number;
    newCategory: CategoryModel;
    searchText: string;
    sortBy: number;
    successCallback: Function;
    failureCallback: Function;
    id: number;
  };
};

function* listCategory({
  payload: { page, searchText, sortBy, successCallback = () => {}, failureCallback = () => {} }
}: CategoryParams) {
  try {
    const search = searchText ? `&Name=${searchText}` : '';
    const sort = sortBy ? `&SortBy=${sortBy}` : '';
    const response = yield call(
      api.get,
      `/campaignmanagement/categories?PageSize=10${
        !!page ? `&PageIndex=${page}` : ''
      }${search}${sort}`
    );
    const {
      data: { dataList: categories, pagesCount: totalPages }
    } = response;

    yield put(LIST_CATEGORY_SUCCESS({ categories, totalPages }));
    successCallback();
  } catch (error: any) {
    const { status } = error.response;

    yield put(LIST_CATEGORY_FAILURE({ error }));
    if (status === 401) {
      failureCallback();
    }
  }
}

function* createCategory({
  payload: { newCategory, successCallback = () => {}, failureCallback = () => {} }
}: CategoryParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));

  try {
    const response = yield call(api.post, '/campaignmanagement/categories', newCategory);
    const { data } = response;

    yield put(CREATE_CATEGORY_SUCCESS(data));
    successCallback(i18n.t('modals.categories.categoryCreated'));
    toast.dismiss(toastId);
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('modals.categories.createError'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(CREATE_CATEGORY_FAILURE({ error }));
    toast.dismiss(toastId);
  }
}

function* editCategory({
  payload: { newCategory, successCallback = () => {}, failureCallback = () => {} }
}: CategoryParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const response = yield call(
      api.put,
      `/campaignmanagement/categories/${newCategory.id}`,
      newCategory
    );
    const { data } = response;

    yield put(EDIT_CATEGORY_SUCCESS({ ...data, createdBy: newCategory.createdBy }));
    successCallback(i18n.t('feedback.editComplete'));
    toast.dismiss(toastId);
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('modals.categories.editError'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(EDIT_CATEGORY_FAILURE({ error }));
    toast.dismiss(toastId);
  }
}

function* deleteCategory({
  payload: { id, successCallback = () => {}, failureCallback = () => {} }
}: CategoryParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `/campaignmanagement/categories/${id}`);

    yield put(DELETE_CATEGORY_SUCCESS(id));
    successCallback(i18n.t('modals.categories.categoryDeleted'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(DELETE_CATEGORY_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

export default function* watcher() {
  yield takeEvery(CREATE_CATEGORY, createCategory);
  yield takeEvery(EDIT_CATEGORY, editCategory);
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
  yield takeEvery(LIST_CATEGORY, listCategory);
}
