import { useEffect, useState } from 'react';
import { TextField, useTranslation, TextArea, Select, Button } from '@basfagro/core';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Icon, Modal } from '../../../components';

import { QuestionsType, questionsValidationSchema, questionsTypes } from './EvaluationForm.utils';

import * as S from './EvaluationForm.styles';

type CategoryModalType = {
  isVisible: boolean;
  toggleVisibility: Function;
  onConfirm: Function;
  idEditQuestion: number | null;
  editQuestion?: QuestionsType;
};

const QuestionModal = ({
  isVisible,
  toggleVisibility,
  onConfirm,
  idEditQuestion,
  editQuestion
}: CategoryModalType) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm<QuestionsType>({
    resolver: yupResolver(questionsValidationSchema),
    defaultValues: {
      description: '',
      options: [{ description: '', score: 0 }]
    }
  });
  const { t } = useTranslation();
  const [changeTypeFlag, setChangeTypeFlag] = useState(false);
  const [questType, setQuestType] = useState<string>(
    editQuestion && !editQuestion?.options.length
      ? t('evaluationForm.subjective')
      : t('evaluationForm.objective')
  );

  const { append, remove } = useFieldArray({
    control,
    name: 'options'
  });

  useEffect(() => {
    if (editQuestion) {
      reset(editQuestion);
    }
  }, [editQuestion, reset, setValue, register]);

  const values = watch();

  useEffect(() => {
    if (!(questType === t('evaluationForm.objective'))) {
      remove();
      setChangeTypeFlag(true);
    } else {
      if (changeTypeFlag) {
        append({ description: '', score: 0 });
      }
      setChangeTypeFlag(false);
    }
  }, [questType]);

  return (
    <Modal visibility={isVisible} toggleVisibility={toggleVisibility} hasExit>
      <S.ModalContainer>
        <S.StyledRow>
          <TextArea
            label={t('evaluationForm.question')}
            placeholder={t('evaluationForm.questionPlaceholder')}
            {...register('description')}
            error={errors.description}
            paddingRight={0}
          />
        </S.StyledRow>
        <S.StyledRow>
          <Select
            label={t('evaluationForm.questionType')}
            onSelect={v => setQuestType(v)}
            value={questType}
            optionsList={questionsTypes}
            large
          />
        </S.StyledRow>
        {questType === t('evaluationForm.objective') && (
          <>
            {values.options.map((item, i) => (
              <S.StyledRow key={item.description} style={{ alignItems: 'center' }}>
                <TextField
                  label={t('evaluationForm.answerOption')}
                  col={9.7}
                  error={errors.options?.[i]?.description}
                  {...register(`options.${i}.description`)}
                />
                <TextField
                  label={t('evaluationForm.answerScore')}
                  placeholder={t('evaluationForm.answerScorePlaceholder')}
                  {...register(`options.${i}.score`)}
                  col={5}
                  error={errors.options?.[i]?.score}
                />
                {values.options.length === i + 1 ? (
                  <>
                    {values.options.length !== 1 && (
                      <S.RegularButton onClick={() => remove(i)} style={{ marginTop: '5px' }}>
                        <Icon icon='IcTrash' color='secondary' />
                      </S.RegularButton>
                    )}
                    <S.RegularButton
                      onClick={() => append({ description: '', score: 0 })}
                      style={{ marginTop: '5px' }}
                    >
                      <Icon icon='IcMore' color='black' />
                    </S.RegularButton>
                  </>
                ) : (
                  <S.RegularButton onClick={() => remove(i)} style={{ marginTop: '5px' }}>
                    <Icon icon='IcTrash' color='secondary' />
                  </S.RegularButton>
                )}
              </S.StyledRow>
            ))}
          </>
        )}
        <S.ModalButtonRow>
          <Button
            buttonType='primary'
            height='42px'
            onClick={handleSubmit(v => onConfirm(v, idEditQuestion))}
            marginTop='35px'
            col={8}
          >
            {t('evaluationForm.saveCategory')}
          </Button>
        </S.ModalButtonRow>
      </S.ModalContainer>
    </Modal>
  );
};

export default QuestionModal;
