import styled, { css } from 'styled-components';

type ImageProps = {
  isActive: boolean;
};

type TextProps = {
  disabled?: boolean;
};

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 40px 16px 0px 16px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
`;

export const StyledButtonContainer = styled.div`
  width: 300px;
  align-self: flex-start;
`;

export const StyledSearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const StyledModalTitle = styled.p`
  ${({ theme: { colors, typography } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.lg};
    font-weight: ${typography.fontWeight.bold};
  `};
`;

export const StyledModalText = styled.p<TextProps>`
  ${({ theme: { colors, typography }, disabled }) => css`
    color: ${disabled ? colors.neutral.dark : colors.black};
    font-size: ${typography.fontSize.xs};
    font-weight: ${typography.fontWeight.bold};
    margin-top: 25px;
  `};
`;

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  width: 800px;
  padding: 0px 16px;
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledButtonRow = styled(StyledRow)`
  justify-content: flex-end;
  align-self: flex-end;
`;

export const StyledImageCircle = styled.div<ImageProps>`
  ${({ theme: { colors }, isActive }) => css`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-top: 15px;
    background-color: ${colors.neutral.default};
    opacity: 0.3;
    cursor: pointer;

    ${isActive &&
    css`
      background-color: ${colors.secondary.lightest};
      opacity: 1;
    `};
  `};
`;

export const StyledLoading = styled.img`
  width: 8%;
  height: auto;
  align-self: center;
`;
