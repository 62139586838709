import { useState, useEffect } from 'react';

import { TextField, useTranslation, Button } from '@basfagro/core';

import * as S from './Categories.styles';

import { Icon, Modal } from '../../../components';

import { CategoryModel } from '../../../types/models';

const categoryIcons = {
  1: 'IcPlant',
  2: 'IcTestTube',
  3: 'IcClipboard',
  4: 'IcTree',
  5: 'IcSeedsBag',
  6: 'IcFire',
  7: 'IcLogo'
};

type CategoryModalProps = {
  isVisible: boolean;
  categoryToEdit?: CategoryModel;
  toggleVisibility: Function;
  onConfirm: Function;
  hasPermission?: boolean;
};

const CategoryModal = ({
  isVisible,
  toggleVisibility,
  categoryToEdit,
  onConfirm,
  hasPermission = false
}: CategoryModalProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(categoryIcons[1]);

  useEffect(() => {
    if (categoryToEdit) {
      setName(categoryToEdit.name);
      setSelectedIcon(categoryIcons[categoryToEdit.icon]);
    } else {
      setName('');
      setSelectedIcon(categoryIcons[1]);
    }
  }, [categoryToEdit]);

  return (
    <Modal visibility={isVisible} toggleVisibility={toggleVisibility} hasExit>
      <S.StyledModalContainer>
        <div>
          <S.StyledModalTitle>{t('riteCategory.newCategoryModal.title')}</S.StyledModalTitle>
          <TextField
            value={name}
            onChange={e => setName(e.target.value)}
            label={t('riteCategory.newCategoryModal.name')}
            placeholder={t('riteCategory.newCategoryModal.namePlaceholder')}
            marginTop={25}
            readOnly={!hasPermission}
          />
          <S.StyledModalText disabled={!hasPermission}>
            {t('riteCategory.newCategoryModal.icons')}
          </S.StyledModalText>
          <S.StyledRow>
            {Object.values(categoryIcons).map((icon, index) => (
              <S.StyledImageCircle
                key={index}
                isActive={selectedIcon === icon}
                onClick={() => {
                  if (hasPermission) setSelectedIcon(icon);
                }}
              >
                <Icon color='primary' height={50} width={50} icon={icon} />
              </S.StyledImageCircle>
            ))}
          </S.StyledRow>
        </div>

        {hasPermission && (
          <S.StyledButtonRow>
            <Button
              buttonType='secondary'
              col={6}
              marginRight='16px'
              height='42px'
              onClick={() => toggleVisibility()}
            >
              {t('buttons.cancelButton')}
            </Button>
            <Button
              buttonType='primary'
              col={6}
              height='42px'
              onClick={() =>
                onConfirm({
                  name,
                  icon: parseInt(
                    Object.keys(categoryIcons).find(i => categoryIcons[i] === selectedIcon) || '1'
                  )
                })
              }
            >
              {t('buttons.confirmButton')}
            </Button>
          </S.StyledButtonRow>
        )}
        {!hasPermission && (
          <S.StyledButtonRow style={{ justifyContent: 'center' }}>
            <Button buttonType='secondary' col={6} height='42px' onClick={() => toggleVisibility()}>
              {t('evaluationForm.goBack')}
            </Button>
          </S.StyledButtonRow>
        )}
      </S.StyledModalContainer>
    </Modal>
  );
};

export default CategoryModal;
