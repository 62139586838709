import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 242px auto;
  grid-template-areas: 'menu content';
`;

export const Content = styled.div`
  height: 100%;
  grid-area: content;
  background-color: white;
`;
