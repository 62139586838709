import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

type TableRowProps = {
  isOdd?: boolean;
  isActive?: boolean;
  width?: number;
};

export const Table = styled.table`
  width: 100%;
  text-align: left;
  table-layout: fixed;

  background-color: ${({ theme: { colors } }) => colors.neutral.lightest};
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 2px rgba(141, 151, 158, 0.4);
`;

export const TableRow = styled.tr<TableRowProps>`
  width: 100%;
  height: 60px;
  background-color: ${({ isOdd, theme: { colors } }) =>
    isOdd ? colors.colorWithOpacityGenerator('neutral.default', 0.5) : colors.neutral.lightest};
`;

export const TableHeaders = styled.th`
  ${({ theme: { colors, typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      border-bottom: 1px solid ${colors.colorWithOpacityGenerator('neutral.default', 0.5)};
      background-color: ${colors.white};

      :last-of-type {
        width: 100px;
        text-align: center;
      }
    `};
`;

export const TableContent = styled.td<TableRowProps>`
  ${({ theme: { typography, spacing, colors }, isActive }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
      color: ${isActive ? colors.neutral.darkest : colors.neutral.default};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const ContentRow = styled.td`
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
`;

export const RegularButton = styled.button`
  ${({ theme: { typography, spacing } }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${spacing['2xs']};
      border: none;

      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      cursor: pointer;

      border-radius: ${spacing['2xs']};
      background: none;
    `};
`;

export const StyledSubOptionsContainer = styled(motion.tr)<TableRowProps>`
  width: 100%;
  background-color: ${({ isOdd, theme: { colors } }) =>
    isOdd ? colors.colorWithOpacityGenerator('neutral.default', 0.5) : colors.neutral.lightest};
`;

export const StyledSubOptions = styled.td`
  width: 100%;
  padding-left: 10%;
`;

export const StyledSubOptionsRow = styled(ContentRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.default};

  :last-of-type {
    border-bottom: 0px;
  }
`;

export const StyledSubOptionsTitle = styled.p<TableRowProps>`
  ${({ theme: { typography, colors }, width, isActive }) =>
    css`
      width: ${width}%;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      text-overflow: ellipsis;
      color: ${colors.neutral.darkest};
    `};
`;

export const StyledSubOptionsData = styled.p<TableRowProps>`
  ${({ theme: { typography, colors }, width, isActive }) =>
    css`
      width: ${width}%;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: ${isActive ? colors.neutral.darkest : colors.neutral.default};
      padding-right: 10px;
    `};
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 200px;
  h1 {
    align-self: center;
    font-size: ${({ theme: { typography } }) => typography.fontSize.lg};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 16px 24px;
`;

export const ModalButton = styled.button`
  ${({ theme: { typography, spacing } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;

    border: none;
    background: none;

    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.semiBold};
    line-height: ${spacing.lg};
    cursor: pointer;
  `};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('neutral.default', 0.2)};
  border-radius: 50px;

  :hover {
    background-color: ${({ theme: { colors } }) =>
      colors.colorWithOpacityGenerator('neutral.default', 0.5)};
  }
`;
