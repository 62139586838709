import { combineReducers } from '@reduxjs/toolkit';

import auth from './authSlice';
import categories from './categoriesSlice';
import custodies from './custodiesSlice';
import campaigns from './campaignsSlice';
import adminUsers from './adminUsersSlice';
import evaluationForms from './evaluationFormsSlice';
import generalInfo from './generalInfoSlice';
import farmers from './farmersSlice';
import distributors from './distributorsSlice';

const rootReducer = combineReducers({
  auth,
  categories,
  custodies,
  campaigns,
  adminUsers,
  generalInfo,
  farmers,
  evaluationForms,
  distributors
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
