import { useEffect, useState } from 'react';

import * as S from './Users.styles';

import { useTranslation, Button, EmptyPage, Pagination, SearchField } from '@basfagro/core';

import {
  Header,
  NewUserModal,
  ActionTable,
  ConfirmModal,
  FeedbackModal
} from '../../../components';

import { useAdminUsers, useAuth } from '../../../hooks';
import { AdminUser } from '../../../types/models';
import Loading from '../../../assets/Loading.gif';

export const Users = () => {
  const { t } = useTranslation();
  const {
    users,
    totalPages,
    isLoading,
    listAdminUsers,
    createAdminUser,
    deleteAdminUser,
    editAdminUser
  } = useAdminUsers();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Administrator');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [choosenUser, setChoosenUser] = useState({} as AdminUser | undefined);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  useEffect(() => {
    if (searchText === '') {
      listAdminUsers(10, page);
    } else {
      listAdminUsers(10, page, searchText, () => {
        setPage(prev => (totalPages < page ? 1 : prev));
      });
    }
  }, [page, searchText, listAdminUsers, totalPages]);

  const onCreate = newUser => {
    createAdminUser(
      newUser,
      feedbackText => {
        listAdminUsers(10, page, '');
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        });
      },
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  const onEditTable = toBeEdited => {
    setChoosenUser(toBeEdited);
    setShowEditModal(true);
  };

  const onEdit = (id, editedUser) => {
    editAdminUser(
      id,
      editedUser,
      feedbackText => {
        listAdminUsers(10, page, '');
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        });
      },
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  const onDelete = toBeDeleted => {
    setChoosenUser(toBeDeleted);
    setShowConfirmModal(true);
  };

  return (
    <S.StyledContainer>
      <Header title={t('adminUsers.header')} subtitle={t('adminUsers.subtitle')} />
      <S.StyledRow>
        {hasPermission && (
          <S.StyledButtonContainer>
            <Button
              buttonType='primary'
              height='42px'
              customColor='secondary.lightest'
              onClick={() => {
                setChoosenUser(undefined);
                setShowCreateModal(true);
              }}
              marginTop='16px'
            >
              + {t('adminUsers.newUser')}
            </Button>
          </S.StyledButtonContainer>
        )}
        <S.StyledSearchContainer hasPermission={!!hasPermission}>
          <SearchField placeholder={t('adminUsers.searchAdmin')} onChangeText={setSearchText} />
        </S.StyledSearchContainer>
      </S.StyledRow>
      {isLoading ? (
        <S.StyledLoading src={Loading} />
      ) : users.length ? (
        <>
          <ActionTable
            headers={{
              name: t('fields.name'),
              phoneNumber: t('fields.phone'),
              email: t('fields.mail')
            }}
            onPressDelete={u => onDelete(u)}
            onPressEdit={u => onEditTable(u)}
            tableData={users}
            hasPermission={!!hasPermission}
          />
          <Pagination pagesTotal={totalPages} page={page} setPage={setPage} />
        </>
      ) : (
        <EmptyPage />
      )}
      {showCreateModal && (
        <NewUserModal
          visibility={showCreateModal}
          toggleVisibility={() => setShowCreateModal(false)}
          onSave={onCreate}
          hasPermission
        />
      )}
      {showEditModal && (
        <NewUserModal
          visibility={showEditModal}
          toggleVisibility={() => setShowEditModal(false)}
          onSave={onEdit}
          user={choosenUser}
          hasPermission={!!hasPermission}
        />
      )}
      <ConfirmModal
        title={t('modals.users.title', { name: choosenUser?.name })}
        content={t('modals.users.content', { name: choosenUser?.name })}
        isVisible={showConfirmModal}
        setVisibility={setShowConfirmModal}
        onConfirm={() => {
          deleteAdminUser(
            choosenUser?.id,
            feedbackText => {
              listAdminUsers(10, page, '');
              setShowConfirmModal(false);
              setFBModal({
                state: true,
                icon: 'IcPositiveFB',
                text: feedbackText
              });
            },
            feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
          );
        }}
      />
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => {
            setFBModal({ ...FBModal, state: !FBModal.state });
            if (FBModal.icon !== 'IcNegativeFB') {
              setShowCreateModal(false);
              setShowEditModal(false);
            }
          }}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </S.StyledContainer>
  );
};

export default Users;
