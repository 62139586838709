import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { colors, typography, spacing } from '@basfagro/core';
import '@basfagro/core/fonts';

import breakpoints from './breakpoints';

interface ThemeProviderProps {
  children?: JSX.Element | JSX.Element[];
}

const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <StyledThemeProvider theme={{ colors, typography, breakpoints, spacing }}>
    {children}
  </StyledThemeProvider>
);

export default ThemeProvider;
