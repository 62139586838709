import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../services/translations';

import * as S from './DistributorsTable.styles';
import { Icon } from '..';

import { useDistributors } from '../../hooks';

type HeaderType = {
  [keys: string]: string;
};

type DistributorsTableProps = {
  headers: HeaderType;
  tableData: {
    [keys: string]: any;
  }[];
  type: 'inProcess' | 'accepted' | 'denied';
  onPressDelete: Function;
  onPressApprove: Function;
  onPressDeny: Function;
  canEdit?: boolean;
};

const DistributorsTable = ({
  headers,
  tableData,
  type,
  onPressDelete,
  onPressApprove,
  onPressDeny,
  canEdit = false
}: DistributorsTableProps) => {
  const navigate = useNavigate();
  const { getDetailedDistributor } = useDistributors();

  const { t: translate } = useTranslation();

  const headersKeys = Object.keys(headers);

  const ActionsHeader = () => {
    if (canEdit) {
      switch (type) {
        case 'accepted':
          return (
            <S.TableHeaders style={{ width: '100px' }}>
              {translate('fields.evaluation')}
            </S.TableHeaders>
          );
        case 'denied':
          return (
            <S.TableHeaders style={{ width: '180px' }}>
              {translate('fields.evaluation')}
            </S.TableHeaders>
          );
        case 'inProcess':
          return (
            <S.TableHeaders style={{ width: '210px' }}>
              {translate('fields.evaluation')}
            </S.TableHeaders>
          );
        default:
          return;
      }
    } else {
      return (
        <S.TableHeaders style={{ width: '80px' }}>{translate('table.actions')}</S.TableHeaders>
      );
    }
  };

  return (
    <S.TableWrapper>
      <S.Table>
        <thead>
          <S.TableRow>
            {Object.values(headers).map(header => (
              <S.TableHeaders key={header}>{header}</S.TableHeaders>
            ))}
            {ActionsHeader()}
          </S.TableRow>
        </thead>
        <tbody>
          {tableData.map((dataGroup, index) => {
            return (
              <S.TableRow key={index}>
                {headersKeys.map(info => {
                  if (info === 'contactPhones') {
                    return (
                      <S.TableContent key={info}>
                        <S.ContentRow style={{ justifyContent: 'left' }}>
                          <S.TableText>{dataGroup[info][0]?.description}</S.TableText>
                          {dataGroup[info][1] && (
                            <S.NumbersPlusNumber>
                              <p>+{dataGroup[info].length - 1}</p>
                              <S.NumbersPlusList>
                                {dataGroup[info].slice(1).map((e, index) => (
                                  <li key={index}>
                                    <p>{e.description}</p>
                                  </li>
                                ))}
                              </S.NumbersPlusList>
                            </S.NumbersPlusNumber>
                          )}
                        </S.ContentRow>
                      </S.TableContent>
                    );
                  } else if (info === 'department') {
                    return (
                      <S.TableContent key={info}>
                        <S.TableText>{dataGroup[info].name}</S.TableText>
                      </S.TableContent>
                    );
                  } else {
                    return (
                      <S.TableContent key={info}>
                        <S.TableText>{dataGroup[info]}</S.TableText>
                      </S.TableContent>
                    );
                  }
                })}
                <S.TableContent>
                  {canEdit && type === 'inProcess' ? (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => getDetailedDistributor(dataGroup.id, () => navigate('edit'))}
                      >
                        <Icon icon='IcEdit' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='primary'
                        onClick={() => onPressApprove(dataGroup.id, dataGroup.name)}
                      >
                        {translate('custodyFields.buttons.approve')}
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='secondary'
                        onClick={() => onPressDeny(dataGroup.id, dataGroup.name)}
                      >
                        {translate('custodyFields.buttons.deny')}
                      </S.RegularButton>
                    </S.ContentRow>
                  ) : canEdit && type === 'accepted' ? (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => getDetailedDistributor(dataGroup.id, () => navigate('edit'))}
                      >
                        <Icon icon='IcEdit' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => onPressDelete(dataGroup.id, dataGroup.name)}
                      >
                        <Icon icon='IcTrash' color='secondary' />
                      </S.RegularButton>
                    </S.ContentRow>
                  ) : canEdit && type === 'denied' ? (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => getDetailedDistributor(dataGroup.id, () => navigate('edit'))}
                      >
                        <Icon icon='IcEdit' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => onPressDelete(dataGroup.id, dataGroup.name)}
                      >
                        <Icon icon='IcTrash' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='primary'
                        onClick={() => onPressApprove(dataGroup.id, dataGroup.name)}
                      >
                        {translate('custodyFields.buttons.approve')}
                      </S.RegularButton>
                    </S.ContentRow>
                  ) : (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => getDetailedDistributor(dataGroup.id, () => navigate('edit'))}
                      >
                        <Icon icon='IcEye' color='secondary' />
                      </S.RegularButton>
                    </S.ContentRow>
                  )}
                </S.TableContent>
              </S.TableRow>
            );
          })}
        </tbody>
      </S.Table>
    </S.TableWrapper>
  );
};

export default DistributorsTable;
