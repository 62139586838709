import { useState, useEffect } from 'react';
import moment from 'moment';

import { useTranslation, SearchField, EmptyPage, Button, Pagination } from '@basfagro/core';

import * as S from './Categories.styles';
import CategoryModal from './CategoryModal';

import { useCategories, useAuth } from '../../../hooks';
import {
  Header,
  ActionTable,
  FilterButton,
  ConfirmModal,
  FeedbackModal
} from '../../../components';
import { creationDateFilter } from '../../../types/filters';
import { CategoryModel } from '../../../types/models';
import Loading from '../../../assets/Loading.gif';

const filterParams = {
  sortBy: 2
};

const Categories = () => {
  const { t } = useTranslation();
  const {
    categories,
    totalPages,
    listCategories,
    createCategory,
    editCategory,
    deleteCategory,
    isLoadingList
  } = useCategories();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Administrator');

  const [EModalVisible, setEModalVisibility] = useState(false);
  const [DModalVisibility, setDModalVisibility] = useState(false);
  const [categoryToEdit, setCategoryEdit] = useState({} as CategoryModel | undefined);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilter, setFilter] = useState(filterParams);
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });
  const [editedOrCreated, setEorC] = useState(false);

  useEffect(() => {
    if (searchValue === '') {
      listCategories(page, '', selectedFilter['sortBy']);
    } else {
      listCategories(page, searchValue, selectedFilter['sortBy'], () => {
        setPage(prev => (totalPages < page ? 1 : prev));
      });
    }
  }, [listCategories, page, searchValue, selectedFilter, totalPages, editedOrCreated]);

  const onConfirm = newCategory => {
    if (!categoryToEdit?.id) {
      createCategory(
        newCategory,
        feedbackText =>
          setFBModal({
            state: true,
            icon: 'IcPositiveFB',
            text: feedbackText
          }),
        feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
      );
    } else {
      setEModalVisibility(false);
      editCategory(
        { ...(categoryToEdit ? categoryToEdit : {}), ...newCategory },
        feedbackText =>
          setFBModal({
            state: true,
            icon: 'IcPositiveFB',
            text: feedbackText
          }),
        feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
      );
    }
  };

  const onClose = () => {
    setEModalVisibility(false);
    setDModalVisibility(false);
    setCategoryEdit(undefined);
  };

  const onPressEdit = category => {
    setEModalVisibility(true);
    setCategoryEdit(category);
  };

  const onPressDelete = category => {
    setDModalVisibility(true);
    setCategoryEdit(category);
  };

  const onConfirmDelete = () => {
    deleteCategory(
      categoryToEdit?.id,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <>
      <S.StyledContainer>
        <Header title={t('riteCategory.title')} subtitle={t('riteCampaign.riteSubtitle')} />
        {hasPermission && (
          <S.StyledButtonContainer>
            <Button
              buttonType='primary'
              height='42px'
              customColor='secondary.lightest'
              onClick={() => setEModalVisibility(true)}
              marginTop='16px'
            >
              + {t('riteCategory.buttons.newCategory')}
            </Button>
          </S.StyledButtonContainer>
        )}
        <S.StyledSearchContainer>
          <SearchField
            placeholder={t('riteCampaign.searchCategory')}
            onChangeText={setSearchValue}
            marginTop='24px'
            marginBottom='16px'
            marginRight='16px'
          />
          <FilterButton
            filterOptions={creationDateFilter}
            selectedDefault={selectedFilter}
            onClickFilter={a => setFilter(a)}
          />
        </S.StyledSearchContainer>
        {isLoadingList ? (
          <S.StyledLoading src={Loading} />
        ) : !!categories.length ? (
          <ActionTable
            headers={{
              name: t('riteCategory.fields.categoryName'),
              createdBy: t('riteCategory.fields.createdBy'),
              creationDate: t('riteCategory.fields.creationDate')
            }}
            formatRow={{
              creationDate: v => moment(v).format('DD/MM/YYYY')
            }}
            tableData={categories}
            onPressEdit={onPressEdit}
            onPressDelete={onPressDelete}
            hasPermission={!!hasPermission}
          />
        ) : (
          <EmptyPage />
        )}
        {!!totalPages && <Pagination pagesTotal={totalPages} page={page} setPage={setPage} />}
      </S.StyledContainer>
      {EModalVisible && (
        <CategoryModal
          isVisible={EModalVisible}
          categoryToEdit={categoryToEdit}
          toggleVisibility={onClose}
          onConfirm={onConfirm}
          hasPermission={!!hasPermission}
        />
      )}
      {DModalVisibility && (
        <ConfirmModal
          isVisible={DModalVisibility}
          setVisibility={onClose}
          onConfirm={onConfirmDelete}
          title={t('modals.categories.deleteTitle')}
          content={t('modals.categories.deleteContent', { name: categoryToEdit?.name })}
        />
      )}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => {
            setFBModal({ ...FBModal, state: !FBModal.state });
            setEModalVisibility(false);
            setDModalVisibility(false);
            setCategoryEdit(undefined);
            setEorC(prev => !prev);
          }}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </>
  );
};

export default Categories;
