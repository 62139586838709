import { useTranslation, Button } from '@basfagro/core';

import { Icon } from '../../../components';

import { QuestionsType } from './EvaluationForm.utils';

import * as S from './EvaluationForm.styles';

type QuestionsTableType = {
  questions: QuestionsType[];
  addQuestion: Function;
  onDelete: Function;
  onEdit: Function;
  disabled: boolean;
  error: string;
};

const QuestionsTable = ({
  questions = [],
  addQuestion,
  onDelete,
  onEdit,
  disabled,
  error
}: QuestionsTableType) => {
  const { t } = useTranslation();

  return (
    <>
      {!!questions.length && (
        <S.QuestionsTableRow style={{ backgroundColor: 'white' }}>
          <S.QuestionsTableCell width={40}>
            <S.QuestionsTableHeader>{t('evaluationForm.question')}</S.QuestionsTableHeader>
          </S.QuestionsTableCell>
          <S.QuestionsTableCell width={disabled ? 20 : 10}>
            <S.QuestionsTableHeader>{t('evaluationForm.type')}</S.QuestionsTableHeader>
          </S.QuestionsTableCell>
          <S.QuestionsTableCell width={40}>
            <S.QuestionsTableHeader>{t('evaluationForm.pontuationTitle')}</S.QuestionsTableHeader>
          </S.QuestionsTableCell>
          {!disabled && (
            <S.QuestionsTableCell width={10}>
              <S.QuestionsTableHeader>{t('table.actions')}</S.QuestionsTableHeader>
            </S.QuestionsTableCell>
          )}
        </S.QuestionsTableRow>
      )}
      <S.QuestionsContainer>
        {!!questions.length &&
          questions.map(({ options, description }, index) => (
            <S.QuestionsTableRow key={description}>
              <S.QuestionsTableCell width={40}>
                <S.QuestionsTableText>{description}</S.QuestionsTableText>
              </S.QuestionsTableCell>
              <S.QuestionsTableCell width={disabled ? 20 : 10}>
                <S.QuestionsTableText>
                  {options?.length ? t('evaluationForm.objective') : t('evaluationForm.subjective')}
                </S.QuestionsTableText>
              </S.QuestionsTableCell>
              <S.QuestionsTableCell width={40}>
                {options?.map(({ score, description }) => (
                  <S.PontuationsContainer key={description}>
                    <S.QuestionsTableText>{description}</S.QuestionsTableText>
                    <S.NumberContainer>
                      {score === -1 ? t('evaluationForm.farmerRefused') : score}
                    </S.NumberContainer>
                  </S.PontuationsContainer>
                ))}
              </S.QuestionsTableCell>
              {!disabled && (
                <S.QuestionsTableCell width={10}>
                  <S.StyledRow>
                    <S.RegularButton onClick={() => onEdit(index)}>
                      <Icon icon='IcEdit' color='secondary' />
                    </S.RegularButton>
                    <S.RegularButton onClick={() => onDelete(index)}>
                      <Icon icon='IcTrash' color='secondary' />
                    </S.RegularButton>
                  </S.StyledRow>
                </S.QuestionsTableCell>
              )}
            </S.QuestionsTableRow>
          ))}
        {error && (
          <S.StyledRow style={{ justifyContent: 'center' }}>
            <S.StyledError>{error}</S.StyledError>
          </S.StyledRow>
        )}
      </S.QuestionsContainer>
      {!disabled && (
        <Button buttonType='secondary' onClick={() => addQuestion()} col={4} height='42px'>
          + {t('evaluationForm.addQuestion')}
        </Button>
      )}
    </>
  );
};

export default QuestionsTable;
