import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import {
  LIST_EVALUATION_FORMS,
  LIST_EVALUATION_FORMS_SUCCESS,
  LIST_EVALUATION_FORMS_FAILURE,
  CREATE_EVALUATION_FORM,
  CREATE_EVALUATION_FORM_SUCCESS,
  CREATE_EVALUATION_FORM_FAILURE,
  CHANGE_STATUS_FORM,
  CHANGE_STATUS_FORM_SUCCESS,
  CHANGE_STATUS_FORM_FAILURE,
  LIST_FORM_CATEGORY,
  LIST_FORM_CATEGORY_SUCCESS,
  LIST_FORM_CATEGORY_FAILURE,
  CREATE_FORM_CATEGORY,
  CREATE_FORM_CATEGORY_SUCCESS,
  CREATE_FORM_CATEGORY_FAILURE,
  EDIT_FORM_CATEGORY,
  EDIT_FORM_CATEGORY_SUCCESS,
  EDIT_FORM_CATEGORY_FAILURE,
  DELETE_FORM_CATEGORY,
  DELETE_FORM_CATEGORY_SUCCESS,
  DELETE_FORM_CATEGORY_FAILURE
} from '../slices/evaluationFormsSlice';

import api from '../../services/api';

type FormParams = {
  payload: {
    successCallback: Function;
    failureCallback: Function;
    newForm?: {};
    newCategory?: {};
    categoryID?: {};
    searchText?: string;
    formID?: number;
    pgIndex?: number;
    pgSize?: number;
    sortBy: number;
    status: boolean;
  };
};

function* listEvaluationForms({
  payload: { successCallback = () => {}, pgIndex, pgSize, searchText, sortBy }
}: FormParams) {
  try {
    const search = searchText ? `&Description=${searchText}` : '';
    const sort = sortBy ? `&SortBy=${sortBy}` : '';
    const { data } = yield call(
      api.get,
      `formsmanagement/forms?PageIndex=${pgIndex}&PageSize=${pgSize}${search}${sort}`
    );

    yield put(LIST_EVALUATION_FORMS_SUCCESS(data));
    successCallback();
  } catch (error) {
    yield put(LIST_EVALUATION_FORMS_FAILURE({ error }));
  }
}

function* createEvaluationForm({ payload: { successCallback = () => {}, newForm } }: FormParams) {
  try {
    const { data } = yield call(api.post, `formsmanagement/forms`, newForm);

    yield put(CREATE_EVALUATION_FORM_SUCCESS(data));
    successCallback();
  } catch (error) {
    yield put(CREATE_EVALUATION_FORM_FAILURE({ error }));
  }
}

function* changeStatusForm({
  payload: { formID, status, successCallback = () => {} }
}: FormParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `formsmanagement/forms/${formID}/enable-disable`, {
      activate: status
    });
    yield put(CHANGE_STATUS_FORM_SUCCESS(formID));
    successCallback();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(CHANGE_STATUS_FORM_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* listFormCategories({
  payload: { successCallback = () => {}, pgIndex, pgSize, searchText, sortBy }
}: FormParams) {
  try {
    const size = pgSize ? `&PageSize=${pgSize}` : '';
    const search = searchText ? `&Name=${searchText}` : '';
    const sort = sortBy ? `&SortBy=${sortBy}` : '';
    const { data } = yield call(
      api.get,
      `formsmanagement/questioncategories?PageIndex=${pgIndex}${size}${search}${sort}`
    );
    yield put(LIST_FORM_CATEGORY_SUCCESS(data));
    successCallback();
  } catch (error) {
    yield put(LIST_FORM_CATEGORY_FAILURE({ error }));
  }
}

function* createFormCategory({
  payload: { successCallback = () => {}, failureCallback = () => {}, newCategory }
}: FormParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const { data } = yield call(api.post, `formsmanagement/questioncategories`, {
      name: newCategory
    });
    yield put(CREATE_FORM_CATEGORY_SUCCESS(data));
    successCallback(i18n.t('modals.categories.categoryCreated'));
    toast.dismiss(toastId);
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('modals.categories.createError'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(CREATE_FORM_CATEGORY_FAILURE({ error }));
    toast.dismiss(toastId);
  }
}

function* editFormCategory({
  payload: { successCallback = () => {}, failureCallback = () => {}, categoryID, newCategory }
}: FormParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `formsmanagement/questioncategories/${categoryID}`, {
      name: newCategory
    });
    yield put(EDIT_FORM_CATEGORY_SUCCESS({ id: categoryID, name: newCategory }));
    successCallback(i18n.t('feedback.editComplete'));
    toast.dismiss(toastId);
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('modals.categories.editError'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(EDIT_FORM_CATEGORY_FAILURE({ error }));
    toast.dismiss(toastId);
  }
}

function* deleteFormCategory({
  payload: { successCallback = () => {}, failureCallback = () => {}, categoryID }
}: FormParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `formsmanagement/questioncategories/${categoryID}`);
    yield put(DELETE_FORM_CATEGORY_SUCCESS(categoryID));
    successCallback(i18n.t('modals.categories.categoryDeleted'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(DELETE_FORM_CATEGORY_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

export default function* watcher() {
  yield takeEvery(LIST_EVALUATION_FORMS, listEvaluationForms);
  yield takeEvery(CREATE_EVALUATION_FORM, createEvaluationForm);
  yield takeEvery(CHANGE_STATUS_FORM, changeStatusForm);
  yield takeEvery(LIST_FORM_CATEGORY, listFormCategories);
  yield takeEvery(CREATE_FORM_CATEGORY, createFormCategory);
  yield takeEvery(EDIT_FORM_CATEGORY, editFormCategory);
  yield takeEvery(DELETE_FORM_CATEGORY, deleteFormCategory);
}
