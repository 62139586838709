import * as yup from 'yup';
import i18n from '../../../services/translations';

export type ScreenType = {
  minDays: number;
  maxDays: number;
  visitRequired: boolean;
  finalDateRequired: boolean;
  isGermination: boolean;
  category: string;
  buttons: number[];
  recomendations: string[];
  products: string[];
};

export type UseFormType = {
  name: string;
  cultive: string;
  variety: string;
  screens: ScreenType[];
};

export const screenDefaultValues: ScreenType = {
  minDays: 0,
  maxDays: 0,
  visitRequired: true,
  finalDateRequired: true,
  isGermination: false,
  category: '',
  buttons: [],
  recomendations: [],
  products: []
};

export const validationSchema = yup
  .object({
    name: yup.string().required(i18n.t('validations.required')),
    cultive: yup.string().required(i18n.t('validations.required')),
    variety: yup.string().required(i18n.t('validations.required')),
    screens: yup.array().of(
      yup.object({
        category: yup.string().required(i18n.t('validations.required')),
        buttons: yup.array().of(yup.number()).min(1, i18n.t('validations.required')),
        minDays: yup
          .number()
          .typeError(i18n.t('validations.invalidNumber'))
          .required(i18n.t('validations.required')),
        maxDays: yup
          .number()
          .typeError(i18n.t('validations.invalidNumber'))
          .min(yup.ref('minDays'), i18n.t('validations.greaterThanMinDays'))
          .required(i18n.t('validations.required')),
        visitRequired: yup.boolean(),
        finalDateRequired: yup.boolean(),
        isGermination: yup.boolean(),
        recomendations: yup.array().of(yup.string()),
        products: yup.array().of(yup.string())
      })
    )
  })
  .required();

export const buttonOptions = [
  //{ id: 1, icon: 'IcGalery', title: i18n.t('riteCampaign.galery') },
  { id: 2, icon: 'IcNote', title: i18n.t('riteCampaign.note') },
  { id: 3, icon: 'IcWarning', title: i18n.t('riteCampaign.recommendation') },
  { id: 4, icon: 'IcCalendarPlus', title: i18n.t('riteCampaign.requestVisit') },
  { id: 5, icon: 'IcTestTube', title: i18n.t('riteCampaign.products') }
];
