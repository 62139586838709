import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useTranslation, Pagination, EmptyPage, SearchField } from '@basfagro/core';
import * as S from './Farmers.styles';

import { Header, ActionTable, ConfirmModal, FeedbackModal } from '../../components';
import Loading from '../../assets/Loading.gif';
import { useFarmers, useAuth } from '../../hooks';

const Farmers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    farmers,
    pagesCount,
    isLoading,
    searchedFarmer,
    farmerPageIndex,
    listFarmers,
    getDetailedFarmer,
    deleteFarmer,
    editSearchedFarmerText,
    editFarmerPageIndex
  } = useFarmers();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Farmer');
  const [searchValue, setSearchValue] = useState(searchedFarmer);
  const [page, setPage] = useState(farmerPageIndex);
  const [DModalVisibility, setDModalVisibility] = useState(false);
  const [choosenFarmer, setChoosenFarmer] = useState({ name: '', id: 0 });
  const [FBModalVisibility, setFBVisibility] = useState(false);

  useEffect(() => {
    if (searchValue !== '') {
      listFarmers(10, page, searchValue, () => {
        setPage(prev => (pagesCount < page ? 1 : prev));
      });
    } else {
      listFarmers(10, page);
    }
    editSearchedFarmerText(searchValue);
    editFarmerPageIndex(page);
  }, [page, searchValue, pagesCount]);

  const onPressDelete = farmer => {
    setChoosenFarmer({ name: farmer.name, id: farmer.id });
    setDModalVisibility(true);
  };

  const onConfirmDelete = () => {
    setDModalVisibility(false);
    deleteFarmer(choosenFarmer.id);
  };

  return (
    <S.StyledContainer>
      <Header title={t('menu.farmers')} subtitle={t('farmers.subtitle')} />
      <S.contentRow>
        <SearchField
          placeholder={t('farmers.search')}
          value={searchValue}
          onChangeText={setSearchValue}
          marginTop='16px'
          marginBottom='16px'
        />
      </S.contentRow>
      {isLoading ? (
        <S.StyledLoading src={Loading} />
      ) : farmers.length ? (
        <ActionTable
          headers={{
            name: t('fields.name'),
            city: t('farmers.city'),
            department: t('farmers.state')
          }}
          tableData={farmers}
          onPressEdit={user =>
            getDetailedFarmer(
              user.id,
              () => navigate(`profile/${user.id}`),
              () => setFBVisibility(true)
            )
          }
          onPressDelete={() => {}}
          hasPermission={false}
        />
      ) : (
        <EmptyPage />
      )}
      <S.contentRow>
        <Pagination pagesTotal={pagesCount} page={page} setPage={setPage} />
      </S.contentRow>
      {DModalVisibility && (
        <ConfirmModal
          isVisible={DModalVisibility}
          setVisibility={() => setDModalVisibility(prev => !prev)}
          onConfirm={onConfirmDelete}
          title={t('modals.farms.farmerTitle')}
          content={t('modals.farms.farmerContent', { name: choosenFarmer.name })}
        />
      )}
      {FBModalVisibility && (
        <FeedbackModal
          isVisible={FBModalVisibility}
          setVisibility={() => setFBVisibility(prev => !prev)}
          text={t('feedback.failure')}
          icon='IcNegativeFB'
        />
      )}
    </S.StyledContainer>
  );
};

export default Farmers;
