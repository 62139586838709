import { useTranslation } from '../../services/translations';

import TableRow from './TableRow';
import * as S from './ActionTable.styles';

type HeaderType = {
  [keys: string]: string;
};

type ActionTableProps = {
  headers: HeaderType;
  subHeaders?: HeaderType;
  subOptionsKey?: string;
  tableData: {
    [keys: string]: string | number | object | boolean | object[];
  }[];
  formatRow?: {
    [keys: string]: Function;
  };
  formatSubRow?: {
    [keys: string]: Function;
  };
  onPressEdit: Function;
  onPressDelete: Function;
  hasPermission?: boolean;
};

const ActionTable = ({
  headers,
  subHeaders,
  tableData,
  onPressEdit,
  onPressDelete,
  formatRow,
  formatSubRow,
  subOptionsKey,
  hasPermission = false
}: ActionTableProps) => {
  const { t } = useTranslation();
  return (
    <S.Table>
      <thead>
        <S.TableRow>
          {Object.values(headers).map((header, index) => (
            <S.TableHeaders key={index}>{header}</S.TableHeaders>
          ))}
          <S.TableHeaders hasPermission={hasPermission}>{t('table.actions')}</S.TableHeaders>
        </S.TableRow>
      </thead>
      <tbody>
        {tableData.map((dataGroup, index) => (
          <TableRow
            key={index}
            headers={headers}
            formatRow={formatRow}
            formatSubRow={formatSubRow}
            data={dataGroup}
            onPressEdit={() => onPressEdit(dataGroup)}
            onPressDelete={() => onPressDelete(dataGroup)}
            subHeaders={subHeaders}
            isOdd={index % 2 === 0 ? true : false}
            subOptionsKey={subOptionsKey}
            hasPermission={hasPermission}
          />
        ))}
      </tbody>
    </S.Table>
  );
};

export default ActionTable;
