import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import {
  LIST_CATEGORY,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY
} from '../store/slices/categoriesSlice';

export const useCategories = () => {
  const dispatch = useDispatch();

  const { categories, isLoading, isLoadingList, error, totalPages } = useSelector(
    ({ categories }: RootState) => categories
  );

  const listCategories = useCallback(
    (page?, searchText?, sortBy?, successCallback?, failureCallback?) =>
      dispatch(LIST_CATEGORY({ page, searchText, sortBy, successCallback, failureCallback })),
    [dispatch]
  );

  const createCategory = useCallback(
    (newCategory, successCallback?, failureCallback?) =>
      dispatch(CREATE_CATEGORY({ newCategory, successCallback, failureCallback })),
    [dispatch]
  );

  const editCategory = useCallback(
    (newCategory, successCallback?, failureCallback?) =>
      dispatch(EDIT_CATEGORY({ newCategory, successCallback, failureCallback })),
    [dispatch]
  );

  const deleteCategory = useCallback(
    (id, successCallback?, failureCallback?) =>
      dispatch(DELETE_CATEGORY({ id, successCallback, failureCallback })),
    [dispatch]
  );

  return {
    categories,
    isLoading,
    isLoadingList,
    error,
    totalPages,
    listCategories,
    createCategory,
    editCategory,
    deleteCategory
  };
};
