import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useTranslation, Button } from '@basfagro/core';

import PersonalData from './PersonalData';
import Farms from './Farms';
import Employees from './Employees';

import * as S from './FarmerProfile.styles';

import { Header, TabBar } from '../../../components';
import { useFarmers } from '../../../hooks';

const FarmerProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { farmerTabIndex, editSearchedFarmText, editFarmerTabIndex } = useFarmers();
  const [selectedTab, setSelectedTab] = useState(farmerTabIndex);

  const renderTab = () => {
    switch (selectedTab) {
      case 0:
        return <PersonalData />;
      case 1:
        return <Farms />;
      case 2:
        return <Employees />;
      default:
        return <PersonalData />;
    }
  };

  const onChangeTab = id => {
    editFarmerTabIndex(id);
    setSelectedTab(id);
    editSearchedFarmText('');
  };

  return (
    <S.StyledContainer>
      <Header title={t('menu.farmers')} subtitle={t('farmers.farmerSubtitle')} />
      <Button
        buttonType='tertiary'
        onClick={() => {
          editFarmerTabIndex(0);
          navigate(-1);
        }}
      >
        {`< ${t('evaluationForm.goBack')}`}
      </Button>
      <TabBar
        options={[
          { id: 0, name: t('farmers.personalData') },
          { id: 1, name: t('farmers.farms') },
          { id: 2, name: t('farmers.employees') }
        ]}
        onPressOption={onChangeTab}
        selectedOpt={selectedTab}
      />
      {renderTab()}
    </S.StyledContainer>
  );
};

export default FarmerProfile;
