import { createSlice } from '@reduxjs/toolkit';

import { DistributorModel } from '../../types/models';

type DistributorsSliceType = {
  isLoading: boolean;
  distributors: {
    id: number;
    name: string;
    email: string;
    status: number;
    department: { id: number; name: string };
    contactPhones: { id: number; description: string; type: number }[];
  }[];
  selectedDistributor: DistributorModel;
  searchedText: string;
  pageIndex: number;
  pagesCount: number;
  totalDistributors: number;
  error: Object | null;
  tabIndex: number;
};

const initialState: DistributorsSliceType = {
  isLoading: false,
  distributors: [],
  selectedDistributor: {
    id: 0,
    email: '',
    name: '',
    companyName: '',
    nit: '',
    profilePicture: '',
    address: {
      id: 0,
      fullAddress: '',
      city: { id: 0, name: '', score: 0, department: { id: 0, name: '' } }
    },
    contactPhones: [{ id: 0, description: '', type: 0 }],
    contactEmails: [{ id: 0, description: '' }]
  },
  searchedText: '',
  pageIndex: 1,
  pagesCount: 10,
  totalDistributors: 100,
  error: null,
  tabIndex: 0
};

const distributorSlice = createSlice({
  name: 'distributors',
  initialState,
  reducers: {
    LIST_DISTRIBUTORS: (state, action) => ({ ...state, isLoading: true }),
    LIST_DISTRIBUTORS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      distributors: payload.dataList,
      pagesCount: payload.pagesCount,
      totalDistributors: payload.totalData
    }),
    LIST_DISTRIBUTORS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EVALUATE_DISTRIBUTOR: (state, action) => ({ ...state, isLoading: true }),
    EVALUATE_DISTRIBUTOR_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      distributors: state.distributors.filter(distributor => distributor.id !== payload.id)
    }),
    EVALUATE_DISTRIBUTOR_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_DISTRIBUTOR: (state, action) => ({ ...state, isLoading: true }),
    DELETE_DISTRIBUTOR_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      distributors: state.distributors.filter(({ id }) => id !== payload.id)
    }),
    DELETE_DISTRIBUTOR_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_DISTRIBUTOR: (state, action) => ({
      ...state,
      isLoading: true
    }),
    EDIT_DISTRIBUTOR_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      distributors: state.distributors.map(distributor =>
        distributor.id !== payload.id ? distributor : { ...distributor, payload }
      )
    }),
    EDIT_DISTRIBUTOR_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    GET_DETAILED_DISTRIBUTOR: (state, action) => ({
      ...state,
      isLoading: true
    }),
    GET_DETAILED_DISTRIBUTOR_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      selectedDistributor: payload
    }),
    GET_DETAILED_DISTRIBUTOR_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_TAB_INDEX: (state, { payload }) => ({ ...state, tabIndex: payload }),
    EDIT_SEARCHED_TEXT: (state, { payload }) => ({ ...state, searchedText: payload }),
    EDIT_PAGE_INDEX: (state, { payload }) => ({ ...state, pageIndex: payload })
  }
});

const { actions, reducer } = distributorSlice;

export const {
  LIST_DISTRIBUTORS,
  LIST_DISTRIBUTORS_SUCCESS,
  LIST_DISTRIBUTORS_FAILURE,
  EVALUATE_DISTRIBUTOR,
  EVALUATE_DISTRIBUTOR_SUCCESS,
  EVALUATE_DISTRIBUTOR_FAILURE,
  DELETE_DISTRIBUTOR,
  DELETE_DISTRIBUTOR_SUCCESS,
  DELETE_DISTRIBUTOR_FAILURE,
  GET_DETAILED_DISTRIBUTOR,
  GET_DETAILED_DISTRIBUTOR_SUCCESS,
  GET_DETAILED_DISTRIBUTOR_FAILURE,
  EDIT_DISTRIBUTOR,
  EDIT_DISTRIBUTOR_SUCCESS,
  EDIT_DISTRIBUTOR_FAILURE,
  EDIT_TAB_INDEX,
  EDIT_SEARCHED_TEXT,
  EDIT_PAGE_INDEX
} = actions;

export default reducer;
