import { useTranslation, TextField } from '@basfagro/core';
import { FieldError } from 'react-hook-form';
import * as S from './DaysInput.styles';

type DaysInputProps = {
  label: string;
  col?: number;
  firstValue?: string;
  lastValue?: string;
  onChangeFirst: Function;
  onChangeLast: Function;
  firstError?: FieldError;
  lastError?: FieldError;
  disabled?: boolean;
};

const DaysInput = ({
  label,
  col = 16,
  onChangeFirst,
  onChangeLast,
  firstValue,
  lastValue,
  firstError,
  lastError,
  disabled = false
}: DaysInputProps) => {
  const { t } = useTranslation();
  return (
    <S.Container width={(100 / 16) * col}>
      {{ label } && (
        <S.Label htmlFor={label} disabled={disabled}>
          {label}
        </S.Label>
      )}
      <S.Row>
        <TextField
          col={8}
          sufix={t('riteCampaign.days')}
          maxLength={3}
          placeholder='0'
          value={firstValue}
          onChange={e => onChangeFirst(e.target.value)}
          error={firstError}
          disabled={disabled}
        />
        <TextField
          col={8}
          sufix={t('riteCampaign.days')}
          maxLength={3}
          placeholder='0'
          value={lastValue}
          onChange={e => onChangeLast(e.target.value)}
          error={lastError}
          disabled={disabled}
        />
      </S.Row>
    </S.Container>
  );
};

export default DaysInput;
