import * as yup from 'yup';
import i18n from '../../../services/translations';

export type PontuationType = {
  minimumScore: number;
  maximumScore: number;
  farmerLevel: string;
  numberOfVisits: string;
};

export type AnswerType = {
  description: string;
  score: number;
};

export type QuestionsType = {
  description: string;
  options: AnswerType[];
};

export type CategoriesType = {
  id: number;
  title: string;
  questions: QuestionsType[];
};

export type UseFormType = {
  title: string;
  cultive: string;
  document: string;
  formType: string;
  pontuations: PontuationType[];
  categories: CategoriesType[];
};

export const screenDefaultValues: UseFormType = {
  title: '',
  cultive: '',
  document: '',
  formType: '',
  pontuations: [
    {
      minimumScore: 0,
      maximumScore: 0,
      farmerLevel: '',
      numberOfVisits: '0'
    }
  ],
  categories: []
};

const questionsSchema = {
  description: yup.string().required(i18n.t('validations.required')),
  options: yup.array().of(
    yup.object({
      description: yup.string().required(i18n.t('validations.required')),
      score: yup
        .number()
        .min(-1, i18n.t('validations.invalidNumber'))
        .typeError(i18n.t('validations.invalidNumber'))
        .required(i18n.t('validations.required'))
    })
  )
};

export const validationSchema = yup
  .object({
    title: yup.string().required(i18n.t('validations.required')),
    cultive: yup.string().required(i18n.t('validations.required')),
    document: yup.string().required(i18n.t('validations.required')),
    formType: yup.string().required(i18n.t('validations.required')),
    pontuations: yup
      .array()
      .min(1)
      .of(
        yup.object({
          minimumScore: yup
            .number()
            .typeError(i18n.t('feedback.invalid'))
            .min(0, i18n.t('feedback.invalid'))
            .required(i18n.t('feedback.invalid')),
          maximumScore: yup
            .number()
            .typeError(i18n.t('feedback.invalid'))
            .min(yup.ref('minimumScore'), i18n.t('feedback.invalid'))
            .required(i18n.t('feedback.invalid')),
          farmerLevel: yup.string().required(i18n.t('validations.required')),
          numberOfVisits: yup
            .number()
            .typeError(i18n.t('validations.invalidValue'))
            .min(0, i18n.t('validations.invalidNumber'))
            .required(i18n.t('validations.required'))
        })
      ),
    categories: yup
      .array()
      .min(1, i18n.t('evaluationForm.categoryError'))
      .of(
        yup.object({
          id: yup.number().required(i18n.t('validations.required')),
          title: yup.string().required(i18n.t('validations.required')),
          questions: yup
            .array()
            .min(1, i18n.t('evaluationForm.questionError'))
            .of(yup.object(questionsSchema))
            .required(i18n.t('validations.required'))
        })
      )
  })
  .required();

export const questionsValidationSchema = yup.object(questionsSchema).required();

export const farmerLevels = [
  i18n.t('evaluationForm.farmerRefused'),
  i18n.t('evaluationForm.farmerHighRisk'),
  i18n.t('evaluationForm.farmerMediumRisk'),
  i18n.t('evaluationForm.farmerLowRisk')
];

export const questionsTypes = [
  i18n.t('evaluationForm.objective'),
  i18n.t('evaluationForm.subjective')
];

export const allowSignature = [i18n.t('evaluationForm.yes'), i18n.t('evaluationForm.no')];
