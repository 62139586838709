import styled, { css } from 'styled-components';
import { default as RootIcon } from '../Icon/Icon';

type ContentProps = {
  bold?: boolean;
};

type IconWrapperProps = {
  isSelected?: boolean;
};

export const IconCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  overflow: hidden;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ theme: { spacing, colors }, isSelected = false }) => css`
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    margin-right: ${spacing.md};
    border-radius: 100%;
    background-color: ${isSelected ? colors.secondary.lightest : colors.neutral.default};
    opacity: ${isSelected ? 1 : 0.3};
  `};

  :hover {
    cursor: pointer;
  }
`;

export const Icon = styled(RootIcon)`
  ${({ theme: { spacing } }) => css`
    display: flex;
    width: 52.9px;
    height: 52.9px;
  `};
`;

export const CardContent = styled.p<ContentProps>`
  ${({ theme: { colors, typography }, bold = false }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.neutral.darkest};
    font-size: ${typography.fontSize.xm};
    font-weight: ${bold ? typography.fontWeight.bold : typography.fontWeight.regular};
  `};
`;
