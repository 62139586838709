import * as S from './Header.styles';

import { Icon } from '..';

import { useAuth } from '../../hooks';

export type HeaderProps = {
  title?: string;
  subtitle?: string;
  showProfile?: boolean;
};

const Header = ({ title, subtitle, showProfile = false }: HeaderProps) => {
  const { user } = useAuth();
  return (
    <S.Container>
      <S.TitleArea>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.TitleArea>
      {showProfile && (
        <S.ProfileArea>
          <S.RoundArea isProfile>
            <S.ProfileArea>
              <S.ProfilePhoto src={user.avatar} />
              <S.ProfileName>{user.name}</S.ProfileName>
            </S.ProfileArea>
            <Icon icon='IcChevronDown' color='black' />
          </S.RoundArea>
          <S.RoundArea>
            <Icon icon='IcNotification' color='black' />
          </S.RoundArea>
        </S.ProfileArea>
      )}
    </S.Container>
  );
};

export default Header;
