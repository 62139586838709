import { useState, useEffect } from 'react';
import { useTranslation, EmptyPage, SearchField } from '@basfagro/core';

import * as S from './Farm.styles';

import { useFarmers, useAuth } from '../../../hooks';
import { ActionTable, LandplotModal, ConfirmModal, FeedbackModal } from '../../../components';
import { LandPlotModel } from '../../../types/models';

type LandplotProps = {
  farmID: string;
};

const Landplots = ({ farmID }: LandplotProps) => {
  const { t } = useTranslation();
  const { landplots, listLandPlots, deleteLandplot, listFarmEmployees } = useFarmers();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Farmer');
  const [LPModalVisibility, setLPVisibility] = useState(false);
  const [choosenLandplot, setChoosenLandplot] = useState({} as LandPlotModel | undefined);
  const [DModalVisibility, setDModalVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  useEffect(() => {
    if (searchValue !== '') {
      listLandPlots(farmID, searchValue);
    } else {
      listLandPlots(farmID);
    }
  }, [searchValue]);

  useEffect(() => {
    listFarmEmployees(farmID);
  }, []);

  const onPressEdit = LP => {
    setChoosenLandplot(LP);
    setLPVisibility(true);
  };

  const onPressDelete = LP => {
    setDModalVisibility(true);
    setChoosenLandplot(LP);
  };

  const onConfirmDelete = () => {
    setDModalVisibility(false);
    deleteLandplot(
      choosenLandplot?.id,
      feedbackText => {
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        });
      },
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <>
      <S.StyledWrapper>
        <SearchField
          placeholder={t('farmers.searchLP')}
          onChangeText={setSearchValue}
          marginBottom='16px'
        />
        {landplots.length ? (
          <ActionTable
            headers={{ name: t('fields.name'), areaInHectares: t('farmers.area') }}
            tableData={landplots}
            onPressDelete={onPressDelete}
            onPressEdit={onPressEdit}
            hasPermission={!!hasPermission}
          />
        ) : (
          <EmptyPage />
        )}
      </S.StyledWrapper>
      {LPModalVisibility && (
        <LandplotModal
          isVisible={LPModalVisibility}
          setVisibility={setLPVisibility}
          onConfirm={() => {}}
          landplot={choosenLandplot}
          hasPermission={!!hasPermission}
        />
      )}
      {DModalVisibility && (
        <ConfirmModal
          isVisible={DModalVisibility}
          setVisibility={() => setDModalVisibility(prev => !prev)}
          onConfirm={onConfirmDelete}
          title={t('modals.farms.landplotTitle')}
          content={t('modals.farms.landplotContent', { name: choosenLandplot?.name })}
        />
      )}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => setFBModal({ ...FBModal, state: !FBModal.state })}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </>
  );
};

export default Landplots;
