import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select, ErrorText, TextField, Button, useTranslation } from '@basfagro/core';

import * as S from './EvaluationForm.styles';

import {
  UseFormType,
  CategoriesType,
  screenDefaultValues,
  validationSchema,
  farmerLevels,
  allowSignature
} from './EvaluationForm.utils';

import { Header, ScreenSlider, ConfirmModal, Icon } from '../../../components';

import { useEvaluationForms, useGeneralInfo } from '../../../hooks';
import { formTypesList } from '../../../utils/generalInfo';

import CategoryModal from './CategoryModal';
import QuestionModal from './QuestionModal';
import QuestionsTable from './QuestionsTable';

const EvaluationForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    control,
    formState: { errors },
    reset
  } = useForm<UseFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: screenDefaultValues
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as any;

  const { createEvaluationForm, listFormCategories, categories } = useEvaluationForms();
  const { cultures, listCultures } = useGeneralInfo();

  useEffect(() => {
    listCultures();
    listFormCategories(1);
  }, [listCultures, listFormCategories]);

  const culturesOptions = useMemo(() => cultures.map(({ name }) => name), [cultures]);

  const { append: appendPontuation, remove: removePontuation } = useFieldArray({
    control,
    name: 'pontuations'
  });

  const { append: appendCategory, remove: removeCategory } = useFieldArray({
    control,
    name: 'categories'
  });

  const values = watch();

  const [categoryModalVisible, setCategoryModalVisibility] = useState<boolean>(false);
  const [questionModalVisible, setQuestionModalVisibility] = useState<boolean>(false);
  const [confirmModalVisible, setConfirmModalVisibility] = useState<boolean>(false);
  const [idEditQuestion, setIdEditQuestion] = useState<number | null>(null);
  const [editCategory, setEditCategory] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<number>(0);

  const disableInputs = !!state?.editForm;

  useEffect(() => {
    if (state?.editForm) {
      const { editForm } = state;
      const mappedForm = {
        title: editForm.name,
        cultive: editForm.culturas[0]?.name,
        document: editForm.requiredSignature ? allowSignature[0] : allowSignature[1],
        formType: formTypesList.find(({ id }) => id === editForm.type)?.name,
        pontuations: editForm.rankings?.map(i => ({
          ...i,
          farmerLevel: farmerLevels[i.farmerLevel - 1]
        })),
        categories: Object.values<CategoriesType>(
          editForm.questions.reduce((acc, cur) => {
            const { questionCategory, questionCategoryId, description, options } = cur;

            acc[questionCategory] = acc[questionCategory] || {
              title: questionCategory,
              id: questionCategoryId,
              questions: []
            };
            acc[questionCategory].questions.push({ description, options });

            return acc;
          }, {})
        )
      };
      setSelectedCategory(0);

      reset(mappedForm);
    }
  }, [state, reset]);

  const onAddCategory = useCallback(
    title => {
      if (editCategory) {
        setValue(`categories.${selectedCategory}.title`, title);
        setCategoryModalVisibility(false);
        setEditCategory(false);
      } else {
        const index = values.categories.length;
        const { id } = categories.find(({ name }) => name === title) || { id: -1 };
        appendCategory({ title, id });
        register(`categories.${index}.questions`);
        setValue(`categories.${index}.questions`, []);
        setCategoryModalVisibility(false);
        setSelectedCategory(index);
      }
    },
    [
      editCategory,
      setValue,
      selectedCategory,
      values.categories.length,
      categories,
      appendCategory,
      register
    ]
  );

  const onEditCategory = useCallback(() => {
    setEditCategory(true);
    setCategoryModalVisibility(true);
  }, []);

  const onDeleteCategory = useCallback(() => {
    setSelectedCategory(selectedCategory > 0 ? selectedCategory - 1 : 0);
    removeCategory(selectedCategory);
    setCategoryModalVisibility(false);
    setEditCategory(false);
  }, [removeCategory, selectedCategory]);

  const onDeleteQuestion = useCallback(
    index => {
      setValue(
        `categories.${selectedCategory}.questions`,
        values.categories[selectedCategory].questions.filter((q, i) => i !== index)
      );
    },
    [selectedCategory, setValue, values.categories]
  );

  const onEditQuestion = useCallback(id => {
    setIdEditQuestion(id);
    setQuestionModalVisibility(true);
  }, []);

  const onSaveQuestion = useCallback(
    (question, id?) => {
      setQuestionModalVisibility(false);
      if (id !== null) {
        setValue(
          `categories.${selectedCategory}.questions`,
          values.categories[selectedCategory].questions.map((q, i) => (i === id ? question : q)),
          {
            shouldValidate: true
          }
        );
        setQuestionModalVisibility(false);
        setIdEditQuestion(null);
      } else {
        setValue(
          `categories.${selectedCategory}.questions`,
          [...values.categories[selectedCategory].questions, question],
          {
            shouldValidate: true
          }
        );
        setQuestionModalVisibility(false);
      }
    },
    [selectedCategory, setValue, values.categories]
  );

  useEffect(() => {
    if (!state?.editForm) {
      if (values.formType === formTypesList[0].name) {
        setValue(`pontuations`, screenDefaultValues.pontuations);
      } else {
        setValue(`pontuations.0.minimumScore`, 1);
        setValue(`pontuations.0.maximumScore`, 2);
        setValue(`pontuations.0.farmerLevel`, farmerLevels[0]);
        setValue(`pontuations.0.numberOfVisits`, '1');
      }
    }
  }, [values.formType]);

  const onSubmit = formValues => {
    let incorrectInterval = false;
    values.pontuations?.forEach((p, index) => {
      if (index !== 0 && p.minimumScore <= values.pontuations[index - 1]?.maximumScore) {
        incorrectInterval = true;
        setError(`pontuations.${index}.minimumScore`, {
          type: 'manual',
          message: t('evaluationForm.incorrectInterval')
        });
      }
    });
    if (!incorrectInterval) {
      const rankings = formValues.pontuations?.map(
        ({ minimumScore, maximumScore, farmerLevel, numberOfVisits }) => ({
          minimumScore: minimumScore,
          maximumScore: parseInt(maximumScore),
          farmerLevel: farmerLevels.findIndex(f => f === farmerLevel) + 1,
          numberOfVisits: parseInt(numberOfVisits)
        })
      );

      const auxQuestions = formValues.categories.reduce(
        (acc, cur) => [...acc, ...cur.questions.map(i => ({ ...i, questionCategoryId: cur.id }))],
        []
      );
      if (formValues.formType === formTypesList[0].name) {
        const newForm = {
          name: formValues.title,
          type: 1,
          requiredSignature: formValues.document === t('evaluationForm.yes'),
          culturasId: [cultures.find(({ name }) => name === formValues.cultive)?.id],
          rankings: rankings,
          questions: auxQuestions
        };
        createEvaluationForm(newForm, () =>
          navigate('/dashboard/admin/evaluations', { replace: true })
        );
      } else {
        const newForm = {
          name: formValues.title,
          type: formTypesList.find(({ name }) => name === formValues.formType)?.id,
          requiredSignature: formValues.document === t('evaluationForm.yes'),
          culturasId: [cultures.find(({ name }) => name === formValues.cultive)?.id],
          questions: auxQuestions
        };
        createEvaluationForm(newForm, () =>
          navigate('/dashboard/admin/evaluations', { replace: true })
        );
      }
    }
  };

  return (
    <>
      <S.Container>
        <Header
          title={t('evaluationForm.title')}
          subtitle={t('evaluationForm.creationFormSubtitle')}
        />
        <S.FormDivisor />
        <S.SectionTitleWrapper>
          <Icon icon='IcDoc' height={20} width={16} />
          <S.SectionTitle>{t('evaluationForm.formDataTitle')}</S.SectionTitle>
        </S.SectionTitleWrapper>
        <S.SectionSubTitle>{t('evaluationForm.formDataSubtitle')}</S.SectionSubTitle>
        <S.StyledRow marginTop={12}>
          <TextField
            placeholder={t('evaluationForm.formTitlePlaceholder')}
            label={t('evaluationForm.formTitle')}
            {...register('title')}
            error={errors.title}
            disabled={disableInputs}
          />
        </S.StyledRow>
        <S.StyledRow>
          <Select
            label={t('evaluationForm.cultive')}
            onSelect={v => setValue('cultive', v, { shouldValidate: true })}
            value={values.cultive}
            optionsList={culturesOptions}
            col={5.5}
            error={errors.cultive}
            disabled={disableInputs}
          />
          <Select
            label={t('evaluationForm.signature')}
            onSelect={v => setValue('document', v, { shouldValidate: true })}
            value={values.document}
            optionsList={allowSignature}
            col={5.5}
            error={errors.document}
            disabled={disableInputs}
          />
          <Select
            label={t('evaluationForm.formType')}
            onSelect={v => setValue('formType', v, { shouldValidate: true })}
            value={values.formType}
            optionsList={formTypesList.map(({ name }) => name)}
            col={5.5}
            error={errors.formType}
            disabled={disableInputs}
          />
        </S.StyledRow>
        <S.FormDivisor />
        <S.SectionTitleWrapper>
          <Icon icon='IcQuestion' />
          <S.SectionTitle>{t('evaluationForm.questionGroupTitle')}</S.SectionTitle>
        </S.SectionTitleWrapper>
        <S.SectionSubTitle>{t('evaluationForm.questionGroupSubtitle')}</S.SectionSubTitle>
        <ScreenSlider
          screens={values.categories.map(({ title }) => title)}
          selectedScreen={selectedCategory}
          onChange={setSelectedCategory}
          onAdd={() => setCategoryModalVisibility(true)}
          placeholder={t('evaluationForm.addCategory')}
          isEditable
          disabled={disableInputs}
          onEdit={onEditCategory}
          onDelete={() => {}}
          bigMargin={false}
          errors={[(errors.categories as any)?.message] || []}
        />
        {(errors.categories as any)?.message && values.categories.length === 0 && (
          <S.StyledRow style={{ justifyContent: 'center' }}>
            <S.StyledError>{(errors.categories as any)?.message}</S.StyledError>
          </S.StyledRow>
        )}
        {values.categories.length > 0 && (
          <QuestionsTable
            questions={values.categories[selectedCategory].questions}
            addQuestion={() => setQuestionModalVisibility(true)}
            onEdit={onEditQuestion}
            disabled={disableInputs}
            onDelete={onDeleteQuestion}
            error={(errors?.categories?.[selectedCategory]?.questions as any)?.message}
          />
        )}
        {!disableInputs && (
          <S.StyledRow>
            <S.Bubble>{t('evaluationForm.formWarning')}</S.Bubble>
            <S.Bubble>{t('evaluationForm.documentWarning')}</S.Bubble>
          </S.StyledRow>
        )}
        {values.formType === formTypesList[0].name && (
          <>
            <S.FormDivisor />
            <S.SectionTitleWrapper>
              <Icon icon='IcStar' height={20} width={16} />
              <S.SectionTitle>{t('evaluationForm.pontuationTitle')}</S.SectionTitle>
            </S.SectionTitleWrapper>
            <S.SectionSubTitle>{t('evaluationForm.pontuationSubtitle')}</S.SectionSubTitle>
          </>
        )}
        {values.formType === formTypesList[0].name &&
          values.pontuations.map((p, i) => (
            <S.PontuationsRow key={crypto.randomUUID()} marginTop={i === 0 ? 24 : 0}>
              <S.PontuationsText>{t('evaluationForm.initialScore')}</S.PontuationsText>
              <S.PontuationTFWrapper>
                <S.PontuationTextField
                  {...register(`pontuations.${i}.minimumScore`)}
                  disabled={disableInputs}
                  error={!!errors.pontuations?.[i]?.minimumScore}
                  maxLength={3}
                />
                <ErrorText>{errors.pontuations?.[i]?.minimumScore?.message}</ErrorText>
              </S.PontuationTFWrapper>
              <S.PontuationsText>{t('evaluationForm.finalScore')}</S.PontuationsText>
              <S.PontuationTFWrapper style={{ marginRight: '24px' }}>
                <S.PontuationTextField
                  {...register(`pontuations.${i}.maximumScore`)}
                  disabled={disableInputs}
                  error={!!errors.pontuations?.[i]?.maximumScore}
                  maxLength={3}
                />
                <ErrorText>{errors.pontuations?.[i]?.maximumScore?.message}</ErrorText>
              </S.PontuationTFWrapper>
              <Select
                label={t('evaluationForm.farmerLevel')}
                onSelect={v =>
                  setValue(`pontuations.${i}.farmerLevel`, v, { shouldValidate: true })
                }
                value={values.pontuations?.[i]?.farmerLevel}
                optionsList={farmerLevels}
                col={4}
                error={errors.pontuations?.[i]?.farmerLevel}
                disabled={disableInputs}
              />
              <TextField
                label={t('evaluationForm.visits')}
                col={4}
                maxLength={3}
                error={errors.pontuations?.[i]?.numberOfVisits}
                {...register(`pontuations.${i}.numberOfVisits`)}
                disabled={disableInputs}
                marginLeft={24}
              />
              {values.pontuations.length > 1 && !disableInputs && i !== 0 && (
                <S.RegularButton
                  onClick={() => removePontuation(i)}
                  style={{ alignSelf: 'center', marginTop: '5px' }}
                >
                  <Icon icon='IcTrash' color='secondary' />
                </S.RegularButton>
              )}
            </S.PontuationsRow>
          ))}
        {values.formType === formTypesList[0].name && !disableInputs && (
          <Button
            buttonType='secondary'
            onClick={() => appendPontuation(screenDefaultValues.pontuations)}
            col={4}
            height='42px'
            marginTop='12px'
          >
            + {t('evaluationForm.addScore')}
          </Button>
        )}
        <S.StyledRow flexEnd>
          <Button
            buttonType='secondary'
            height='48px'
            marginTop='20px'
            onClick={() => {
              state?.editForm
                ? navigate('/dashboard/admin/evaluations', { replace: true })
                : setConfirmModalVisibility(true);
            }}
            col={4}
          >
            {!disableInputs ? t('evaluationForm.cancel') : t('evaluationForm.goBack')}
          </Button>
          {!disableInputs && (
            <Button
              buttonType='primary'
              height='48px'
              marginLeft='20px'
              marginTop='20px'
              onClick={handleSubmit(onSubmit)}
              col={4}
            >
              {t('evaluationForm.save')}
            </Button>
          )}
        </S.StyledRow>
      </S.Container>
      {categoryModalVisible && (
        <CategoryModal
          isVisible={categoryModalVisible}
          toggleVisibility={() => {
            setCategoryModalVisibility(p => !p);
            setEditCategory(false);
          }}
          onConfirm={onAddCategory}
          editCategory={editCategory ? values.categories[selectedCategory].title : null}
          onDelete={values.categories.length > 1 ? () => onDeleteCategory() : null}
          categories={categories.reduce<string[]>(
            (acc, cur) =>
              values.categories.map(({ title }) => title).includes(cur.name)
                ? acc
                : [...acc, cur.name],
            []
          )}
        />
      )}
      {questionModalVisible && (
        <QuestionModal
          isVisible={questionModalVisible}
          toggleVisibility={() => {
            setQuestionModalVisibility(p => !p);
            setIdEditQuestion(null);
          }}
          onConfirm={onSaveQuestion}
          idEditQuestion={idEditQuestion}
          editQuestion={values.categories[selectedCategory].questions.find(
            (q, i) => i === idEditQuestion
          )}
        />
      )}
      <ConfirmModal
        title={t('modals.discardForm.title')}
        content={t('modals.discardForm.content')}
        isVisible={confirmModalVisible}
        setVisibility={setConfirmModalVisibility}
        onConfirm={() => navigate('/dashboard/admin/evaluations', { replace: true })}
      />
    </>
  );
};

export default EvaluationForm;
