import * as yup from 'yup';
import i18n from '../../services/translations';
import { EmployeeModel } from '../../types/models';
import customYup from '../../utils/customValidation';

export type ModalProps = {
  isVisible: boolean;
  setVisibility: Function;
  employee?: EmployeeModel;
  showFarms?: boolean;
  hasPermission?: boolean;
};

export const validationSchema = {
  name: yup.string().required(i18n.t('validations.required')),
  email: yup.string().required(i18n.t('validations.required')),
  identificationType: yup.string().required(i18n.t('validations.required')),
  identificationNumber: customYup.string().required(i18n.t('validations.required')),
  farms: yup
    .array()
    .of(yup.string())
    .min(1, i18n.t('validations.required'))
    .required(i18n.t('validations.required'))
};
