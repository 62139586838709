import styled, { css } from 'styled-components';

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  table-layout: fixed;

  background-color: ${({ theme: { colors } }) => colors.neutral.lightest};
  border-collapse: collapse;
  border-radius: 8px;

  box-shadow: 0px 2px 10px 2px rgba(141, 151, 158, 0.4);

  tbody tr:nth-child(odd) {
    background-color: ${({ theme: { colors } }) =>
      colors.colorWithOpacityGenerator('neutral.default', 0.5)};
  }
`;

export const TableRow = styled.tr`
  width: 100%;
  height: 60px;
`;

export const TableHeaders = styled.th`
  ${({ theme: { colors, typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      border-bottom: 1px solid ${colors.neutral.default};
      :last-of-type {
        width: 100px;
        text-align: center;
      }
    `};
`;

export const TableContent = styled.td`
  ${({ theme: { typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const TableText = styled.p`
  ${({ theme: { typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const ContentRow = styled.td`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

export const RegularButton = styled.button`
  ${({ theme: { typography, spacing } }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${spacing['2xs']};
      background: none;
      border: none;
      cursor: pointer;
    `};
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 200px;
  h1 {
    align-self: center;
    font-size: ${({ theme: { typography } }) => typography.fontSize.lg};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 16px 24px;
`;

export const ModalButton = styled.button`
  ${({ theme: { typography, spacing } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: none;
    background: none;

    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.semiBold};
    line-height: ${spacing.lg};
    cursor: pointer;
  `};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  background-color: ${({ theme: { colors } }) => colors.neutral.light};
  border-radius: 50px;
`;
