import { useEffect, useState, useRef } from 'react';
import { useTranslation, RadioButton, Button } from '@basfagro/core';

import * as S from './FilterButton.styles';

import { Icon } from '../';
import { useOnClickOutside } from '../../hooks';

type FilterButtonProps = {
  filterOptions: { sectionName: string; type: string; options: { name: string; value: any }[] }[];
  selectedDefault: {};
  onClickFilter: Function;
  hasClear?: boolean;
};

const FilterButton = ({
  filterOptions,
  onClickFilter,
  selectedDefault,
  hasClear
}: FilterButtonProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [selectedOptions, setSelected] = useState(selectedDefault);
  const selectContainerRef = useRef(null);

  const clickOutsideHandler = () => {
    setIsActive(false);
  };
  useOnClickOutside(selectContainerRef, clickOutsideHandler);

  const onSelect = (value, type) => setSelected(prev => ({ ...prev, [type]: value }));

  const clearFilter = () => setSelected({});

  useEffect(() => {
    onClickFilter(selectedOptions);
  }, [selectedOptions]);

  return (
    <S.Container>
      <S.ButtonFilter
        onClick={() => setIsActive(prev => !prev)}
        hasFilter={!!Object.keys(selectedOptions).length}
      >
        <Icon icon='IcFilter' color={Object.keys(selectedOptions).length ? 'white' : 'black'} />
      </S.ButtonFilter>
      {isActive && (
        <S.ContentFilter ref={selectContainerRef}>
          <S.AreaCloseButton>
            <S.CloseButton
              onClick={() => {
                setIsActive(false);
              }}
            >
              <Icon icon='IcClose' color='black' />
            </S.CloseButton>
          </S.AreaCloseButton>
          <S.HeaderFilterTitle>
            <S.HeaderText>{t('filterFields.filters')}</S.HeaderText>
          </S.HeaderFilterTitle>
          {filterOptions.map(filter => (
            <S.FormWrapper key={filter.sectionName}>
              <S.FormTitleText>{filter.sectionName}</S.FormTitleText>
              {filter.options.map(({ name, value }) => (
                <RadioButton
                  key={name}
                  label={name}
                  groupName={name}
                  onChangeFunc={() => {
                    onSelect(value, filter.type);
                  }}
                  isChecked={selectedOptions[filter.type] === value}
                />
              ))}
            </S.FormWrapper>
          ))}
          {hasClear && (
            <Button buttonType='tertiary' onClick={clearFilter}>
              {t('filterFields.clearFilters')}
            </Button>
          )}
        </S.ContentFilter>
      )}
    </S.Container>
  );
};

export default FilterButton;
