import { useState, useEffect } from 'react';
import { useTranslation, Pagination, SearchField, EmptyPage } from '@basfagro/core';

import * as S from './Custody.styles';

import { TabOptions } from './Custody.mock';
import Loading from '../../../assets/Loading.gif';

import { useCustodies, useAuth } from '../../../hooks';
import { Header, TabBar, CustodiesTable, ConfirmModal } from '../../../components';

const Custody = () => {
  const { t: translate } = useTranslation();

  const {
    listCustodies,
    approveCustody,
    denyCustody,
    deleteCustody,
    custodies,
    tabIndex,
    pageIndex,
    pagesCount,
    isLoading,
    searchedText,
    editSearchedText,
    editTabIndex,
    editPageIndex
  } = useCustodies();

  const { hasEditPermission } = useAuth();

  const [searchValue, setSearchValue] = useState(searchedText);
  const [status, setStatus] = useState(tabIndex + 3);
  const [page, setPage] = useState(pageIndex);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showApproveModal, setApproveModal] = useState(false);
  const [showDenyModal, setDenyModal] = useState(false);
  const [selectedCustody, setSelectedCustody] = useState({ name: '', id: 0, type: '' });

  useEffect(() => {
    if (searchValue === '') {
      listCustodies(10, page, status);
    } else {
      listCustodies(10, page, status, searchValue, () => {
        setPage(prev => (pagesCount < page ? 1 : prev));
      });
    }
    editSearchedText(searchValue);
    editPageIndex(page);
  }, [listCustodies, selectedCustody, status, page, searchValue]);

  const onPressApprove = (id, name) => {
    setSelectedCustody({ name, id, type: 'approve' });
    setApproveModal(true);
  };

  const onPressDeny = (id, name) => {
    setSelectedCustody({ name: name, id: id, type: 'deny' });
    setDenyModal(true);
  };

  const onPressDelete = (id, name) => {
    setSelectedCustody({ name: name, id: id, type: 'delete' });
    setDeleteModal(true);
  };

  const onChangeTab = id => {
    setStatus(id + 3);
    setPage(1);
    editTabIndex(id);
    setSearchValue('');
  };

  const onPressConfirm = () => {
    const { type, id } = selectedCustody;
    if (type === 'approve') {
      approveCustody(id, () => setApproveModal(false));
      setApproveModal(false);
    }
    if (type === 'deny') {
      denyCustody(id, () => setDenyModal(false));
      setDenyModal(false);
    }
    if (type === 'delete') {
      deleteCustody(id, () => setDeleteModal(false));
      setDeleteModal(false);
    }
  };

  return (
    <S.StyledContainer>
      <Header
        title={translate('custodyFields.headerTitle')}
        subtitle={translate('custodyFields.headerSubTitle')}
      />
      <TabBar options={TabOptions()} onPressOption={onChangeTab} selectedOpt={tabIndex} />
      <SearchField
        placeholder={translate('custodyFields.search')}
        value={searchValue}
        onChangeText={setSearchValue}
        marginTop='16px'
        marginBottom='24px'
      />
      {isLoading ? (
        <S.StyledLoading src={Loading} />
      ) : custodies.length ? (
        <>
          <CustodiesTable
            headers={{
              name: translate('fields.name'),
              phoneNumber: translate('fields.phone'),
              departments: translate('fields.department'),
              cargo: translate('fields.role'),
              email: translate('fields.mail')
            }}
            tableData={custodies}
            type={status === 3 ? 'inProcess' : status === 4 ? 'accepted' : 'denied'}
            onPressApprove={onPressApprove}
            onPressDeny={onPressDeny}
            onPressDelete={onPressDelete}
            canEdit={!!hasEditPermission('Custodia')}
          />
          <Pagination pagesTotal={pagesCount} page={page} setPage={setPage} />
        </>
      ) : (
        <EmptyPage />
      )}
      {showDeleteModal && (
        <ConfirmModal
          isVisible={showDeleteModal}
          setVisibility={() => setDeleteModal(prev => !prev)}
          onConfirm={onPressConfirm}
          title={translate('modals.custody.delete.title')}
          content={translate('modals.custody.delete.content', { name: selectedCustody.name })}
        />
      )}
      {showApproveModal && (
        <ConfirmModal
          isVisible={showApproveModal}
          setVisibility={() => setApproveModal(prev => !prev)}
          onConfirm={onPressConfirm}
          title={translate('modals.custody.accept.title')}
          content={translate('modals.custody.accept.content', { name: selectedCustody.name })}
        />
      )}
      {showDenyModal && (
        <ConfirmModal
          isVisible={showDenyModal}
          setVisibility={() => setDenyModal(prev => !prev)}
          onConfirm={onPressConfirm}
          title={translate('modals.custody.refusal.title')}
          content={translate('modals.custody.refusal.content', { name: selectedCustody.name })}
        />
      )}
    </S.StyledContainer>
  );
};

export default Custody;
