import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import * as Slice from '../slices/farmersSlice';

import api from '../../services/api';
import { EmployeeModel } from '../../types/models';

type FarmersParams = {
  payload: {
    pgSize: number;
    pgIndex: number;
    searchText: string;
    successCallback: Function;
    failureCallback: Function;
    selectedID: number;
    editedFarmer: {
      name: string;
      companyName: string;
      identificationType: string;
      identificationNumber: number;
      phoneNumbers: {
        phoneNumber: string;
        mobileNumber: string;
      };
      address: {
        fullAddress: string;
        cityId: number;
      };
    };
    editedFarm: {
      name: string;
      areaInHectares: number;
      cityId: number;
      location: [{ latitude: number; longitude: number }];
    };
    editedLandplot: {
      name: string;
      varietyId: number;
      areaInHectares: number;
      location: [
        {
          latitude: number;
          longitude: number;
        }
      ];
    };
    editedEmployee: EmployeeModel;
  };
};

function* listFarmers({
  payload: { pgSize, pgIndex, searchText, successCallback = () => {} }
}: FarmersParams) {
  try {
    const search = searchText ? `&NameEmail=${searchText}` : '';
    const { data } = yield call(api.get, `farmer?PageIndex=${pgIndex}&PageSize=${pgSize}${search}`);

    yield put(
      Slice.LIST_FARMERS_SUCCESS({
        farmerList: data.dataList.map(({ farmer }) => farmer),
        pagesCount: data.pagesCount,
        ...data
      })
    );
    successCallback();
  } catch (error) {
    yield put(Slice.LIST_FARMERS_FAILURE({ error }));
  }
}

function* getDetailedFarmer({
  payload: { selectedID, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  try {
    const { data } = yield call(api.get, `farmer/${selectedID}`);

    yield put(Slice.GET_DETAILED_FARMER_SUCCESS(data));
    successCallback(data);
  } catch (error) {
    yield put(Slice.GET_DETAILED_FARMER_FAILURE({ error }));
    failureCallback();
  }
}

function* editFarmer({
  payload: { selectedID, editedFarmer, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `usermanagement/farmers/${selectedID}`, editedFarmer);
    yield put(Slice.EDIT_FARMER_SUCCESS({ selectedID, editedFarmer }));
    successCallback(i18n.t('feedback.userEdited'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(Slice.EDIT_FARMER_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

function* deleteFarmer({ payload: { selectedID, successCallback = () => {} } }: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `usermanagement/farmers/${selectedID}`);
    yield put(Slice.DELETE_FARMER_SUCCESS({ selectedID }));
    successCallback();
    toast.success(i18n.t('feedback.userDeleted'), {
      id: toastId
    });
  } catch (error) {
    yield put(Slice.DELETE_FARMER_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* listFarms({
  payload: { selectedID, searchText, successCallback = () => {} }
}: FarmersParams) {
  try {
    const search = searchText ? `&Name=${searchText}` : '';
    const { data } = yield call(
      api.get,
      `farm?WhereBy=Farmer&ConditionalId=${selectedID}${search}`
    );
    if (data) {
      yield put(Slice.LIST_FARMS_SUCCESS(data));
    }
    successCallback();
  } catch (error) {
    yield put(Slice.LIST_FARMS_FAILURE({ error }));
  }
}

function* getDetailedFarm({ payload: { selectedID, successCallback = () => {} } }: FarmersParams) {
  try {
    const { data } = yield call(api.get, `farm/${selectedID}`);

    yield put(Slice.GET_DETAILED_FARM_SUCCESS(data));
    successCallback(data);
  } catch (error) {
    yield put(Slice.GET_DETAILED_FARM_FAILURE({ error }));
  }
}

function* editFarm({
  payload: { selectedID, editedFarm, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const { data } = yield call(api.put, `farm/${selectedID}`, editedFarm);

    yield put(Slice.EDIT_FARM_SUCCESS({ selectedID, editedFarm }));
    successCallback(i18n.t('feedback.farmEdited'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(Slice.EDIT_FARM_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

function* deleteFarm({
  payload: { selectedID, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  try {
    yield call(api.delete, `farm/${selectedID}`);

    yield put(Slice.DELETE_FARM_SUCCESS({ selectedID }));

    successCallback(i18n.t('feedback.farmDeleted'));
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('farmers.deleteFarmFailure'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(Slice.DELETE_FARM_FAILURE({ error }));
  }
}

function* listLandPlots({
  payload: { selectedID, searchText, successCallback = () => {} }
}: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const search = searchText ? `&Name=${searchText}` : '';
    const { data } = yield call(api.get, `farm/${selectedID}/landplots?${search}`);
    if (data) {
      yield put(
        Slice.LIST_LANDPLOTS_SUCCESS(
          data?.map(({ landPlot, cycleStatus }) => {
            return { ...landPlot, cycleStatus };
          })
        )
      );
    }
    successCallback();
    toast.dismiss(toastId);
  } catch (error) {
    yield put(Slice.LIST_LANDPLOTS_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* editLandplot({
  payload: { selectedID, editedLandplot, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `landplot/${selectedID}`, editedLandplot);

    yield put(Slice.EDIT_LANDPLOT_SUCCESS({ selectedID, editedLandplot }));

    successCallback(i18n.t('feedback.landPlotEdited'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(Slice.EDIT_LANDPLOT_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

function* deleteLandplot({
  payload: { selectedID, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  try {
    yield call(api.delete, `landplot/${selectedID}`);

    yield put(Slice.DELETE_LANDPLOT_SUCCESS({ selectedID }));

    successCallback(i18n.t('feedback.landPlotDeleted'));
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('farmers.deleteFarmFailure'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(Slice.DELETE_LANDPLOT_FAILURE({ error }));
  }
}

function* listFarmEmployees({
  payload: { selectedID, searchText, successCallback = () => {} }
}: FarmersParams) {
  try {
    const search = searchText ? `&NameEmail=${searchText}` : '';
    const { data } = yield call(api.get, `farm/${selectedID}/employees?${search}`);

    if (data) {
      yield put(Slice.LIST_FARM_EMPLOYEES_SUCCESS(data));
    }
    successCallback();
  } catch (error) {
    yield put(Slice.LIST_FARM_EMPLOYEES_FAILURE({ error }));
  }
}

function* listEmployees({
  payload: { selectedID, searchText, successCallback = () => {} }
}: FarmersParams) {
  try {
    const search = searchText ? `&NameEmail=${searchText}` : '';
    const { data } = yield call(api.get, `employee?farmerId=${selectedID}${search}`);

    if (data) {
      yield put(Slice.LIST_EMPLOYEES_SUCCESS(data));
    }
    successCallback();
  } catch (error) {
    yield put(Slice.LIST_EMPLOYEES_FAILURE({ error }));
  }
}

function* editEmployee({
  payload: { selectedID, editedEmployee, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `employee/${selectedID}`, {
      name: editedEmployee.name,
      identificationType: editedEmployee.identificationType,
      identificationNumber: editedEmployee.identificationNumber,
      farms: editedEmployee.farms.map(({ id }) => id)
    });

    yield put(Slice.EDIT_EMPLOYEE_SUCCESS({ selectedID, editedEmployee }));

    successCallback(i18n.t('feedback.userEdited'));
    toast.dismiss(toastId);
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      failureCallback(i18n.t('feedback.invalidValues'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
    yield put(Slice.EDIT_EMPLOYEE_FAILURE({ error }));
    toast.dismiss(toastId);
  }
}

function* deleteEmployee({
  payload: { selectedID, successCallback = () => {}, failureCallback = () => {} }
}: FarmersParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `employee/${selectedID}`);

    yield put(Slice.DELETE_EMPLOYEE_SUCCESS({ selectedID }));

    successCallback(i18n.t('feedback.employeeDeleted'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(Slice.DELETE_EMPLOYEE_FAILURE({ error }));
    failureCallback(i18n.t('farmers.deleteEmployee'));
    toast.dismiss(toastId);
  }
}

export default function* watcher() {
  yield takeEvery(Slice.LIST_FARMERS, listFarmers);
  yield takeEvery(Slice.GET_DETAILED_FARMER, getDetailedFarmer);
  yield takeEvery(Slice.LIST_FARMS, listFarms);
  yield takeEvery(Slice.GET_DETAILED_FARM, getDetailedFarm);
  yield takeEvery(Slice.DELETE_FARM, deleteFarm);
  yield takeEvery(Slice.LIST_LANDPLOTS, listLandPlots);
  yield takeEvery(Slice.DELETE_LANDPLOT, deleteLandplot);
  yield takeEvery(Slice.LIST_FARM_EMPLOYEES, listFarmEmployees);
  yield takeEvery(Slice.LIST_EMPLOYEES, listEmployees);
  yield takeEvery(Slice.EDIT_FARMER, editFarmer);
  yield takeEvery(Slice.EDIT_FARM, editFarm);
  yield takeEvery(Slice.EDIT_LANDPLOT, editLandplot);
  yield takeEvery(Slice.DELETE_EMPLOYEE, deleteEmployee);
  yield takeEvery(Slice.EDIT_EMPLOYEE, editEmployee);
  yield takeEvery(Slice.DELETE_FARMER, deleteFarmer);
}
