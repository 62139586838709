import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import { AdminUser } from '../../types/models';

import {
  LIST_ADMIN_USERS,
  LIST_ADMIN_USERS_SUCCESS,
  LIST_ADMIN_USERS_FAILURE,
  CREATE_ADMIN_USER,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAILURE,
  EDIT_ADMIN_USER,
  EDIT_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER_FAILURE,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_FAILURE
} from '../slices/adminUsersSlice';

import api from '../../services/api';

type AdminUserParams = {
  payload: {
    id: number;
    newUser: AdminUser;
    pgIndex: number;
    pgSize: number;
    searchText: string;
    whereBy: number;
    successCallback: Function;
    failureCallback: Function;
    editedUser: AdminUser;
  };
};

function* listAdminUsers({
  payload: { pgIndex, pgSize, searchText, successCallback = () => {} }
}: AdminUserParams) {
  try {
    const search = searchText ? `&NameEmail=${searchText}` : '';
    const { data } = yield call(
      api.get,
      `/usermanagement/administrators?PageIndex=${pgIndex}&PageSize=${pgSize}${search}&Active=true`
    );

    yield put(LIST_ADMIN_USERS_SUCCESS(data));
    successCallback();
  } catch (error) {
    yield put(LIST_ADMIN_USERS_FAILURE({ error }));
  }
}

function* createAdminUser({
  payload: { successCallback = () => {}, failureCallback = () => {}, newUser }
}: AdminUserParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const { data } = yield call(api.post, `user/administrator`, newUser);
    yield put(CREATE_ADMIN_USER_SUCCESS(data));
    successCallback(i18n.t('feedback.userCreated'));
    toast.dismiss(toastId);
  } catch (error: any) {
    const { status } = error.response;
    yield put(CREATE_ADMIN_USER_FAILURE({ error }));
    toast.dismiss(toastId);
    if (status === 400) {
      failureCallback(i18n.t('adminUsers.createError'));
    } else {
      failureCallback(i18n.t('feedback.failure'));
    }
  }
}

function* deleteAdminUser({
  payload: { successCallback = () => {}, failureCallback = () => {}, id }
}: AdminUserParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `usermanagement/administrators/${id}`);
    yield put(DELETE_ADMIN_USER_SUCCESS({ id }));
    successCallback(i18n.t('feedback.userDeleted'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(DELETE_ADMIN_USER_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

function* editAdminUser({
  payload: { id, editedUser, successCallback = () => {}, failureCallback = () => {} }
}: AdminUserParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const { data } = yield call(api.put, `usermanagement/administrators/${id}`, editedUser);
    yield put(EDIT_ADMIN_USER_SUCCESS(data));
    successCallback(i18n.t('feedback.userEdited'));
    toast.dismiss(toastId);
  } catch (error) {
    yield put(EDIT_ADMIN_USER_FAILURE({ error }));
    failureCallback(i18n.t('feedback.failure'));
    toast.dismiss(toastId);
  }
}

export default function* watcher() {
  yield takeEvery(LIST_ADMIN_USERS, listAdminUsers);
  yield takeEvery(CREATE_ADMIN_USER, createAdminUser);
  yield takeEvery(DELETE_ADMIN_USER, deleteAdminUser);
  yield takeEvery(EDIT_ADMIN_USER, editAdminUser);
}
