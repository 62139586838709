import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import { DistributorModel } from '../../types/models';

import * as Slice from '../slices/distributorsSlice';

import api from '../../services/api';

type DistributorParams = {
  payload: {
    id: number;
    newDistributor: {
      id: number;
      name: string;
      email: string;
      companyName: string;
      nit: string;
      profilePicture: string;
      address: {
        fullAddress: string;
        city: number;
      };
      contactPhones: { description: string; type: number }[];
      contactEmails: string[];
    };
    evaluate: boolean;
    searchText: string;
    pageIndex: number;
    pageSize: number;
    status: number;
    successCallBack: Function;
  };
};

function* listDistributors({
  payload: { searchText, pageIndex, pageSize, status, successCallBack = () => {} }
}: DistributorParams) {
  try {
    const search = searchText ? `&NameEmail=${searchText}` : '';
    const response = yield call(
      api.get,
      `usermanagement/distributors/?pageSize=${pageSize}&pageIndex=${pageIndex}&status=${status}${search}`
    );
    const { data } = response;

    yield put(Slice.LIST_DISTRIBUTORS_SUCCESS(data));
    successCallBack();
  } catch (error) {
    yield put(Slice.LIST_DISTRIBUTORS_FAILURE({ error }));
  }
}

function* evaluateDistributor({
  payload: { id, successCallBack = () => {}, evaluate }
}: DistributorParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `usermanagement/distributors/${id}/evaluate`, {
      approved: evaluate
    });

    yield put(Slice.EVALUATE_DISTRIBUTOR_SUCCESS({ id }));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(Slice.EVALUATE_DISTRIBUTOR_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* deleteDistributor({ payload: { id, successCallBack = () => {} } }: DistributorParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `usermanagement/distributors/${id}`);

    yield put(Slice.DELETE_DISTRIBUTOR_SUCCESS({ id }));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(Slice.DELETE_DISTRIBUTOR_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* getDetailedDistributor({
  payload: { id, successCallBack = () => {} }
}: DistributorParams) {
  try {
    const { data } = yield call(api.get, `distributors/${id}`);

    yield put(Slice.GET_DETAILED_DISTRIBUTOR_SUCCESS(data));
    successCallBack();
  } catch (error) {
    yield put(Slice.GET_DETAILED_DISTRIBUTOR_FAILURE({ error }));
  }
}

function* editDistributor({
  payload: { newDistributor, successCallBack = () => {} }
}: DistributorParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const formData = new FormData();
    formData.append('name', newDistributor.name);
    formData.append('companyName', newDistributor.companyName);
    formData.append('nit', newDistributor.nit);
    formData.append('address.fullAddress', newDistributor.address.fullAddress);
    formData.append('address.cityId', `${newDistributor.address.city}`);
    formData.append('profilePicture', newDistributor.profilePicture);

    newDistributor.contactPhones.forEach((phone, i) => {
      formData.append(`ContactPhones[${i}].Description`, phone.description);
      formData.append(`ContactPhones[${i}].Type`, `${phone.type}`);
    });

    newDistributor.contactEmails?.forEach((email, i) => {
      formData.append(`ContactEmails[${i}].Description`, email);
    });

    const { data } = yield call(
      api.put,
      `usermanagement/distributors/${newDistributor.id}`,
      formData
    );
    yield put(Slice.EDIT_DISTRIBUTOR_SUCCESS(data));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      toast.error(i18n.t('feedback.invalidValues'), {
        id: toastId
      });
    } else {
      toast.error(i18n.t('feedback.failure'), {
        id: toastId
      });
    }
    yield put(Slice.EDIT_DISTRIBUTOR_FAILURE({ error }));
  }
}

export default function* watcher() {
  yield takeEvery(Slice.LIST_DISTRIBUTORS, listDistributors);
  yield takeEvery(Slice.EVALUATE_DISTRIBUTOR, evaluateDistributor);
  yield takeEvery(Slice.DELETE_DISTRIBUTOR, deleteDistributor);
  yield takeEvery(Slice.GET_DETAILED_DISTRIBUTOR, getDetailedDistributor);
  yield takeEvery(Slice.EDIT_DISTRIBUTOR, editDistributor);
}
