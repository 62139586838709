import { useTranslation } from '../../../services/translations';

export const TabOptions = () => {
  const { t: translate } = useTranslation();
  return [
    { id: 0, name: translate('fields.inProcess') },
    { id: 1, name: translate('fields.approved') },
    { id: 2, name: translate('fields.denied') }
  ];
};
