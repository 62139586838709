import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useCustodies, useDistributors } from '../../hooks';

import * as S from './MenuOption.styles';

import { Icon } from '../';

export type MenuOptionProps = {
  id: number;
  icon?: string;
  title?: string;
  isSelected: boolean;
  subOptions?: { id: number; title: string; screen: string }[];
  onPress: Function;
};

const MenuOption = ({ id, icon, title, onPress, isSelected, subOptions }: MenuOptionProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    editSearchedText: editSearchedTextCustody,
    editPageIndex: editPageIndexCustody,
    editTabIndex: editTabIndexCustody
  } = useCustodies();

  const {
    editSearchedText: editSearchedTextDistributor,
    editPageIndex: editPageIndexDistributor,
    editTabIndex: editTabIndexDistributor
  } = useDistributors();

  const [selectedSubOption, setSelectedSubOption] = useState(0);

  const onPressOption = (screen, id) => {
    if (screen === 'approvations/custody') {
      editSearchedTextCustody('');
      editPageIndexCustody(1);
      editTabIndexCustody(0);
    }
    if (screen === 'approvations/distributors') {
      editSearchedTextDistributor('');
      editPageIndexDistributor(1);
      editTabIndexDistributor(0);
    }
    setSelectedSubOption(id);
    navigate(screen);
  };

  useEffect(() => {
    const selectedPath = pathname.split('/')?.[3];
    if (selectedPath) {
      const subId = subOptions?.find(({ screen }) => screen.split('/')[1] === selectedPath)?.id;
      setSelectedSubOption(subId || parseFloat(`${id}.1`));
    }
  }, [pathname]);

  return (
    <S.OptionWrapper layout>
      <S.Container
        isSelected={isSelected}
        onClick={() => !isSelected && onPress()}
        isSecondary={false}
        haveSubOptions={!!subOptions}
      >
        <Icon icon={icon} width={20} height={20} color={isSelected ? 'primary' : 'white'} />
        <S.Text isSelected={isSelected}>{title}</S.Text>
      </S.Container>
      {isSelected &&
        subOptions?.length &&
        subOptions.map(({ id, title, screen }) => (
          <S.Container
            key={id}
            isSelected={selectedSubOption === id}
            onClick={() => onPressOption(screen, id)}
            isSecondary
          >
            <S.Text isSelected>{title}</S.Text>
          </S.Container>
        ))}
    </S.OptionWrapper>
  );
};

export default MenuOption;
