import styled from 'styled-components';

type UserProps = {
  hasPermission?: boolean;
};

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  padding: 40px 16px 0px 16px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
  overflow: auto;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 300px;
  height: 58px;
  align-self: flex-start;
  align-items: center;
`;

export const StyledSearchContainer = styled.div<UserProps>`
  display: flex;
  width: ${({ hasPermission }) => (hasPermission ? '462px' : '100%')};
  height: 58px;
  align-items: flex-end;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledLoading = styled.img`
  width: 8%;
  height: auto;
  align-self: center;
`;
