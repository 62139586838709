import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 40px 16px 16px 16px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
`;

export const StyledButtonContainer = styled.div`
  width: 300px;
  margin-bottom: 15px;
`;

export const StyledLoading = styled.img`
  width: 8%;
  height: auto;
  align-self: center;
`;
