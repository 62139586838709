import styled, { css } from 'styled-components';
import { Button as RootButton } from '@basfagro/core';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 634px;
  position: relative;
`;

export const Text = styled.h1`
  ${({ theme: { colors, typography, spacing } }) => css`
    margin-bottom: ${spacing.md};
    color: ${colors.neutral.darkest};
    font-size: ${typography.fontWeight.lg};
    font-weight: ${typography.fontWeight.bold};
    line-height: ${spacing.lg};
    letter-spacing: 0.3px;
  `};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: ${({ theme: { spacing } }) => spacing.sm};
`;

export const Button = styled(RootButton)`
  width: 263px;
  height: 40px;
`;
