import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation, TextField, Button } from '@basfagro/core';

import * as S from '../ResetPassword.styles';

import { ValidateTokenValidation } from '../ResetPassword.utils';

import { sendPasswordTokenCase, validateCodeCase } from '../../../useCases/resetPasswordUseCase';

import greenLogo from '../../../assets/images/logo/greenSimpleLogo.svg';

const SECONDS_AMOUNT = 60;

const GetToken = () => {
  const navigate = useNavigate();
  const email = useLocation().state as any;
  const { t } = useTranslation();
  const [secondCounter, setSecondCounter] = useState(SECONDS_AMOUNT);
  const [isCounterActive, setCounterActivity] = useState(true);

  useEffect(() => {
    if (!email) {
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (!isCounterActive) {
      return;
    }
    setTimeout(() => {
      if (secondCounter - 1 === 0) {
        setCounterActivity(false);
      } else {
        setSecondCounter(prev => prev - 1);
      }
    }, 1000);
  }, [isCounterActive, secondCounter]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(ValidateTokenValidation),
    defaultValues: { code: '' }
  });

  const onSubmit = formData => {
    validateCodeCase(formData.code, () => {
      navigate('reset-password', { state: email });
    });
  };

  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <img alt='Basf logo' src={greenLogo} />
      </S.LogoWrapper>
      <S.ContentWrapper onSubmit={handleSubmit(onSubmit)}>
        <S.Title>{t('reset.forgotTitle')}</S.Title>
        <S.Subtitle>{t('reset.enterCodeSubtitle')}</S.Subtitle>
        <TextField
          label={t('reset.code')}
          placeholder={t('reset.codeField')}
          {...register('code')}
          error={errors.code}
        />
        {isCounterActive ? (
          <S.TimeText>
            {t('reset.resendCodeIn')}
            {secondCounter}
            {t('reset.seconds')}
          </S.TimeText>
        ) : (
          <Button
            buttonType='tertiary'
            type='button'
            onClick={() => {
              sendPasswordTokenCase(email, () => {
                setSecondCounter(SECONDS_AMOUNT);
                setCounterActivity(true);
              });
            }}
          >
            {t('reset.resendCode')}
          </Button>
        )}
        <S.ButtonsRow>
          <Button
            type='button'
            buttonType='secondary'
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            {t('buttons.cancelButton')}
          </Button>
          <Button buttonType='primary' type='submit'>
            {t('buttons.confirmButton')}
          </Button>
        </S.ButtonsRow>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default GetToken;
