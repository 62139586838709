import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  height: fit-content;
  min-height: 180px;

  justify-content: center;
  border-radius: 8px;
  padding: 12px 24px;
  background-color: ${({ theme: { colors } }) => colors.white};
  position: relative;

  animation-name: openAnimation;
  animation-duration: 0.5s;
  opacity: 1;

  @keyframes openAnimation {
    0% {
      opacity: 0;
      height: 150px;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      height: fit-content;
    }
  }
`;

export const Title = styled.h1`
  ${({ theme: { spacing, colors, typography } }) => css`
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.bold};
    line-height: 26px;
    color: ${colors.black};
    margin-bottom: ${spacing.sm};
  `};
`;

export const Content = styled.p`
  ${({ theme: { spacing, colors, typography } }) => css`
    font-size: ${typography.fontSize.xm};
    font-weight: ${typography.fontWeight.regular};
    line-height: ${spacing.md};
    color: ${colors.black};
    margin-bottom: ${spacing.sm};
  `};
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  justify-content: flex-end;
  gap: ${({ theme: { spacing } }) => spacing.sm};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 126px;
`;
