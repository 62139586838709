import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type ButtonProps = {
  isSelected: boolean;
};

type LabelProps = {
  disabled?: boolean;
};

export const Container = styled.div<CSSProperties>`
  ${({ width }) => css`
    display: flex;
    flex-direction: column;
    width: ${width}%;

    padding-right: 20px;
  `};
`;

export const Label = styled.label<LabelProps>`
  ${({ theme: { colors, typography }, disabled }) => css`
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.xs};
    line-height: 22px;
    color: ${disabled ? colors.neutral.dark : colors.black};
  `};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  overflow-x: auto;
`;

export const ButtonContainer = styled.div<ButtonProps>`
  ${({ theme: { colors, typography }, isSelected }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    min-width: 128px;
    height: 100px;
    padding: 12px;
    margin-right: 16px;
    border-radius: 8px;

    background-color: ${colors.white};
    filter: grayscale(100%) opacity(50%);
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ${isSelected &&
    css`
      border: 1px solid ${colors.secondary.default};
      filter: grayscale(0%) opacity(100%);
    `}

    label {
      font-weight: ${typography.fontWeight.light};
      font-size: ${typography.fontSize.xs};
    }

    :last-child {
      margin: 0;
    }
  `};
`;
