import { createInstance, useTranslation } from '@basfagro/core';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import pt from './pt.json';
import es from './es.json';

const clearfieldInstance = createInstance();

clearfieldInstance.use(LanguageDetector).init({
  fallbackLng: 'es',
  resources: {
    en,
    pt,
    es
  }
});

export { useTranslation };

export default clearfieldInstance;
