import styled, { css, CSSProperties } from 'styled-components';

type OptionProp = {
  isSelected: boolean;
  error?: boolean;
};

export const StyledContainer = styled.div<CSSProperties>`
  ${({ theme: { colors, spacing }, marginTop, marginBottom }) => css`
    width: calc(100% + 32px);
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${spacing.sm};
    margin-top: ${marginTop}px;
    margin-bottom: ${marginBottom}px;
    margin-left: -16px;
    background-color: ${colors.colorWithOpacityGenerator('neutral.default', 0.5)};
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar-track {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      display: none;
    }

    ::-webkit-scrollbar-thumb:hover {
      display: none;
    }
  `}
`;

export const StyledOption = styled.div<OptionProp>`
  ${({ theme: { colors, spacing }, isSelected, error }) => css`
    width: 180px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${spacing.sm};
    margin-right: ${spacing['2xs']};
    background-color: ${colors.colorWithOpacityGenerator('neutral.light', 0.6)};
    position: relative;
    cursor: pointer;

    :last-of-type {
      margin-right: ${spacing.md};
    }

    svg:not(#edit) {
      display: none;
      position: absolute;
      top: 2px;
      right: 2px;
    }

    #edit {
      display: none;
      margin-left: ${spacing.xs};
    }

    ${isSelected &&
    css`
      color: ${colors.secondary.default};
      background-color: ${colors.white};
      box-shadow: 10px 4px 15px rgba(0, 0, 0, 0.07);

      :hover {
        svg:not(#edit) {
          display: block;
        }
        #edit {
          display: block;
        }
      }
    `}

    ${error &&
    css`
      border: 1px solid ${colors.tertiary.default};
    `}
  `}
`;

export const Placeholder = styled.div`
  ${({ theme: { colors, spacing, typography } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing.sm};
    background-color: ${colors.colorWithOpacityGenerator('neutral.light', 0.1)};
    cursor: pointer;

    color: ${colors.neutral.darkest};
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.semiBold};
  `}
`;

export const SVGWrapper = styled.div`
  display: flex;
  min-width: 18px;
  min-height: 18px;
`;
