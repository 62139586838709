import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TextField, Select, SearchSelect, Button, useTranslation } from '@basfagro/core';

import * as S from './EmployeeModal.styles';
import { ModalProps, validationSchema } from './Employee.utils';

import { useFarmers } from '../../hooks/useFarmers';
import { Modal, FeedbackModal } from '..';
import { identificationTypes } from '../../utils/generalInfo';
import { getValidationByDocumentType } from '../../utils/helpers';

const EmployeeModal = ({
  isVisible,
  setVisibility,
  employee,
  showFarms,
  hasPermission
}: ModalProps) => {
  const { t } = useTranslation();
  const { editEmployee, selectedFarmer, listFarms, farms } = useFarmers();

  const [schema, setSchema] = useState(yup.object(validationSchema).required());
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  const defaultValues = {
    name: employee?.name,
    email: employee?.email,
    identificationType: identificationTypes.find(({ id }) => id === employee?.identificationType)
      ?.name,
    identificationNumber: employee?.identificationNumber,
    farms: employee?.farms.map(({ name }) => name)
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const values = watch();

  useEffect(() => {
    setSchema(
      yup
        .object({
          ...validationSchema,
          identificationNumber: getValidationByDocumentType(values.identificationType)
        })
        .required()
    );
  }, [values.identificationType]);

  useEffect(() => {
    listFarms(selectedFarmer.id);
  }, [selectedFarmer]);

  useEffect(() => {
    register('farms');
    setValue(
      'farms',
      employee?.farms.map(({ name }) => name)
    );
  }, [register]);

  const onSelect = element => {
    if (hasPermission) {
      if (values.farms?.includes(element)) {
        setValue(
          'farms',
          values.farms?.filter(value => value !== element)
        );
      } else {
        const farms = values.farms ? values.farms : [];
        setValue('farms', [...farms, element]);
      }
    }
  };

  const onSave = formValues => {
    editEmployee(
      employee?.id,
      {
        id: employee?.id,
        name: formValues.name,
        email: formValues.email,
        registerCompleted: employee?.registerCompleted,
        identificationType: identificationTypes.find(
          ({ name }) => name === formValues.identificationType
        )?.id,
        identificationNumber: formValues.identificationNumber,
        farms: farms.filter(({ name }) => formValues.farms.includes(name))
      },
      feedbackText => {
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        });
      },
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <Modal visibility={isVisible} toggleVisibility={setVisibility} hasExit>
      <S.StyledWrapper showFarms={showFarms}>
        <S.StyledRow>
          <TextField label={t('fields.name')} value={values.name} readOnly />
          <TextField
            label={t('fields.mail')}
            value={values.email}
            {...register('email')}
            error={errors.email}
            readOnly
          />
        </S.StyledRow>
        <S.StyledRow>
          <Select
            label={t('fields.docType')}
            optionsList={identificationTypes.map(({ name }) => name)}
            col={8}
            value={values.identificationType || ''}
            onSelect={v => setValue('identificationType', v)}
            error={errors.identificationType}
            disabled={!hasPermission}
          />
          <TextField
            label={t('fields.docNumber')}
            value={values.identificationNumber}
            {...register('identificationNumber')}
            error={errors.identificationNumber}
            col={8}
            readOnly={!hasPermission}
          />
        </S.StyledRow>
        {showFarms && (
          <S.StyledRow>
            <SearchSelect
              optionsList={farms
                .map(({ name }) => name)
                .filter(farm => !values.farms?.includes(farm))}
              selectedOptions={values.farms || []}
              onSelect={onSelect}
              label={t('farmers.farms')}
              placeholder={t('farmers.selectFarms')}
              error={(errors.farms as any)?.message}
              disabled={!hasPermission}
            />
          </S.StyledRow>
        )}
        {hasPermission && (
          <S.ButtonsRow>
            <Button
              buttonType='secondary'
              height='48px'
              onClick={() => setVisibility(false)}
              marginRight='16px'
            >
              {t('buttons.discardChanges')}
            </Button>
            <Button
              buttonType='primary'
              height='48px'
              onClick={handleSubmit(onSave)}
              marginRight='16px'
            >
              {t('buttons.saveChanges')}
            </Button>
          </S.ButtonsRow>
        )}
        {!hasPermission && (
          <S.ButtonsRow>
            <Button
              buttonType='secondary'
              height='48px'
              col={6}
              onClick={() => setVisibility(false)}
            >
              {t('evaluationForm.goBack')}
            </Button>
          </S.ButtonsRow>
        )}
        {FBModal && (
          <FeedbackModal
            isVisible={FBModal.state}
            setVisibility={() => {
              setFBModal({ ...FBModal, state: !FBModal.state });
              setVisibility(prev => !prev);
            }}
            text={FBModal.text}
            icon={FBModal.icon}
          />
        )}
      </S.StyledWrapper>
    </Modal>
  );
};

export default EmployeeModal;
