import { useEffect, useState } from 'react';

import { useTranslation, EmptyPage, SearchField } from '@basfagro/core';

import * as S from './FarmerProfile.styles';
import { StyledLoading } from '../Farmers.styles';

import { useFarmers, useAuth } from '../../../hooks';
import Loading from '../../../assets/Loading.gif';

import { ActionTable, EmployeeModal, ConfirmModal, FeedbackModal } from '../../../components';
import { EmployeeModel } from '../../../types/models';

const Employees = () => {
  const { t } = useTranslation();
  const { listEmployees, employees, isLoading, selectedFarmer, deleteEmployee } = useFarmers();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Farmer');
  const [searchValue, setSearchValue] = useState('');
  const [EModalVisibility, setEVisibility] = useState(false);
  const [choosenEmployee, setChoosenEmployee] = useState({} as EmployeeModel | undefined);
  const [DModalVisibility, setDModalVisibility] = useState(false);
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  useEffect(() => {
    if (searchValue === '') {
      listEmployees(selectedFarmer.id);
    } else {
      listEmployees(selectedFarmer.id, searchValue);
    }
  }, [listEmployees, searchValue]);

  const onPressEdit = employee => {
    setChoosenEmployee(employee);
    setEVisibility(true);
  };

  const onPressDelete = employee => {
    setDModalVisibility(true);
    setChoosenEmployee(employee);
  };

  const onConfirmDelete = () => {
    setDModalVisibility(false);
    deleteEmployee(
      choosenEmployee?.id,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <S.StyledWrapper>
      <SearchField
        placeholder={t('farmers.searchEmployee')}
        onChangeText={setSearchValue}
        marginTop='16px'
        marginBottom='16px'
      />
      {isLoading ? (
        <StyledLoading src={Loading} />
      ) : employees.length ? (
        <ActionTable
          headers={{
            name: t('fields.name'),
            email: t('fields.mail')
          }}
          tableData={employees}
          onPressEdit={onPressEdit}
          onPressDelete={onPressDelete}
          hasPermission={!!hasPermission}
        />
      ) : (
        <EmptyPage />
      )}
      {EModalVisibility && (
        <EmployeeModal
          isVisible={EModalVisibility}
          setVisibility={setEVisibility}
          employee={choosenEmployee}
          showFarms
          hasPermission={!!hasPermission}
        />
      )}
      {DModalVisibility && (
        <ConfirmModal
          isVisible={DModalVisibility}
          setVisibility={() => setDModalVisibility(prev => !prev)}
          onConfirm={onConfirmDelete}
          title={t('modals.farms.employeeTitle')}
          content={t('modals.farms.employeeContent', { name: choosenEmployee?.name })}
        />
      )}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => setFBModal({ ...FBModal, state: !FBModal.state })}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </S.StyledWrapper>
  );
};

export default Employees;
