import api from '../services/api';

export const totalVarietySales = async (
  id,
  successCallback = (info: any) => {},
  failureCallback = (e: any) => {}
) => {
  try {
    const { data } = await api.get(`/dashboard/volume-variety-sold/${id}`);
    successCallback(data);
  } catch (e) {
    failureCallback(e);
  }
};
