import { useState } from 'react';
import { useTranslation, Select, Button } from '@basfagro/core';

import { Modal } from '../../../components';

import { ModalButtonRow } from './EvaluationForm.styles';

type CategoryModalType = {
  isVisible: boolean;
  toggleVisibility: Function;
  onConfirm: Function;
  onDelete: Function | null;
  editCategory: string | null;
  categories: string[];
};

const CategoryModal = ({
  isVisible,
  toggleVisibility,
  onConfirm,
  editCategory,
  onDelete,
  categories
}: CategoryModalType) => {
  const [title, setTitle] = useState<string>(editCategory || categories[0]);
  const { t } = useTranslation();

  return (
    <Modal visibility={isVisible} toggleVisibility={toggleVisibility} hasExit>
      <Select
        label={t('evaluationForm.categoryName')}
        onSelect={v => setTitle(v)}
        optionsList={categories}
        value={title}
        height={100}
      />
      <ModalButtonRow>
        {editCategory && onDelete && (
          <Button
            buttonType='secondary'
            height='42px'
            onClick={() => onDelete()}
            marginTop='80px'
            marginRight='15px'
            col={7}
          >
            {t('evaluationForm.deleteCategory')}
          </Button>
        )}
        <Button
          buttonType='primary'
          height='42px'
          onClick={() => onConfirm(title)}
          marginTop='80px'
          col={7}
        >
          {t('evaluationForm.saveCategory')}
        </Button>
      </ModalButtonRow>
    </Modal>
  );
};

export default CategoryModal;
