import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE } from '../slices/authSlice';

import api from '../../services/api';

type LoginParams = {
  payload: {
    successCallback: Function;
    failureCallback: Function;
  };
};

function* login({
  payload: { successCallback = () => {}, failureCallback = () => {} }
}: LoginParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const { data } = yield call(api.get, 'user');
    const permissions = data.permissions;
    localStorage.setItem('@basfagro-admin/userInfo', JSON.stringify({ permissions }));

    yield put(LOGIN_SUCCESS({ permissions }));

    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
    successCallback();
  } catch (error: any) {
    yield put(LOGIN_FAILURE({ error }));
    failureCallback();
  }
}

export default function* watcher() {
  yield takeEvery(LOGIN, login);
}
