import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import {
  LIST_CAMPAIGNS,
  CREATE_CAMPAIGN,
  GET_DETAILED_CAMPAIGN,
  UPDATE_CAMPAIGN,
  RESET_CAMPAIGNS,
  CHANGE_STATUS_CAMPAIGN
} from '../store/slices/campaignsSlice';

export const useCampaigns = () => {
  const dispatch = useDispatch();

  const { totalPages, isLoading, campaigns, error } = useSelector(
    ({ campaigns }: RootState) => campaigns
  );

  const listCampaigns = useCallback(
    (page, successCallback?) => dispatch(LIST_CAMPAIGNS({ page, successCallback })),
    [dispatch]
  );

  const createCampaigns = useCallback(
    (newCampaign, successCallback?) => dispatch(CREATE_CAMPAIGN({ newCampaign, successCallback })),
    [dispatch]
  );

  const getDetailedCampaign = useCallback(
    (id, successCallback?) => dispatch(GET_DETAILED_CAMPAIGN({ id, successCallback })),
    [dispatch]
  );

  const updateCampaign = useCallback(
    (id, updateCampaign, successCallback?) =>
      dispatch(UPDATE_CAMPAIGN({ id, updateCampaign, successCallback })),
    [dispatch]
  );

  const changeStatusCampaign = useCallback(
    (id, successCallback?) => dispatch(CHANGE_STATUS_CAMPAIGN({ id, successCallback })),
    [dispatch]
  );

  const resetCampaigns = useCallback(() => dispatch(RESET_CAMPAIGNS()), [dispatch]);

  return {
    totalPages,
    isLoading,
    campaigns,
    error,
    listCampaigns,
    createCampaigns,
    getDetailedCampaign,
    updateCampaign,
    changeStatusCampaign,
    resetCampaigns
  };
};
