import api from '../services/api';

export const totalHectaresUseCase = async (
  successCallback = (info: any) => {},
  failureCallback = (e: any) => {}
) => {
  try {
    const { data } = await api.get(`/dashboard/total-hectares-variety`);
    successCallback(data);
  } catch (e) {
    failureCallback(e);
  }
};
