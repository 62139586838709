import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import {
  LIST_ADMIN_USERS,
  CREATE_ADMIN_USER,
  EDIT_ADMIN_USER,
  DELETE_ADMIN_USER
} from '../store/slices/adminUsersSlice';

export const useAdminUsers = () => {
  const dispatch = useDispatch();

  const { users, isLoading, error, pageIndex, totalPages } = useSelector(
    ({ adminUsers }: RootState) => adminUsers
  );

  const listAdminUsers = useCallback(
    (pgSize, pgIndex, searchText?, successCallback?) =>
      dispatch(LIST_ADMIN_USERS({ pgSize, pgIndex, searchText, successCallback })),
    [dispatch]
  );

  const createAdminUser = useCallback(
    (newUser, successCallback?, failureCallback?) =>
      dispatch(CREATE_ADMIN_USER({ newUser, successCallback, failureCallback })),
    [dispatch]
  );

  const deleteAdminUser = useCallback(
    (id, successCallback?, failureCallback?) =>
      dispatch(DELETE_ADMIN_USER({ id, successCallback, failureCallback })),
    [dispatch]
  );

  const editAdminUser = useCallback(
    (id, editedUser, successCallback?, failureCallback?) =>
      dispatch(EDIT_ADMIN_USER({ id, editedUser, successCallback, failureCallback })),
    [dispatch]
  );

  return {
    users,
    isLoading,
    error,
    pageIndex,
    totalPages,
    listAdminUsers,
    createAdminUser,
    deleteAdminUser,
    editAdminUser
  };
};
