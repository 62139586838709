import styled from 'styled-components';

type ModalProps = {
  showFarms?: boolean;
};

export const StyledWrapper = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  height: ${({ showFarms }) => (showFarms ? 'fit-content' : '300px')};
  width: 826px;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonsRow = styled(StyledRow)`
  margin-top: 40px;
  justify-content: center;
`;
