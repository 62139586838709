import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { motion } from 'framer-motion';

type TabbleRowProps = {
  isOdd?: boolean;
  hasPermission?: boolean;
};

export const Table = styled.table`
  width: 100%;
  text-align: left;
  table-layout: fixed;

  background-color: ${({ theme: { colors } }) => colors.neutral.lightest};
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 2px rgba(141, 151, 158, 0.4);
`;

export const TableRow = styled.tr<TabbleRowProps>`
  width: 100%;
  height: 60px;
  background-color: ${({ isOdd, theme: { colors } }) =>
    isOdd ? colors.colorWithOpacityGenerator('neutral.default', 0.5) : colors.neutral.lightest};
`;

export const TableHeaders = styled.th<TabbleRowProps>`
  ${({ theme: { colors, typography, spacing }, hasPermission }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      border-bottom: 1px solid ${colors.colorWithOpacityGenerator('neutral.default', 0.5)};
      background-color: ${colors.white};

      :last-of-type {
        width: ${hasPermission ? '120px' : '100px'};
        text-align: ${hasPermission ? 'left' : 'center'};
        padding: 12px 0;
      }
    `};
`;

export const TableContent = styled.td`
  ${({ theme: { typography, spacing } }) =>
    css`
      padding: 12px;
      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.regular};
      line-height: ${spacing.md};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const ContentRow = styled.td<TabbleRowProps>`
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: ${({ hasPermission }) => (hasPermission ? 'flex-start' : 'center')};
`;

export const RegularButton = styled.button`
  ${({ theme: { typography, spacing } }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${spacing['2xs']};
      border: none;

      font-size: ${typography.fontSize.xm};
      font-weight: ${typography.fontWeight.bold};
      line-height: ${spacing.md};
      cursor: pointer;

      border-radius: ${spacing['2xs']};
      background: none;
    `};
`;

export const StyledSubOptionsContainer = styled(motion.tr)<TabbleRowProps>`
  width: 100%;
  background-color: ${({ isOdd, theme: { colors } }) =>
    isOdd ? colors.colorWithOpacityGenerator('neutral.default', 0.5) : colors.neutral.lightest};
`;

export const StyledSubOptions = styled.td`
  width: 100%;
  padding-left: 10%;
`;

export const StyledSubOptionsRow = styled(ContentRow)`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.default};

  :last-of-type {
    border-bottom: 0px;
  }
`;

export const StyledSubOptionsTitle = styled.p<CSSProperties>`
  ${({ theme: { typography, colors }, width }) =>
    css`
      width: ${width}%;
      font-size: ${typography.fontSize.md};
      font-weight: ${typography.fontWeight.bold};
      text-overflow: ellipsis;
      color: ${colors.neutral.darkest};
    `};
`;

export const StyledSubOptionsData = styled.p<CSSProperties>`
  ${({ theme: { typography, colors }, width }) =>
    css`
      width: ${width}%;
      font-size: ${typography.fontSize.md};
      font-weight: ${typography.fontWeight.regular};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: ${colors.neutral.darkest};
      padding-right: 10px;
    `};
`;
