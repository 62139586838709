import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import customYup from '../../../../utils/customValidation';
import { maskJs } from 'mask-js';
import { TextField, useTranslation, Select, MultiSelect, Button } from '@basfagro/core';

import * as S from './EditCustody.styles';

import { CustodyModel } from '../../../../types/models';
import { departmentsList, identificationTypes, roleTypesList } from '../../../../utils/generalInfo';

import { useCustodies, useAuth } from '../../../../hooks';
import { Header } from '../../../../components';

type DefaultValuesType = {
  name: string;
  identificationType: string;
  identificationNumber: string;
  phoneNumber: string;
  mobileNumber: string;
  cargo: string;
  departments: string[];
};

export const EditCustody = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { editCustody } = useCustodies();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Custodia');

  const custodyData = location.state
    ? (location.state as CustodyModel)
    : {
        id: -1,
        name: 'N/A',
        email: 'N/A',
        identificationType: '0',
        identificationNumber: 'N/A',
        phoneNumber: 'N/A',
        mobileNumber: 'N/A',
        cargo: { id: 0, name: 'N/A' },
        departments: [{ id: 0, name: '' }]
      };

  const custodyValues: DefaultValuesType = {
    name: custodyData.name,
    identificationType:
      identificationTypes.find(element => element.id === custodyData.identificationType)?.name ||
      'N/A',
    identificationNumber: custodyData.identificationNumber,
    phoneNumber: custodyData.phoneNumber,
    mobileNumber: custodyData.mobileNumber,
    cargo: roleTypesList[custodyData.cargo.id - 1].name,
    departments: custodyData.departments.map(({ name }) => name)
  };

  const validationSchema = yup.object({
    name: yup.string().required(t('errors.name')),
    identificationType: yup.string().required(t('errors.identificationType')),
    identificationNumber: yup.string().required(t('errors.docNumber')),
    phoneNumber: customYup.string().colombiaPhone().required(t('errors.phone')),
    mobileNumber: customYup.string().colombiaMobilePhone().required(t('errors.cellphone')),
    cargo: yup.string().required(t('errors.role')),
    departments: yup.array().of(yup.string()).min(1, t('errors.departments')).required()
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<DefaultValuesType>({
    resolver: yupResolver(validationSchema),
    defaultValues: custodyValues
  });

  const values = watch();

  const { docTypes, roleTypes } = useMemo(
    () => ({
      docTypes: identificationTypes.map(({ name }) => name),
      roleTypes: roleTypesList.map(({ name }) => name)
    }),
    []
  );

  useEffect(() => {
    register('identificationType');
    register('cargo');
    register('departments');
    setValue(
      'departments',
      custodyData.departments.map(({ name }) => name)
    );
  }, [register]);

  const onSubmit = data => {
    editCustody(
      custodyData.id,
      {
        identificationType: data.identificationType,
        identificationNumber: data.identificationNumber,
        phoneNumbers: {
          phoneNumber: data.phoneNumber,
          mobileNumber: data.mobileNumber
        },
        cargoId: roleTypesList.find(element => element.name === data.cargo)?.id || 0,
        departmentsId: departmentsList
          .filter(({ name }) => data.departments.includes(name))
          .map(({ id }) => id)
      },
      () => navigate('/dashboard/approvations/custody', { replace: true })
    );
  };

  return (
    <S.StyledContainer>
      <Header title={t('custodyFields.headerTitle')} subtitle={t('custodyFields.headerSubTitle')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.FormRow>
          <TextField
            type='formType'
            label={t('fields.name')}
            value={custodyData.name}
            placeholder={t('fields.name')}
            readOnly
          />
          <TextField
            type='formType'
            label={t('fields.mail')}
            placeholder={t('fields.mail')}
            value={custodyData.email}
            disabled
          />
        </S.FormRow>
        <S.FormRow>
          <Select
            label={t('fields.docType')}
            optionsList={docTypes}
            value={values.identificationType}
            onSelect={v => setValue('identificationType', v)}
            col={5}
            disabled={!hasPermission}
          />

          <TextField
            type='formType'
            label={t('fields.docNumber')}
            placeholder={t('fields.typeNumber')}
            {...register('identificationNumber')}
            col={5}
            error={errors.identificationNumber}
            readOnly={!hasPermission}
          />

          <Select
            label={t('fields.role')}
            optionsList={roleTypes}
            value={values.cargo}
            onSelect={v => setValue('cargo', v)}
            col={6}
            disabled={!hasPermission}
          />
        </S.FormRow>
        <S.FormRow>
          <TextField
            type='formType'
            label={t('fields.phone')}
            placeholder={t('textfields.typePhoneNumber')}
            value={maskJs('999 9999', values.phoneNumber)}
            {...register('phoneNumber')}
            col={6}
            error={errors.phoneNumber}
            readOnly={!hasPermission}
          />
          <TextField
            type='formType'
            label={t('fields.cellphone')}
            placeholder={t('textfields.typeCellPhoneNumber')}
            value={maskJs('999 999 9999', values.mobileNumber)}
            {...register('mobileNumber')}
            col={6}
            error={errors.mobileNumber}
            readOnly={!hasPermission}
          />
        </S.FormRow>
        <S.FormRow>
          <MultiSelect
            options={departmentsList.map(({ name }) => name)}
            selectedOptions={values.departments}
            onSelect={v => setValue('departments', v, { shouldValidate: true })}
            label={hasPermission ? t('fields.selectDepartment') : t('fields.selectedDepartments')}
            error={(errors.departments as any)?.message}
            disabled={!hasPermission}
            placeholder={t('fields.selectState')}
          />
        </S.FormRow>
        <S.ButtonsRow>
          <S.ButtonWrapper>
            <Button
              buttonType='secondary'
              onClick={() => navigate('/dashboard/approvations/custody', { replace: true })}
            >
              {hasPermission ? t('buttons.discardChanges') : t('evaluationForm.goBack')}
            </Button>
          </S.ButtonWrapper>
          {hasPermission && (
            <S.ButtonWrapper>
              <Button buttonType='primary' type='submit'>
                {t('buttons.saveChanges')}
              </Button>
            </S.ButtonWrapper>
          )}
        </S.ButtonsRow>
      </form>
    </S.StyledContainer>
  );
};

export default EditCustody;
