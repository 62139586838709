import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import {
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SUCCESS,
  LIST_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  GET_DETAILED_CAMPAIGN,
  GET_DETAILED_CAMPAIGN_SUCCESS,
  GET_DETAILED_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  CHANGE_STATUS_CAMPAIGN,
  CHANGE_STATUS_CAMPAIGN_SUCCESS,
  CHANGE_STATUS_CAMPAIGN_FAILURE
} from '../slices/campaignsSlice';

import api from '../../services/api';

type CampaignsParams = {
  payload: {
    page?: number;
    id?: number;
    newCampaign?: {};
    updateCampaign?: {};
    successCallback: Function;
  };
};

function* listCampaigns({ payload: { page, successCallback = () => {} } }: CampaignsParams) {
  try {
    const response = yield call(
      api.get,
      `/campaignmanagement/campaigns?PageSize=10&PageIndex=${page}`
    );
    let {
      data: { dataList, pagesCount: totalPages }
    } = response;

    dataList = dataList.map(i => ({
      ...i,
      rites: i.rites.map((r, index) => ({ ...r, screen: index + 1 }))
    }));

    yield put(LIST_CAMPAIGNS_SUCCESS({ dataList, totalPages }));
    successCallback();
  } catch (error) {
    yield put(LIST_CAMPAIGNS_FAILURE({ error }));
  }
}

function* createCampaigns({
  payload: { newCampaign, successCallback = () => {} }
}: CampaignsParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const response = yield call(api.post, '/campaignmanagement/campaigns', newCampaign);
    const { data } = response;

    yield put(CREATE_CAMPAIGN_SUCCESS(data));
    successCallback();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(CREATE_CAMPAIGN_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* getDetailedCampaign({ payload: { id, successCallback = () => {} } }: CampaignsParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const response = yield call(api.get, `/campaignmanagement/campaigns/${id}`);
    const { data } = response;

    yield put(GET_DETAILED_CAMPAIGN_SUCCESS());
    successCallback(data);
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(GET_DETAILED_CAMPAIGN_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* updateCampaign({
  payload: { id, updateCampaign, successCallback = () => {} }
}: CampaignsParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const response = yield call(api.put, `/campaignmanagement/campaigns/${id}`, updateCampaign);
    const { data } = response;

    yield put(UPDATE_CAMPAIGN_SUCCESS(data));
    successCallback(data);
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(UPDATE_CAMPAIGN_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* changeStatusCampaign({ payload: { id, successCallback = () => {} } }: CampaignsParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `/campaignmanagement/campaigns/${id}/disable-enable`);

    yield put(CHANGE_STATUS_CAMPAIGN_SUCCESS(id));
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
    successCallback();
  } catch (error) {
    yield put(CHANGE_STATUS_CAMPAIGN_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

export default function* watcher() {
  yield takeEvery(LIST_CAMPAIGNS, listCampaigns);
  yield takeEvery(CREATE_CAMPAIGN, createCampaigns);
  yield takeEvery(GET_DETAILED_CAMPAIGN, getDetailedCampaign);
  yield takeEvery(UPDATE_CAMPAIGN, updateCampaign);
  yield takeEvery(CHANGE_STATUS_CAMPAIGN, changeStatusCampaign);
}
