import i18n from '../services/translations';

export const userFilter = [
  {
    sectionName: i18n.t('filterFields.displayBy'),
    type: 'whereBy',
    options: [
      { name: i18n.t('fields.name'), value: 1 },
      { name: i18n.t('fields.mail'), value: 2 }
    ]
  }
];

export const creationDateFilter = [
  {
    sectionName: i18n.t('filterFields.creationDate'),
    type: 'sortBy',
    options: [
      { name: i18n.t('filterFields.old'), value: 1 },
      { name: i18n.t('filterFields.recent'), value: 2 }
    ]
  }
];
