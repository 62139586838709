import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from '../../services/translations';

import { useAuth, useFarmers } from '../../hooks';

import * as S from './Menu.styles';
import whiteLogo from '../../assets/images/logo/whiteCompleteLogo.svg';

import { Icon } from '../';

import MenuOption from './MenuOption';

type OptionsType = {
  id: number;
  title: string;
  icon?: string;
  screen: string;
  subOptions?: OptionsType[];
};

export const Menu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { setUserInfo } = useAuth();
  const { logout, hasEditPermission, hasViewPermission, isLoggedIn, user } = useAuth();
  const { editFarmerTabIndex, editSearchedFarmerText } = useFarmers();
  const [selectedOption, setSelectedOption] = useState(0);

  document.title = t('appName');

  useEffect(() => {
    setUserInfo(
      () => {},
      () => logout()
    );
  }, []);

  const onPressOption = (screen, id) => {
    if (screen === 'farmers') {
      editSearchedFarmerText('');
      editFarmerTabIndex(0);
    }
    setSelectedOption(id);
    navigate(screen);
  };

  const permissionOpt = useMemo(() => {
    let optID = 0;
    let menuOptions: OptionsType[] = [
      { id: 0, title: t('menu.dashboard'), icon: 'IcDashboard', screen: '/dashboard' }
    ];

    if (
      (hasEditPermission('Custodia') || hasViewPermission('Custodia')) &&
      (hasEditPermission('Distributor') || hasViewPermission('Distributor'))
    ) {
      optID++;
      menuOptions.push({
        id: optID,
        title: t('menu.aprovations'),
        icon: 'IcClipboard',
        screen: 'approvations',
        subOptions: [
          {
            id: optID + 0.1,
            title: t('menu.custody'),
            screen: 'approvations/custody'
          },
          {
            id: optID + 0.2,
            title: t('menu.distributors'),
            screen: 'approvations/distributors'
          }
        ]
      });
    } else if (hasEditPermission('Custodia') || hasViewPermission('Custodia')) {
      optID++;
      menuOptions.push({
        id: optID,
        title: t('menu.aprovations'),
        icon: 'IcClipboard',
        screen: 'approvations',
        subOptions: [
          {
            id: optID + 0.1,
            title: t('menu.custody'),
            screen: 'approvations/custody'
          }
        ]
      });
    } else if (hasEditPermission('Distributor') || hasViewPermission('Distributor')) {
      optID++;
      menuOptions.push({
        id: optID,
        title: t('menu.aprovations'),
        icon: 'IcClipboard',
        screen: 'approvations',
        subOptions: [
          {
            id: optID + 0.1,
            title: t('menu.distributors'),
            screen: 'approvations/distributors'
          }
        ]
      });
    }

    if (hasEditPermission('Farmer') || hasViewPermission('Farmer')) {
      optID++;
      menuOptions.push({
        id: optID,
        title: t('menu.farmers'),
        icon: 'IcPlant',
        screen: 'farmers'
      });
    }

    if (hasEditPermission('Administrator') || hasViewPermission('Administrator')) {
      optID++;
      menuOptions.push({
        id: optID,
        title: t('menu.administrators'),
        icon: 'IcBook',
        screen: 'admin',
        subOptions: [
          {
            id: optID + 0.1,
            title: t('menu.riteCategories'),
            screen: 'admin/categories'
          },
          { id: optID + 0.2, title: t('menu.campaigns'), screen: 'admin/campaigns' },
          { id: optID + 0.3, title: t('menu.users'), screen: 'admin/users' },
          { id: optID + 0.4, title: t('menu.forms'), screen: 'admin/evaluations' },
          {
            id: optID + 0.5,
            title: t('menu.formCategories'),
            screen: 'admin/form-categories'
          }
        ]
      });
    }
    return menuOptions;
  }, [hasEditPermission, hasViewPermission, isLoggedIn, user]);

  useEffect(() => {
    const selectedPath = pathname.split('/')?.[2];
    if (selectedPath) {
      const { id } = permissionOpt.find(({ screen }) => screen === selectedPath) ?? { id: 0 };
      setSelectedOption(id);
    }
  }, [pathname, permissionOpt]);

  return (
    <S.WrapperDiv>
      <S.MenuWrapper>
        <S.OptionsAndHeaderWrapper>
          <S.Logo src={whiteLogo} alt='Basf logo' />
          <S.OptionsWrapper>
            <S.AnimatedDiv
              animate={{
                y: `${selectedOption * 42 + selectedOption * 16}px`,
                height: `${
                  ((permissionOpt.find(({ id }) => id === selectedOption)?.subOptions?.length ??
                    0) +
                    1) *
                  42
                }px`
              }}
              transition={{ type: 'spring', bounce: 0.2, velocity: 3 }}
            />
            {permissionOpt.map(({ title, id, icon, subOptions, screen }) => (
              <MenuOption
                id={id}
                title={title}
                icon={icon}
                isSelected={selectedOption === id}
                onPress={() =>
                  onPressOption(!subOptions?.length ? screen : subOptions[0].screen, id)
                }
                subOptions={subOptions}
                key={id}
              />
            ))}
          </S.OptionsWrapper>
        </S.OptionsAndHeaderWrapper>
        <S.LogoutContainer onClick={() => logout()}>
          <Icon icon='IcLogout' color='white' />
          <S.LogoutTitle>{t('menu.logout')}</S.LogoutTitle>
        </S.LogoutContainer>
      </S.MenuWrapper>
    </S.WrapperDiv>
  );
};

export default Menu;
