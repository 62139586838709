import { ErrorText, useTranslation } from '@basfagro/core';
import { Icon } from '..';
import * as S from './ButtonSelector.styles';

type ButtonSelectorProps = {
  values: number[];
  onSelect: Function;
  options: { id: number; icon: string; title: string }[];
  col?: number;
  error?: string;
  disabled?: boolean;
};

const ButtonSelector = ({
  values,
  onSelect,
  options,
  col = 16,
  error,
  disabled
}: ButtonSelectorProps) => {
  const { t } = useTranslation();

  const onPressOptions = id => {
    let aux = [...values];
    if (values.includes(id)) aux = values.filter(i => i !== id);
    else aux.push(id);
    onSelect(aux);
  };

  return (
    <S.Container width={(100 / 16) * col}>
      <S.Label disabled={disabled}>{t('riteCampaign.showButtons')}</S.Label>
      <S.Row>
        {options.map(({ id, icon, title }) => (
          <S.ButtonContainer
            key={id}
            isSelected={values.includes(id)}
            onClick={() => {
              !disabled && onPressOptions(id);
            }}
          >
            <Icon icon={icon} width={23} height={23} />
            <label>{title}</label>
          </S.ButtonContainer>
        ))}
      </S.Row>
      {error && <ErrorText style={{ textAlign: 'left' }}>{error}</ErrorText>}
    </S.Container>
  );
};

export default ButtonSelector;
