import { createSlice } from '@reduxjs/toolkit';

export type CampaignSliceType = {
  isLoading: boolean;
  isCultureLoading: boolean;
  isProductsLoading: boolean;
  totalPages: number;
  campaigns: {
    id: number;
    description: string;
    active: boolean;
    varietyId: number;
    varietyName: string;
    cultivationName: string;
  }[];
  error: Object | null;
};

const initialState: CampaignSliceType = {
  totalPages: 0,
  isLoading: true,
  isCultureLoading: true,
  isProductsLoading: true,
  campaigns: [],
  error: null
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    RESET_CAMPAIGNS: state => ({ ...state, ...initialState }),
    LIST_CAMPAIGNS: (state, action) => ({ ...state, isLoading: true }),
    CREATE_CAMPAIGN: (state, action) => ({ ...state, isLoading: true }),
    GET_DETAILED_CAMPAIGN: (state, action) => ({ ...state, isLoading: true }),
    CHANGE_STATUS_CAMPAIGN: (state, action) => ({ ...state, isLoading: true }),
    UPDATE_CAMPAIGN: (state, action) => ({ ...state, isLoading: true }),
    LIST_CAMPAIGNS_SUCCESS: (state, { payload: { dataList, ...data } }) => ({
      ...state,
      isLoading: false,
      ...data,
      campaigns: dataList
    }),
    LIST_CAMPAIGNS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      pageIndex: null,
      pageSize: null,
      totalData: null,
      pagesCount: null,
      error: payload.error
    }),

    CREATE_CAMPAIGN_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      campaigns: [...state.campaigns, payload]
    }),
    CREATE_CAMPAIGN_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    GET_DETAILED_CAMPAIGN_SUCCESS: state => ({
      ...state,
      isLoading: false
    }),
    GET_DETAILED_CAMPAIGN_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    UPDATE_CAMPAIGN_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      campaigns: state.campaigns.map(i => (i.id === payload.id ? payload : i))
    }),
    UPDATE_CAMPAIGN_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    CHANGE_STATUS_CAMPAIGN_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      campaigns: state.campaigns.map(i => (i.id === payload ? { ...i, active: !i.active } : i))
    }),
    CHANGE_STATUS_CAMPAIGN_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    })
  }
});

const { actions, reducer } = campaignSlice;

export const {
  RESET_CAMPAIGNS,
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SUCCESS,
  LIST_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  GET_DETAILED_CAMPAIGN,
  GET_DETAILED_CAMPAIGN_SUCCESS,
  GET_DETAILED_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,
  CHANGE_STATUS_CAMPAIGN,
  CHANGE_STATUS_CAMPAIGN_SUCCESS,
  CHANGE_STATUS_CAMPAIGN_FAILURE
} = actions;
export default reducer;
