import * as yup from 'yup';
import i18n from '../../../services/translations';
import customYup from '../../../utils/customValidation';

export const validationSchema = yup
  .object({
    name: yup.string().required(i18n.t('validations.required')),
    areaInHectares: yup.string().required(i18n.t('validations.required')),
    address: yup.object({
      department: yup.string().required(i18n.t('validations.required')),
      city: customYup.string().choosenCity().required(i18n.t('validations.required'))
    })
  })
  .required();
