import { createSlice } from '@reduxjs/toolkit';

export type AdminUsersSliceType = {
  isLoading: boolean;
  pageIndex: number;
  totalPages: number;
  users: {
    id: number;
    name: string;
    email: string;
    phoneNumber: string;
    administratorType: number;
    permissionsId: { id: number; name: string; action: number }[];
  }[];
  error: Object | null;
};

const initialState: AdminUsersSliceType = {
  isLoading: false,
  pageIndex: 1,
  totalPages: 0,
  users: [],
  error: null
};

const usersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    LIST_ADMIN_USERS: (state, action) => ({ ...state, isLoading: true }),
    LIST_ADMIN_USERS_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      users: payload.dataList,
      totalPages: payload.pagesCount
    }),
    LIST_ADMIN_USERS_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    CREATE_ADMIN_USER: (state, action) => ({ ...state, isLoading: true }),
    CREATE_ADMIN_USER_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false
    }),
    CREATE_ADMIN_USER_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    EDIT_ADMIN_USER: (state, action) => ({ ...state, isLoading: true }),
    EDIT_ADMIN_USER_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      users: state.users.map(user => (user.id !== payload.id ? user : { ...user, ...payload }))
    }),
    EDIT_ADMIN_USER_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    }),
    DELETE_ADMIN_USER: (state, action) => ({ ...state, isLoading: true }),
    DELETE_ADMIN_USER_SUCCESS: (state, { payload }) => ({
      ...state,
      isLoading: false,
      users: state.users.filter(({ id }) => id !== payload.id)
    }),
    DELETE_ADMIN_USER_FAILURE: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error
    })
  }
});

const { actions, reducer } = usersSlice;

export const {
  LIST_ADMIN_USERS,
  LIST_ADMIN_USERS_SUCCESS,
  LIST_ADMIN_USERS_FAILURE,
  CREATE_ADMIN_USER,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAILURE,
  EDIT_ADMIN_USER,
  EDIT_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER_FAILURE,
  DELETE_ADMIN_USER,
  DELETE_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_FAILURE
} = actions;

export default reducer;
