import api from '../services/api';

export const checkActivityCase = async (
  id: number,
  successCallback = (status: any) => {},
  failureCallback = (e: any) => {}
) => {
  try {
    const { data } = await api.get(`/campaignmanagement/campaigns/check-activity/${id}`);
    successCallback(data);
  } catch (e) {
    failureCallback(e);
  }
};
