import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../services/translations';

import * as S from './CustodiesTable.styles';
import { Icon } from '..';
import { roleTypesList } from '../../utils/generalInfo';

type HeaderType = {
  [keys: string]: string;
};

type CustodiesTableProps = {
  headers: HeaderType;
  tableData: {
    id: number;
    name: string;
    email: string;
    identificationType: number;
    identificationNumber: string;
    phoneNumber: string;
    mobileNumber: string;
    status: number;
    cargo: { id: number; name: string };
    departments: { id: number; name: string }[];
  }[];
  type: 'inProcess' | 'accepted' | 'denied';
  onPressDelete: Function;
  onPressApprove: Function;
  onPressDeny: Function;
  canEdit: boolean;
};

export const CustodiesTable = ({
  headers,
  tableData,
  type,
  onPressDelete,
  onPressApprove,
  onPressDeny,
  canEdit
}: CustodiesTableProps) => {
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const headersKeys = Object.keys(headers);

  const ActionsHeader = () => {
    if (canEdit) {
      switch (type) {
        case 'accepted':
          return (
            <S.TableHeaders style={{ width: '100px' }}>
              {translate('fields.evaluation')}
            </S.TableHeaders>
          );
        case 'denied':
          return (
            <S.TableHeaders style={{ width: '180px' }}>
              {translate('fields.evaluation')}
            </S.TableHeaders>
          );
        case 'inProcess':
          return (
            <S.TableHeaders style={{ width: '210px' }}>
              {translate('fields.evaluation')}
            </S.TableHeaders>
          );
        default:
          return;
      }
    } else {
      return (
        <S.TableHeaders style={{ width: '80px' }}>{translate('table.actions')}</S.TableHeaders>
      );
    }
  };

  return (
    <S.TableWrapper>
      <S.Table>
        <thead>
          <S.TableRow>
            {Object.values(headers).map(header => (
              <S.TableHeaders key={header}>{header}</S.TableHeaders>
            ))}
            {ActionsHeader()}
          </S.TableRow>
        </thead>
        <tbody>
          {tableData.map((dataGroup, index) => {
            return (
              <S.TableRow key={index}>
                {headersKeys.map(info => {
                  if (info === 'departments') {
                    return (
                      <S.TableContent key={info}>
                        <S.ContentRow style={{ justifyContent: 'left' }}>
                          <S.TableText>{dataGroup[info][0]?.name}</S.TableText>
                          {dataGroup[info][1] && (
                            <S.DepartmentPlusNumber>
                              <p>+{dataGroup[info].length - 1}</p>
                              <S.DepartmentPlusList>
                                {dataGroup[info].map((e, index) => {
                                  if (index !== 0) {
                                    return (
                                      <li key={index}>
                                        <p>{e.name}</p>
                                      </li>
                                    );
                                  }
                                })}
                              </S.DepartmentPlusList>
                            </S.DepartmentPlusNumber>
                          )}
                        </S.ContentRow>
                      </S.TableContent>
                    );
                  } else if (info === 'cargo') {
                    return (
                      <S.TableContent key={info}>
                        <S.TableText>{roleTypesList[dataGroup[info].id - 1]?.name}</S.TableText>
                      </S.TableContent>
                    );
                  } else {
                    return (
                      <S.TableContent key={info}>
                        <S.TableText>{dataGroup[info]}</S.TableText>
                      </S.TableContent>
                    );
                  }
                })}
                <S.TableContent>
                  {canEdit && type === 'inProcess' ? (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => navigate('edit', { state: dataGroup })}
                      >
                        <Icon icon='IcEdit' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='primary'
                        onClick={() => onPressApprove(dataGroup.id, dataGroup.name)}
                      >
                        {translate('custodyFields.buttons.approve')}
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='secondary'
                        onClick={() => onPressDeny(dataGroup.id, dataGroup.name)}
                      >
                        {translate('custodyFields.buttons.deny')}
                      </S.RegularButton>
                    </S.ContentRow>
                  ) : canEdit && type === 'accepted' ? (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => navigate('edit', { state: dataGroup })}
                      >
                        <Icon icon='IcEdit' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => onPressDelete(dataGroup.id, dataGroup.name)}
                      >
                        <Icon icon='IcTrash' color='secondary' />
                      </S.RegularButton>
                    </S.ContentRow>
                  ) : canEdit && type === 'denied' ? (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => navigate('edit', { state: dataGroup })}
                      >
                        <Icon icon='IcEdit' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => onPressDelete(dataGroup.id, dataGroup.name)}
                      >
                        <Icon icon='IcTrash' color='secondary' />
                      </S.RegularButton>
                      <S.RegularButton
                        buttonType='primary'
                        onClick={() => onPressApprove(dataGroup.id, dataGroup.name)}
                      >
                        {translate('custodyFields.buttons.approve')}
                      </S.RegularButton>
                    </S.ContentRow>
                  ) : (
                    <S.ContentRow>
                      <S.RegularButton
                        buttonType='icon'
                        onClick={() => navigate('edit', { state: dataGroup })}
                      >
                        <Icon icon='IcEye' color='secondary' />
                      </S.RegularButton>
                    </S.ContentRow>
                  )}
                </S.TableContent>
              </S.TableRow>
            );
          })}
        </tbody>
      </S.Table>
    </S.TableWrapper>
  );
};

export default CustodiesTable;
