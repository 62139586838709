import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type LabelProps = {
  disabled?: boolean;
};

export const Container = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: ${({ width }) => width}%;
  margin-top: ${({ theme }) => theme.spacing.md};
  padding-right: ${({ theme }) => theme.spacing.sm};
`;

export const BubblesContainer = styled.div`
  ${({ theme: { colors, spacing } }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: ${spacing.sm} ${spacing.sm} ${spacing.sm} 0px;

    ::-webkit-scrollbar {
      width: 8px;
      border-radius: ${spacing['2xs']};
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.neutral.default};
      border-radius: ${spacing['2xs']};
    }
  `};
`;

export const Bubble = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 15px;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    border-radius: 1px 20px 20px 20px;
    background-color: ${colors.colorWithOpacityGenerator('neutral.default', 0.6)};
    position: relative;
  `};
`;

export const BubbleText = styled.p`
  flex: 1;
  flex-direction: 1;
  width: 90%;
  overflow-wrap: break-word;
`;

export const Label = styled.label<LabelProps>`
  ${({ theme: { colors, typography }, disabled }) => css`
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.xs};
    line-height: 22px;
    margin-bottom: -28px;
    color: ${disabled ? colors.neutral.dark : colors.black};
  `};
`;
