import styled from 'styled-components';

type ModalProps = {
  visibility?: boolean;
  hasExit?: boolean;
};

export const ModalWrapper = styled.div<ModalProps>`
  display: ${({ visibility }) => (visibility ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  background-color: rgba(67, 67, 67, 0.5);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 560px;
  min-height: 180px;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 10px 2px rgba(141, 151, 158, 0.2);
`;

export const StyledContainer = styled.div<ModalProps>`
  padding: ${({ hasExit }) => (hasExit ? '0px 20px 20px 20px' : '0')};
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;

  svg {
    margin: 12px 12px 0px 0px;

    :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
