import { useState, useMemo } from 'react';

import * as S from './TabBar.styles';

export type TabBarProps = {
  options: { id: number; name: string }[];
  onPressOption: Function;
  selectedOpt?: number;
};

const TabBar = ({ options, onPressOption, selectedOpt = 0 }: TabBarProps) => {
  const [selectedOption, setSelectedOption] = useState(selectedOpt);

  const { optionWidth } = useMemo(() => ({ optionWidth: 100 / options.length }), [options]);

  const onPress = id => {
    setSelectedOption(id);
    onPressOption(id);
  };

  return (
    <S.Container>
      <S.OptionsContainer>
        {options.map(({ id, name }) => (
          <S.ButtonWrapper key={id} width={optionWidth} onClick={() => onPress(id)}>
            {name}
          </S.ButtonWrapper>
        ))}
      </S.OptionsContainer>
      <S.OptionsBar>
        <S.SelectedOption
          width={optionWidth}
          animate={{ x: `${selectedOption * 100}%` }}
          transition={{ type: 'spring', bounce: 0.2, velocity: 3 }}
        />
      </S.OptionsBar>
    </S.Container>
  );
};

export default TabBar;
