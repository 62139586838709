import * as S from './Modal.styles';

import { Icon } from '../';

type ModalProps = {
  visibility: boolean;
  toggleVisibility: Function;
  children?: JSX.Element | JSX.Element[];
  hasExit?: boolean;
};

export const Modal = ({ visibility = false, toggleVisibility, hasExit, children }: ModalProps) => (
  <S.ModalWrapper visibility={visibility}>
    <S.ModalContent onClick={e => e.stopPropagation()}>
      {hasExit && (
        <S.ModalHeader>
          <Icon color='black' icon='IcClose' onClick={() => toggleVisibility()} />
        </S.ModalHeader>
      )}
      <S.StyledContainer hasExit={hasExit}>{children}</S.StyledContainer>
    </S.ModalContent>
  </S.ModalWrapper>
);

export default Modal;
