import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { CSSAttributes } from '../../types/CSSAttributes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  height: auto;
  margin-top: 24px;
  border-radius: 8px;
`;

export const OptionsBar = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.neutral.default};
`;

export const SelectedOption = styled(motion.div)<CSSAttributes>`
  width: ${({ width }) => width}%;
  height: 4px;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.neutral.dark};
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.button<CSSAttributes>`
  ${({ theme: { typography }, width }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${width}%;
    padding: 8px;
    cursor: pointer;
    font-size: ${typography.fontSize.md};
    font-weight: ${typography.fontWeight.bold};
    background: none;
    border: none;
  `}
`;
