import toast from 'react-hot-toast';
import i18n from '../services/translations';
import api from '../services/api';

export const sendPasswordTokenCase = async (
  email: string,
  successCallback = () => {},
  failureCallback = (e: any) => {}
) => {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    await api.post(`/code`, { userEmail: email, operationType: 1 });
    successCallback();
    toast.success(i18n.t('reset.codeSent'), {
      id: toastId
    });
  } catch (e: any) {
    failureCallback(e.response);
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
};

export const validateCodeCase = async (
  code: string,
  successCallback = () => {},
  failureCallback = (e: any) => {}
) => {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    await api.get(`/code/apply-user-operation?Code=${code}&OperationType=1`);
    successCallback();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (e: any) {
    failureCallback(e.response);
    toast.error(i18n.t('reset.wrongCode'), {
      id: toastId
    });
  }
};

export const resetPasswordCase = async (
  email: string,
  password: string,
  successCallback = () => {},
  failureCallback = (e: any) => {}
) => {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    await api.put(`/login/reset-your-password`, {
      email: email,
      password: password,
      passwordConfirmation: password
    });

    successCallback();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (e: any) {
    failureCallback(e.response);
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
};
