import { useTranslation } from '../../services/translations';

import { Icon } from '..';
import * as S from './CampaignsTable.styles';

import { Modal } from '..';

type ModalProps = {
  visibility: boolean;
  toggleVisibility: Function;
  actions?: {
    action: Function;
    icon: string;
    title: string;
  }[];
  data: {
    [keys: string]: string | number | object | boolean | object[];
  };
};

const TableModal = ({ visibility, toggleVisibility, actions, data }: ModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal visibility={visibility} toggleVisibility={toggleVisibility} hasExit>
      <S.ModalWrapper>
        <h1>{t('modals.tableMTitle')}</h1>
        <S.ButtonsWrapper>
          {actions?.map(action => (
            <S.ModalButton key={action.title} onClick={() => action.action(data)}>
              <S.IconWrapper>
                <Icon
                  icon={
                    action.title === t('buttons.status')
                      ? data.active
                        ? 'IcInactive'
                        : 'IcActive'
                      : action.icon
                  }
                  color={
                    action.title === t('buttons.status')
                      ? data.active
                        ? 'secondary'
                        : 'primary'
                      : 'primary'
                  }
                  height={50}
                  width={50}
                />
              </S.IconWrapper>
              {action.title === t('buttons.status')
                ? data.active
                  ? t('buttons.deactivateCampaign')
                  : t('buttons.activateCampaign')
                : action.title}
            </S.ModalButton>
          ))}
        </S.ButtonsWrapper>
      </S.ModalWrapper>
    </Modal>
  );
};

export default TableModal;
