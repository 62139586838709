import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
  height: fit-content;
  min-height: 520px;
  padding: ${({ theme: { spacing } }) => `${spacing['2xs']} ${spacing.sm}`};
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ theme: { spacing } }) => spacing.sm};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 300px;
  height: 100%;

  align-self: center;
  align-items: flex-end;

  margin-bottom: ${({ theme: { spacing } }) => spacing.sm};
`;

export const StyledFieldSet = styled.fieldset`
  width: 100%;
  border: none;
`;
