import styled from 'styled-components';

export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  padding: 40px 16px 0px 16px;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
  overflow: auto;
`;

export const StyledButtonContainer = styled.div`
  width: 300px;
  height: 42px;
  align-self: flex-start;
  margin-bottom: 15px;
`;
export const StyledSearchContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ theme: { spacing } }) => spacing.sm} 0;
`;

export const StyledLoading = styled.img`
  width: 8%;
  height: auto;
  align-self: center;
`;
