import { Icon } from '../../components';

import * as S from './ScreenSlider.styles';

type ScreenSliderProps = {
  placeholder?: string;
  screens: string[];
  selectedScreen: number;
  onChange: Function;
  onAdd: Function;
  onDelete?: Function;
  onEdit?: Function;
  errors?: string[];
  isEditable?: boolean;
  disabled?: boolean;
  bigMargin?: boolean;
};

const ScreenSlider = ({
  screens,
  onChange,
  selectedScreen,
  onAdd,
  onDelete,
  errors,
  placeholder,
  isEditable = false,
  disabled = false,
  onEdit,
  bigMargin = true
}: ScreenSliderProps) => {
  const onDeleteScreen = (e, index) => {
    e.stopPropagation();
    onDelete?.(index);
  };

  const onEditScreen = (e, index) => {
    e.stopPropagation();
    onEdit?.(index);
  };

  return (
    <S.StyledContainer marginTop={bigMargin ? 64 : 16} marginBottom={bigMargin ? 64 : 16}>
      {placeholder && !screens.length && (
        <S.Placeholder onClick={() => onAdd()}>{placeholder}</S.Placeholder>
      )}
      {screens?.map((screen, index) => (
        <S.StyledOption
          key={index}
          isSelected={selectedScreen === index}
          onClick={() => onChange(index)}
          error={errors?.includes(`${index}`)}
        >
          {screen}
          {screens.length > 1 && !isEditable && !!onDelete && !disabled && (
            <S.SVGWrapper>
              <Icon
                icon='IcClose'
                color='black'
                onClick={e => onDeleteScreen(e, index)}
                width={18}
                height={18}
              />
            </S.SVGWrapper>
          )}
          {isEditable && !disabled && (
            <S.SVGWrapper>
              <Icon
                id='edit'
                icon='IcLineEdit'
                color='secondary'
                onClick={e => onEditScreen(e, index)}
                width={18}
                height={18}
              />
            </S.SVGWrapper>
          )}
        </S.StyledOption>
      ))}
      {!disabled && (
        <S.SVGWrapper>
          <Icon icon='IcMore' color='black' onClick={() => onAdd()} />
        </S.SVGWrapper>
      )}
    </S.StyledContainer>
  );
};

export default ScreenSlider;
