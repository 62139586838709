import { useCallback, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../store/slices';
import { setAuthorization } from '../services/api';
import { LOGIN } from '../store/slices/authSlice';

export const useAuth = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(({ auth }: RootState) => auth);

  const login = useCallback(() => {
    keycloak.login({
      redirectUri: process.env.REACT_APP_REDIRECT_URL || ''
    });
  }, [keycloak]);

  const logout = useCallback(() => {
    localStorage.removeItem('@basfagro-admin/userInfo');
    keycloak.logout({ redirectUri: process.env.REACT_APP_REDIRECT_URL || '' });
  }, [keycloak]);

  const setUserInfo = useCallback(
    (successCallback?, failureCallback?) => {
      dispatch(LOGIN({ successCallback, failureCallback }));
    },
    [dispatch]
  );

  const setAuthorizationToken = () => {
    setAuthorization(keycloak.token);
  };

  const isLoggedIn = useMemo(() => keycloak.authenticated, [keycloak.authenticated]);

  const hasViewPermission = useCallback(
    permission => {
      const info = localStorage.getItem('@basfagro-admin/userInfo');

      if (info) {
        const { permissions } = JSON.parse(info);
        return permissions.find(({ name, action }) => name === permission && action === 1);
      }
    },
    [localStorage]
  );

  const hasEditPermission = useCallback(
    permission => {
      const info = localStorage.getItem('@basfagro-admin/userInfo');
      if (info) {
        const { permissions } = JSON.parse(info);
        return permissions.find(({ name, action }) => name === permission && action === 2);
      }
    },
    [localStorage]
  );

  return {
    user,
    isLoggedIn,
    isLoading,
    login,
    logout,
    setAuthorizationToken,
    setUserInfo,
    hasViewPermission,
    hasEditPermission
  };
};
