import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import i18n from '../../services/translations';

import { CustodyModel } from '../../types/models';

import {
  LIST_CUSTODIES,
  LIST_CUSTODIES_FAILURE,
  LIST_CUSTODIES_SUCCESS,
  APPROVE_CUSTODY,
  APPROVE_CUSTODY_SUCCESS,
  APPROVE_CUSTODY_FAILURE,
  DENY_CUSTODY,
  DENY_CUSTODY_SUCCESS,
  DENY_CUSTODY_FAILURE,
  EDIT_CUSTODY,
  EDIT_CUSTODY_SUCCESS,
  EDIT_CUSTODY_FAILURE,
  DELETE_CUSTODY,
  DELETE_CUSTODY_SUCCESS,
  DELETE_CUSTODY_FAILURE
} from '../slices/custodiesSlice';

import api from '../../services/api';

type CustodyParams = {
  payload: {
    id: number;
    newCustody: CustodyModel;
    successCallBack: Function;
  };
};

function* listCustodies({
  payload: { successCallBack = () => {}, name, pgSize, pgIndex, status }
}: {
  payload: {
    successCallBack: Function;
    name: string;
    pgSize: number;
    pgIndex: number;
    status: number;
  };
}) {
  try {
    const nameParam = name ? `&NameEmail=${name}` : '';
    const response = yield call(
      api.get,
      `usermanagement/custodias/?pageSize=${pgSize}&pageIndex=${pgIndex}&status=${status}${nameParam}`
    );
    const { data } = response;

    yield put(LIST_CUSTODIES_SUCCESS(data));
    successCallBack();
  } catch (error) {
    yield put(LIST_CUSTODIES_FAILURE({ error }));
  }
}

function* approveCustody({
  payload: { id, successCallBack = () => {} }
}: {
  payload: { successCallBack: Function; id: number };
}) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `usermanagement/custodias/${id}/evaluate`, {
      approved: true
    });

    yield put(APPROVE_CUSTODY_SUCCESS({ id }));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(APPROVE_CUSTODY_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* denyCustody({
  payload: { id, successCallBack = () => {} }
}: {
  payload: { successCallBack: Function; id: number };
}) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.put, `usermanagement/custodias/${id}/evaluate`, {
      approved: false
    });

    yield put(DENY_CUSTODY_SUCCESS({ id }));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(DENY_CUSTODY_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

function* editCustody({ payload: { id, newCustody, successCallBack = () => {} } }: CustodyParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    const response = yield call(api.put, `usermanagement/custodias/${id}`, newCustody);
    const { data } = response;

    yield put(EDIT_CUSTODY_SUCCESS(data));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error: any) {
    const { status } = error.response;
    if (status === 400) {
      toast.error(i18n.t('feedback.invalidValues'), {
        id: toastId
      });
    } else {
      toast.error(i18n.t('feedback.failure'), {
        id: toastId
      });
    }
    yield put(EDIT_CUSTODY_FAILURE({ error }));
  }
}

function* deleteCustody({ payload: { id, successCallBack = () => {} } }: CustodyParams) {
  const toastId = toast.loading(i18n.t('feedback.loading'));
  try {
    yield call(api.delete, `usermanagement/custodias/${id}`);

    yield put(DELETE_CUSTODY_SUCCESS({ id }));
    successCallBack();
    toast.success(i18n.t('feedback.success'), {
      id: toastId
    });
  } catch (error) {
    yield put(DELETE_CUSTODY_FAILURE({ error }));
    toast.error(i18n.t('feedback.failure'), {
      id: toastId
    });
  }
}

export default function* watcher() {
  yield takeEvery(LIST_CUSTODIES, listCustodies);
  yield takeEvery(APPROVE_CUSTODY, approveCustody);
  yield takeEvery(DENY_CUSTODY, denyCustody);
  yield takeEvery(EDIT_CUSTODY, editCustody);
  yield takeEvery(DELETE_CUSTODY, deleteCustody);
}
