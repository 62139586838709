import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import {
  LIST_CITIES,
  LIST_CULTURES,
  LIST_PRODUCTS,
  LIST_VARIETIES
} from '../store/slices/generalInfoSlice';

export const useGeneralInfo = () => {
  const dispatch = useDispatch();

  const { cultures, varieties, products, cities, error, isLoading, citiesPagesCount } = useSelector(
    ({ generalInfo }: RootState) => generalInfo
  );

  const listCultures = useCallback(
    (successCallback?) => dispatch(LIST_CULTURES({ successCallback })),
    [dispatch]
  );

  const listProducts = useCallback(
    (ID, successCallback?) => dispatch(LIST_PRODUCTS({ ID, successCallback })),
    [dispatch]
  );

  const listCities = useCallback(
    (ID, pgIndex, clearCities, name?, successCallback?) => {
      dispatch(LIST_CITIES({ ID, pgIndex, clearCities, name, successCallback }));
    },
    [dispatch]
  );

  const listVarieties = useCallback(
    (ID, successCallback?, failureCallback?) => {
      dispatch(LIST_VARIETIES({ ID, successCallback, failureCallback }));
    },
    [dispatch]
  );

  return {
    isLoading,
    error,
    cultures,
    varieties,
    products,
    cities,
    citiesPagesCount,
    listCultures,
    listVarieties,
    listProducts,
    listCities
  };
};
