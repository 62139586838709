import styled from 'styled-components';

type ModalProps = {
  smaller: boolean;
};

export const StyledWrapper = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  height: ${({ smaller }) => (smaller ? '200px' : '330px')};
  width: 826px;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonsRow = styled(StyledRow)`
  margin-top: 70px;
`;
