import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Select, Button, useTranslation } from '@basfagro/core';
import * as S from './LandplotModal.styles';

import { useGeneralInfo, useFarmers } from '../../hooks';
import { Modal, FeedbackModal } from '..';
import { ModalProps, validationSchema } from './LandplotModal.utils';

const LandplotModal = ({
  isVisible,
  setVisibility,
  landplot,
  hasPermission = false
}: ModalProps) => {
  const { t } = useTranslation();
  const { listCultures, cultures } = useGeneralInfo();
  const { editLandplot } = useFarmers();
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  const defaultValues = {
    name: landplot?.name,
    areaInHectares: landplot?.areaInHectares,
    cultive: landplot?.variety.cultivation,
    variety: landplot?.variety.name
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues
  });

  const values = watch();

  useEffect(() => {
    listCultures();
  }, [listCultures]);

  const varietyList = useMemo(() => {
    return (
      cultures
        .find(({ name }) => name === landplot?.variety.cultivation)
        ?.varieties.map(({ name }) => name) || []
    );
  }, [values.cultive, cultures, landplot]);

  const onSave = data => {
    const editedLandplot = {
      name: values.name,
      varietyId:
        cultures
          .find(({ name }) => name === values.cultive)
          ?.varieties.find(({ name }) => name === values.variety)?.id || -1,
      areaInHectares: values.areaInHectares,
      location: landplot?.location
    };
    editLandplot(
      landplot?.id,
      editedLandplot,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <Modal visibility={isVisible} toggleVisibility={setVisibility} hasExit>
      <S.StyledWrapper smaller={landplot?.cycleStatus || !hasPermission}>
        <S.StyledRow>
          <TextField
            label={t('farmers.landplotIdentification')}
            value={values.name}
            {...register('name')}
            error={errors.name}
            readOnly={landplot?.cycleStatus || !hasPermission}
          />
          <TextField
            label={t('farmers.area')}
            value={values.areaInHectares}
            {...register('areaInHectares')}
            error={errors.areaInHectares}
            readOnly={landplot?.cycleStatus || !hasPermission}
          />
        </S.StyledRow>
        <S.StyledRow>
          <Select
            label={t('riteCampaign.crop')}
            optionsList={cultures.map(({ name }) => name)}
            col={8}
            value={values.cultive}
            onSelect={v => setValue('cultive', v)}
            error={errors.cultive}
            disabled={landplot?.cycleStatus || !hasPermission}
          />
          <Select
            label={t('riteCampaign.variety')}
            optionsList={varietyList}
            col={8}
            value={values.variety}
            onSelect={v => setValue('variety', v)}
            error={errors.variety}
            disabled={landplot?.cycleStatus || !hasPermission}
          />
        </S.StyledRow>
        {(!landplot?.cycleStatus || !hasPermission) && (
          <S.ButtonsRow>
            <Button
              buttonType='secondary'
              height='48px'
              onClick={() => setVisibility(false)}
              marginRight='16px'
            >
              {t('buttons.discardChanges')}
            </Button>
            <Button
              buttonType='primary'
              height='48px'
              onClick={handleSubmit(onSave)}
              marginRight='16px'
            >
              {t('buttons.saveChanges')}
            </Button>
          </S.ButtonsRow>
        )}
        {FBModal && (
          <FeedbackModal
            isVisible={FBModal.state}
            setVisibility={() => {
              setFBModal({ ...FBModal, state: !FBModal.state });
              setVisibility();
            }}
            text={FBModal.text}
            icon={FBModal.icon}
          />
        )}
      </S.StyledWrapper>
    </Modal>
  );
};

export default LandplotModal;
