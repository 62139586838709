import { useTranslation, Button } from '@basfagro/core';
import { Modal } from '../Modal/Modal';
import * as S from './ConfirmModal.styles';

export type ModalProps = {
  isVisible: boolean;
  setVisibility: Function;
  title: string;
  content: string;
  onConfirm: Function;
};

export const ConfirmModal = ({
  isVisible,
  setVisibility,
  title,
  content,
  onConfirm
}: ModalProps) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visibility={isVisible} toggleVisibility={setVisibility}>
      <S.ModalContent onClick={e => e.stopPropagation()}>
        <S.Title>{title}</S.Title>
        <S.Content>{content}</S.Content>
        <S.ButtonsRow>
          <S.ButtonWrapper>
            <Button buttonType='secondary' onClick={() => setVisibility()}>
              {translate('buttons.cancelButton')}
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button buttonType='primary' onClick={() => onConfirm()}>
              {translate('buttons.confirmButton')}
            </Button>
          </S.ButtonWrapper>
        </S.ButtonsRow>
      </S.ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
