import { useEffect, useState } from 'react';

import { useTranslation, Select } from '@basfagro/core';

import * as S from './Dashboard.styles';
import Loading from '../../assets/Loading.gif';

import { Icon, Header, DashboardCard, Chart } from '../../components';
import { ReactEChartsProps } from '../../components/Chart/Chart';
import { useCustodies, useFarmers, useDistributors, useGeneralInfo, useAuth } from '../../hooks';
import { totalHectaresUseCase } from '../../useCases/totalHectaresUseCase';
import { totalVarietySales } from '../../useCases/totalVarietySales';

export const Dashboard = () => {
  const { t } = useTranslation();
  const { listCustodies, totalData: custodiesTotal, isLoading: isLoadingCustody } = useCustodies();
  const { listFarmers, totalFarmers, isLoading: isLoadingFarmer } = useFarmers();
  const {
    listDistributors,
    totalDistributors,
    isLoading: isLoadingDistributor
  } = useDistributors();
  const { listVarieties, varieties } = useGeneralInfo();
  const [selectedVariety, setSelectedVariety] = useState('');
  const [pieData, setPieData] = useState([{}] as {
    id: number;
    name: string;
    total: number;
  }[]);
  const [barData, setBarData] = useState([{}] as { month: number; totalWeight: number }[]);

  useEffect(() => {
    listCustodies(1, 1, 4);
    listFarmers(1, 1);
    listDistributors(1, 1, 4);
    totalHectaresUseCase(infoData => setPieData(infoData));
    listVarieties(1, infoData => {
      setBarData(infoData);
      setSelectedVariety(infoData[0].name);
    });
  }, []);

  useEffect(() => {
    if (varieties.find(({ name }) => name === selectedVariety)?.id) {
      totalVarietySales(varieties.find(({ name }) => name === selectedVariety)?.id, infoData =>
        setBarData(infoData)
      );
    }
  }, [selectedVariety]);

  const cardOptions = [
    { icon: 'IcPlant', title: t('menu.farmers'), count: `${totalFarmers}` },
    { icon: 'IcSuitCase', title: t('menu.custody'), count: `${custodiesTotal}` },
    {
      icon: 'IcStore',
      title: t('menu.distributors'),
      count: `${totalDistributors}`,
      hasMargin: 0
    }
  ];

  const optionPie: ReactEChartsProps['option'] = {
    legend: {
      top: 'bottom',
      left: 'right',
      orient: 'vertical',
      icon: 'circle'
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true, title: t('dashboard.saveChart') }
      }
    },
    series: [
      {
        type: 'pie',
        radius: [40, 80],
        height: '100%',
        width: '92%',
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1
        },
        label: {
          alignTo: 'labelLine',
          formatter: '{name|{b}}\n{value|{c}}, {d}%',
          minMargin: 8,
          edgeDistance: 10,
          lineHeight: 20,
          rich: {
            name: { fontSize: 12, color: '#999' },
            value: {
              fontSize: 16
            }
          }
        },
        data: [
          { name: pieData[0]?.name, value: pieData[0]?.total, itemStyle: { color: '#CC3D00' } },
          { name: pieData[1]?.name, value: pieData[1]?.total, itemStyle: { color: '#00793A' } }
        ]
      }
    ]
  };

  const optionBar: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    toolbox: {
      feature: {
        magicType: {
          show: true,
          type: ['line'],
          title: { line: t('dashboard.changeChart') }
        },
        restore: { show: true, title: t('dashboard.restoreChart') },
        saveAsImage: { show: true, title: t('dashboard.saveChart') }
      }
    },
    legend: {
      data: [t('dashboard.quantity')]
    },
    xAxis: [
      {
        type: 'category',
        name: t('dashboard.month'),
        nameLocation: 'end',
        nameTextStyle: { align: 'center', verticalAlign: 'bottom' },
        data: [
          t('dashboard.jan'),
          t('dashboard.feb'),
          t('dashboard.mar'),
          t('dashboard.apr'),
          t('dashboard.may'),
          t('dashboard.jun'),
          t('dashboard.jul'),
          t('dashboard.aug'),
          t('dashboard.sep'),
          t('dashboard.oct'),
          t('dashboard.nov'),
          t('dashboard.dec')
        ],
        axisPointer: {
          type: 'shadow'
        },
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: `${t('dashboard.quantity')} (Bultos)`,
        nameLocation: 'end',
        nameTextStyle: { align: 'left' },
        axisLine: { show: true },
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    grid: {
      left: '2%',
      right: '8%',
      bottom: '0%',
      containLabel: true
    },
    series: [
      {
        name: t('dashboard.quantity'),
        type: 'bar',
        data: barData.map(({ totalWeight }) => totalWeight),
        itemStyle: {
          color: '#00793A'
        }
      },
      {
        name: t('dashboard.quantity'),
        type: 'line',
        data: barData.map(({ totalWeight }) => totalWeight),
        itemStyle: {
          color: '#DB5701'
        }
      }
    ]
  };

  return (
    <S.DashboardWrapper>
      <Header title='Dashboard' subtitle={t('dashboard.subtitle')} />
      {isLoadingCustody || isLoadingDistributor || isLoadingFarmer ? (
        <S.StyledLoading src={Loading} />
      ) : (
        <>
          <S.CardsWrapper>
            {cardOptions.map(({ icon, title, count, hasMargin = 16 }) => (
              <DashboardCard
                key={title}
                icon={icon}
                title={title}
                count={count}
                hasMargin={hasMargin}
              />
            ))}
          </S.CardsWrapper>
          <S.ChartsRow>
            <S.ChartWrapper>
              <Icon icon='IcPlantV2' style={{ marginBottom: '8px' }} />
              <S.ChartTitle>{t('dashboard.hectares')}</S.ChartTitle>
              <S.ChartDivisor />
              {pieData[0]?.name ? (
                <Chart option={optionPie} />
              ) : (
                <S.NoDataWarn>
                  <S.StyledImageCircle>
                    <Icon icon='IcNote' width={48} height={48} />
                  </S.StyledImageCircle>
                  <S.WarnText>{t('feedback.dataMissing')}</S.WarnText>
                </S.NoDataWarn>
              )}
            </S.ChartWrapper>
            <S.ChartWrapper>
              <Icon icon='IcSeedsBag' style={{ marginBottom: '8px' }} />
              <S.ChartTitle>{t('dashboard.seeds')}</S.ChartTitle>
              <Select
                optionsList={varieties.map(({ name }) => name)}
                onSelect={v => setSelectedVariety(v)}
                value={selectedVariety}
                label={t('dashboard.selectSeed')}
                large
              />
              <S.ChartDivisor />
              <Chart option={optionBar} />
            </S.ChartWrapper>
          </S.ChartsRow>
        </>
      )}
    </S.DashboardWrapper>
  );
};

export default Dashboard;
