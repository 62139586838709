import * as yup from 'yup';

import i18n from '../services/translations';

declare module 'yup' {
  interface StringSchema {
    passport(): StringSchema;
    colombiaCC(): StringSchema;
    colombiaCE(): StringSchema;
    choosenCity(): StringSchema;
    verifyNIT(): StringSchema;
    colombiaPhone(): StringSchema;
    colombiaMobilePhone(): StringSchema;
  }
}

const verifyPassport = value => {
  return /^[A-Z]{2}[0-9]{6}$/.test(value);
};

const verifyCedulaColombia = value => {
  return /\d{8,11}/g.test(value);
};

const verifyCedulaEstrangeiraColombiana = value => {
  return /\d{8,11}/g.test(value);
};

const verifyNIT = value => {
  const aux = value.replace(/\D+/g, '');
  if (!aux || aux.length !== 10) return false;

  const digitVerification = Number(value[9]);
  const multipliers = [41, 37, 29, 23, 19, 17, 13, 7, 3];
  let multiResult = 0;

  for (let i = 0; i < multipliers.length; i += 1) {
    multiResult += multipliers[i] * (value[i] - ('0' as any));
  }

  let result = multiResult % 11;

  if (result >= 2) {
    result = 11 - result;
  }

  return result === digitVerification;
};

yup.addMethod(yup.string, 'passport', () => {
  return yup
    .string()
    .test('isPassport', i18n.t('validations.invalidInfo'), value => verifyPassport(value));
});

yup.addMethod(yup.string, 'colombiaCC', () => {
  return yup
    .string()
    .test('isColombiaCC', i18n.t('validations.invalidInfo'), value => verifyCedulaColombia(value));
});

yup.addMethod(yup.string, 'colombiaCE', () => {
  return yup
    .string()
    .test('isColombiaCE', i18n.t('validations.invalidInfo'), value =>
      verifyCedulaEstrangeiraColombiana(value)
    );
});

yup.addMethod(yup.string, 'choosenCity', () => {
  return yup
    .string()
    .test('hasCity', i18n.t('fields.selectCity'), value => value !== i18n.t('fields.selectCity'));
});

yup.addMethod(yup.string, 'verifyNIT', () => {
  return yup
    .string()
    .test('isValidNIT', i18n.t('validations.invalidInfo'), value => verifyNIT(value));
});

yup.addMethod(yup.string, 'colombiaPhone', (required = true) => {
  return yup
    .string()
    .test('isColombiaPhone', i18n.t('errors.phone'), value =>
      required ? value?.split(' ').join('').length === 7 : true
    );
});

yup.addMethod(yup.string, 'colombiaMobilePhone', () => {
  return yup
    .string()
    .test(
      'isColombiaMobilePhone',
      i18n.t('errors.cellphone'),
      value => value?.split(' ').join('').length === 10
    );
});

export default yup;
