import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 40px 16px 0px 16px;
  overflow-y: auto;
  background-color: ${({ theme: { colors } }) => colors.background.default};
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 336px;
  height: 42px;
  margin-right: ${({ theme: { spacing } }) => spacing.sm};
  :last-child {
    margin: 0;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: fit-content;
  margin-top: ${({ theme: { spacing } }) => spacing.md};
  margin-bottom: ${({ theme: { spacing } }) => spacing.md};
`;
