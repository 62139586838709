import * as yup from 'yup';
import i18n from '../../../services/translations';
import customYup from '../../../utils/customValidation';

export type FarmerType = {
  name: string;
  email: string;
  legalPerson: boolean;
  companyName: string;
  identificationNumberType: string;
  identificationNumber: string;
  phoneNumbers: {
    phoneNumber: string;
    mobileNumber: string;
  };
  address: {
    fullAddress: string;
    city: string;
    department: string;
  };
};

export const farmerDefaultValues = {
  name: '',
  email: '',
  legalPerson: false,
  companyName: '',
  identificationNumberType: 'CC',
  identificationNumber: '',
  phoneNumbers: {
    phoneNumber: '',
    mobileNumber: ''
  },
  address: {
    fullAddress: '',
    city: '',
    department: ''
  }
};

export const validationSchema = {
  name: yup.string().required(i18n.t('validations.required')),
  email: yup.string().required(i18n.t('validations.required')),
  legalPerson: yup.boolean(),
  companyName: yup.string().when('legalPerson', {
    is: true,
    then: schema => schema.required(i18n.t('validations.required')),
    otherwise: schema => schema.min(0)
  }),
  identificationNumberType: yup.string().required(i18n.t('validations.required')),
  identificationNumber: customYup.string().required(i18n.t('validations.required')),
  phoneNumbers: yup.object({
    phoneNumber: customYup.string(),
    mobileNumber: customYup.string().colombiaMobilePhone().required(i18n.t('validations.required'))
  }),
  address: yup.object({
    fullAddress: yup.string().required(i18n.t('validations.required')),
    city: customYup.string().choosenCity().required(i18n.t('validations.required'))
  })
};
