import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/slices';

import * as Slice from '../store/slices/farmersSlice';

export const useFarmers = () => {
  const dispatch = useDispatch();

  const {
    farmers,
    selectedFarmer,
    farms,
    employees,
    farmEmployees,
    selectedFarm,
    landplots,
    pagesCount,
    farmerLoading,
    isLoading,
    isLoadingFarms,
    totalFarmers,
    farmerTabIndex,
    farmerPageIndex,
    searchedFarmer,
    searchedFarm
  } = useSelector(({ farmers }: RootState) => farmers);

  const listFarmers = useCallback(
    (pgSize, pgIndex, searchText?, successCallback?) =>
      dispatch(Slice.LIST_FARMERS({ pgSize, pgIndex, searchText, successCallback })),
    [dispatch]
  );

  const getDetailedFarmer = useCallback(
    (selectedID, successCallback?, failureCallback?) => {
      dispatch(Slice.GET_DETAILED_FARMER({ selectedID, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const listFarms = useCallback(
    (selectedID, searchText?, successCallback?) => {
      dispatch(Slice.LIST_FARMS({ selectedID, searchText, successCallback }));
    },
    [dispatch]
  );

  const getDetailedFarm = useCallback(
    (selectedID, successCallback?) => {
      dispatch(Slice.GET_DETAILED_FARM({ selectedID, successCallback }));
    },
    [dispatch]
  );

  const editFarm = useCallback(
    (selectedID, editedFarm, successCallback?, failureCallback?) => {
      dispatch(Slice.EDIT_FARM({ selectedID, editedFarm, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const deleteFarm = useCallback(
    (selectedID, successCallback?, failureCallback?) => {
      dispatch(Slice.DELETE_FARM({ selectedID, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const editFarmer = useCallback(
    (selectedID, editedFarmer, successCallback?, failureCallback?) => {
      dispatch(Slice.EDIT_FARMER({ selectedID, editedFarmer, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const deleteFarmer = useCallback(
    (selectedID, successCallback?) => {
      dispatch(Slice.DELETE_FARMER({ selectedID, successCallback }));
    },
    [dispatch]
  );

  const listLandPlots = useCallback(
    (selectedID, searchText?, successCallback?) => {
      dispatch(Slice.LIST_LANDPLOTS({ selectedID, searchText, successCallback }));
    },
    [dispatch]
  );

  const editLandplot = useCallback(
    (selectedID, editedLandplot, successCallback?, failureCallback?) => {
      dispatch(
        Slice.EDIT_LANDPLOT({ selectedID, editedLandplot, successCallback, failureCallback })
      );
    },
    [dispatch]
  );

  const deleteLandplot = useCallback(
    (selectedID, successCallback?, failureCallback?) => {
      dispatch(Slice.DELETE_LANDPLOT({ selectedID, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const listEmployees = useCallback(
    (selectedID, searchText?, successCallback?) => {
      dispatch(Slice.LIST_EMPLOYEES({ selectedID, searchText, successCallback }));
    },
    [dispatch]
  );

  const listFarmEmployees = useCallback(
    (selectedID, searchText?, successCallback?) => {
      dispatch(Slice.LIST_FARM_EMPLOYEES({ selectedID, searchText, successCallback }));
    },
    [dispatch]
  );

  const editEmployee = useCallback(
    (selectedID, editedEmployee, successCallback?, failureCallback?) => {
      dispatch(
        Slice.EDIT_EMPLOYEE({ selectedID, editedEmployee, successCallback, failureCallback })
      );
    },
    [dispatch]
  );

  const deleteEmployee = useCallback(
    (selectedID, successCallback?, failureCallback?) => {
      dispatch(Slice.DELETE_EMPLOYEE({ selectedID, successCallback, failureCallback }));
    },
    [dispatch]
  );

  const editFarmerTabIndex = useCallback(
    id => dispatch(Slice.EDIT_FARMER_TAB_INDEX(id)),
    [dispatch]
  );

  const editFarmerPageIndex = useCallback(
    index => dispatch(Slice.EDIT_FARMER_PAGE_INDEX(index)),
    [dispatch]
  );

  const editSearchedFarmerText = useCallback(
    text => dispatch(Slice.EDIT_SEARCHED_FARMER(text)),
    [dispatch]
  );

  const editSearchedFarmText = useCallback(
    text => dispatch(Slice.EDIT_SEARCHED_FARM(text)),
    [dispatch]
  );

  return {
    farmers,
    selectedFarmer,
    farms,
    employees,
    farmEmployees,
    selectedFarm,
    landplots,
    pagesCount,
    totalFarmers,
    listFarmers,
    getDetailedFarmer,
    listFarms,
    getDetailedFarm,
    listLandPlots,
    listEmployees,
    listFarmEmployees,
    editFarmer,
    deleteFarmer,
    farmerLoading,
    isLoading,
    isLoadingFarms,
    farmerTabIndex,
    farmerPageIndex,
    searchedFarmer,
    searchedFarm,
    editFarm,
    editLandplot,
    deleteLandplot,
    deleteEmployee,
    deleteFarm,
    editEmployee,
    editFarmerTabIndex,
    editFarmerPageIndex,
    editSearchedFarmerText,
    editSearchedFarmText
  };
};
