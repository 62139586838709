import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type LabelProps = {
  disabled?: boolean;
};

export const Container = styled.div<CSSProperties>`
  ${({ width, theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    width: ${width}%;
    padding-right: ${spacing.sm};
  `};
`;

export const Label = styled.label<LabelProps>`
  ${({ theme: { colors, typography }, disabled }) => css`
    font-weight: ${typography.fontWeight.bold};
    font-size: ${typography.fontSize.xs};
    line-height: 22px;
    margin-bottom: -22px;
    color: ${disabled ? colors.neutral.dark : colors.black};
  `};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
