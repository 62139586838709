import * as yup from 'yup';
import i18n from '../../services/translations';

export type UserType = {
  name: string;
  email: string;
  phoneNumber: string;
  role: string;
  editPermission: string[];
  viewPermission: string[];
};

export const userDefaultValues: UserType = {
  name: '',
  email: '',
  phoneNumber: '',
  role: '',
  editPermission: [],
  viewPermission: []
};

export const validationSchema = yup.object({
  name: yup.string().required(i18n.t('errors.name')),
  email: yup
    .string()
    .matches(/^.*@.*basf[.]com.*$/, i18n.t('errors.basfMail'))
    .required(i18n.t('errors.mail')),
  phoneNumber: yup
    .string()
    .matches(/^[+]*[0-9 ]{7,18}$/, i18n.t('validations.invalidNumber'))
    .required(i18n.t('errors.phone')),
  role: yup.string().required(i18n.t('errors.role')),
  editPermission: yup.array().of(yup.string()),
  viewPermission: yup.array().of(yup.string())
});

export const permissions = [
  { idView: 1, idEdit: 5, name: i18n.t('menu.administrators') },
  { idView: 2, idEdit: 6, name: i18n.t('menu.custody') },
  { idView: 3, idEdit: 7, name: i18n.t('menu.distributors') },
  { idView: 4, idEdit: 8, name: i18n.t('menu.farmers') }
];

export const adminTypes = [
  { id: 1, name: 'Administrator' },
  { id: 2, name: 'Administrator AP' }
];
