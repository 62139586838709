import { useEffect, useState } from 'react';
import moment from 'moment';

import { useTranslation, Button, Pagination, EmptyPage, SearchField } from '@basfagro/core';
import { useEvaluationForms, useAuth } from '../../../hooks';
import * as S from './FormCategories.styles';
import {
  Header,
  ActionTable,
  ConfirmModal,
  NewFormCategoryModal,
  FilterButton,
  FeedbackModal
} from '../../../components';
import Loading from '../../../assets/Loading.gif';

import { creationDateFilter } from '../../../types/filters';

const filterParams = {
  sortBy: 1
};

const FormCategories = () => {
  const { t } = useTranslation();
  const { listFormCategories, categories, deleteFormCategory, totalPages, isLoading } =
    useEvaluationForms();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Administrator');
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedFilter, setFilter] = useState(filterParams);
  const [categoryToEdit, setCategoryToEdit] = useState(
    {} as { id: number; name: string } | undefined
  );
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [FBModal, setFBModal] = useState({
    state: false,
    icon: 'IcPositiveFB',
    text: t('feedback.landPlotDeleted')
  });

  useEffect(() => {
    if (searchText === '') {
      listFormCategories(page, 10, '', selectedFilter['sortBy']);
    } else {
      listFormCategories(page, 10, searchText, selectedFilter['sortBy'], () => {
        setPage(prev => (totalPages < page ? 1 : prev));
      });
    }
  }, [page, listFormCategories, searchText, selectedFilter, totalPages]);

  const onPressEdit = category => {
    setCreateModal(true);
    setCategoryToEdit({ id: category.id, name: category.name });
  };

  const onPressDelete = category => {
    setDeleteModal(true);
    setCategoryToEdit({ id: category.id, name: category.name });
  };

  const onConfirmDelete = () => {
    setDeleteModal(false);
    deleteFormCategory(
      categoryToEdit?.id,
      feedbackText =>
        setFBModal({
          state: true,
          icon: 'IcPositiveFB',
          text: feedbackText
        }),
      feedbackText => setFBModal({ state: true, icon: 'IcNegativeFB', text: feedbackText })
    );
  };

  return (
    <>
      <S.StyledContainer>
        <Header
          title={t('evaluationForm.CategoriesTitle')}
          subtitle={t('evaluationForm.CategoriesSubtitle')}
        />
        {hasPermission && (
          <S.StyledButtonContainer>
            <Button
              buttonType='primary'
              height='42px'
              customColor='secondary.lightest'
              onClick={() => {
                setCategoryToEdit(undefined);
                setCreateModal(true);
              }}
              marginTop='16px'
            >
              + {t('evaluationForm.newFormCategory')}
            </Button>
          </S.StyledButtonContainer>
        )}
        <S.StyledSearchContainer>
          <SearchField
            placeholder={t('riteCampaign.searchCategory')}
            onChangeText={setSearchText}
            marginTop='24px'
            marginBottom='16px'
            marginRight='16px'
          />
          <FilterButton
            filterOptions={creationDateFilter}
            selectedDefault={selectedFilter}
            onClickFilter={a => setFilter(a)}
          />
        </S.StyledSearchContainer>

        {isLoading ? (
          <S.StyledLoading src={Loading} />
        ) : categories.length ? (
          <ActionTable
            tableData={categories}
            headers={{
              name: t('evaluationForm.categoryName'),
              creationDate: t('evaluationForm.creationDate'),
              updateDate: t('evaluationForm.updateDate')
            }}
            onPressEdit={onPressEdit}
            onPressDelete={onPressDelete}
            formatRow={{
              creationDate: v => moment(v).format('DD/MM/YYYY'),
              updateDate: v => (v ? moment(v).format('DD/MM/YYYY') : '--/--/----')
            }}
            hasPermission={!!hasPermission}
          />
        ) : (
          <EmptyPage />
        )}
        <Pagination pagesTotal={totalPages} page={page} setPage={setPage} />
      </S.StyledContainer>
      {createModal && (
        <NewFormCategoryModal
          visibility={createModal}
          toggleVisibility={setCreateModal}
          categoryToEdit={categoryToEdit}
          hasPermission={!!hasPermission}
        />
      )}
      {deleteModal && (
        <ConfirmModal
          isVisible={deleteModal}
          setVisibility={() => setDeleteModal(prev => !prev)}
          onConfirm={onConfirmDelete}
          title={t('modals.categories.deleteTitle')}
          content={t('modals.categories.deleteContent', { name: categoryToEdit?.name })}
        />
      )}
      {FBModal && (
        <FeedbackModal
          isVisible={FBModal.state}
          setVisibility={() => setFBModal({ ...FBModal, state: !FBModal.state })}
          text={FBModal.text}
          icon={FBModal.icon}
        />
      )}
    </>
  );
};

export default FormCategories;
