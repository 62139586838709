import { useState } from 'react';
import { TextField, useTranslation } from '@basfagro/core';
import * as S from './RecomendationsInput.styles';

import { Icon } from '..';
type RecomendationsInputType = {
  col?: number;
  recomendations: string[];
  onSave: Function;
  disabled?: boolean;
};

const RecomendationsInput = ({
  col = 16,
  recomendations,
  onSave,
  disabled
}: RecomendationsInputType) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');

  const onEnterPressed = e => {
    if (e.key === 'Enter') {
      onSave([...recomendations, inputText]);
      setInputText('');
    }
  };

  const deleteRecomendation = indexToDelete => {
    const aux = recomendations;
    aux.splice(indexToDelete, 1);
    onSave(aux);
  };

  return (
    <S.Container width={(100 / 16) * col}>
      <S.Label disabled={disabled}>{t('riteCampaign.recommendations')}</S.Label>
      <TextField
        placeholder={t('textfields.typeHere')}
        onKeyPress={onEnterPressed}
        value={inputText}
        onChange={e => setInputText(e.target.value)}
        disabled={disabled}
        paddingRight={0}
      />
      <S.BubblesContainer>
        {recomendations.map((value, index) => (
          <S.Bubble key={value}>
            <S.BubbleText>{value}</S.BubbleText>
            <Icon
              color='black'
              icon='IcClose'
              height={23}
              width={23}
              onClick={() => {
                if (!disabled) deleteRecomendation(index);
              }}
            />
          </S.Bubble>
        ))}
      </S.BubblesContainer>
    </S.Container>
  );
};

export default RecomendationsInput;
