import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useTranslation, Pagination, EmptyPage, Button } from '@basfagro/core';

import * as S from './Campaigns.styles';

import { Header, ConfirmModal, CampaignsTable } from '../../../components';
import Loading from '../../../assets/Loading.gif';

import { useCampaigns, useAuth } from '../../../hooks';
import { checkActivityCase } from '../../../useCases/checkActivityUseCase';

const Campaigns = () => {
  const navigate = useNavigate();
  const { campaigns, listCampaigns, changeStatusCampaign, totalPages, isLoading } = useCampaigns();
  const { t } = useTranslation();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Administrator');

  const [choosenCampaign, setCampaign] = useState({
    id: 0,
    description: '',
    varietyId: 0,
    active: false
  });
  const [modal, setModal] = useState({ title: '', content: '', visibility: false });
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    listCampaigns(page);
  }, [listCampaigns, page, changeStatusCampaign]);

  const onPressEdit = useCallback(item => navigate(`form/${item.id}`), [navigate]);

  const onPressChangeStatus = ({ id, description, varietyId, active, varietyName }) => {
    setCampaign({ id: id, description: description, varietyId: varietyId, active: active });
    if (active) {
      setModal({
        title: t('modals.campaigns.deactivateTitle'),
        content: t('modals.campaigns.deactivateContent', {
          name: description,
          variety: varietyName
        }),
        visibility: true
      });
    } else {
      checkActivityCase(
        varietyId,
        hasCampaign => {
          if (hasCampaign) {
            setModal({
              title: t('modals.campaigns.activateTitle'),
              content: t('modals.campaigns.activateAnother', { variety: varietyName }),
              visibility: true
            });
          } else {
            setModal({
              title: t('modals.campaigns.activateTitle'),
              content: t('modals.campaigns.activateContent', {
                name: description
              }),
              visibility: true
            });
          }
        },
        e => {
          toast.error(t('feedback.failure'));
        }
      );
    }
  };

  const [actions, setActions] = useState([
    {
      action: onPressEdit,
      icon: 'IcEdit',
      title: t('buttons.edit')
    },
    {
      action: ({ id, description, varietyId, active, varietyName }) => {
        onPressChangeStatus({ id, description, varietyId, active, varietyName });
      },
      icon: 'IcEye',
      title: t('buttons.status')
    }
  ]);

  useEffect(() => {
    if (!hasPermission) {
      setActions([
        {
          action: onPressEdit,
          icon: 'IcEye',
          title: t('buttons.view')
        }
      ]);
    }
  }, [hasEditPermission]);

  return (
    <>
      <S.StyledContainer>
        <Header
          title={t('riteCampaign.campaign')}
          subtitle={t('modals.campaigns.campaignSubtitle')}
        />
        {hasPermission && (
          <S.StyledButtonContainer>
            <Button
              buttonType='primary'
              height='42px'
              customColor='secondary.lightest'
              onClick={() => navigate('form')}
              marginTop='16px'
            >
              + {t('riteCampaign.newCampaign')}
            </Button>
          </S.StyledButtonContainer>
        )}
        {isLoading ? (
          <S.StyledLoading src={Loading} />
        ) : !!campaigns.length ? (
          <CampaignsTable
            headers={{
              description: t('riteCampaign.campaignName'),
              cultivationName: t('riteCampaign.crop'),
              varietyName: t('riteCampaign.variety'),
              creationDate: t('riteCampaign.creationDate')
            }}
            formatRow={{
              creationDate: v => moment(v).format('DD/MM/YYYY')
            }}
            subHeaders={{
              screen: t('riteCampaign.screen'),
              categoryName: t('riteCampaign.riteName'),
              createdBy: t('riteCampaign.createdBy'),
              creationDate: t('riteCampaign.creationDate')
            }}
            formatSubRow={{
              screen: v => `${t('riteCampaign.screen')} ${v}`,
              creationDate: v => moment(v).format('DD/MM/YYYY')
            }}
            tableData={campaigns}
            subOptionsKey='rites'
            actions={actions}
          />
        ) : (
          <EmptyPage />
        )}
        {!!totalPages && <Pagination pagesTotal={totalPages} page={page} setPage={setPage} />}
      </S.StyledContainer>
      <ConfirmModal
        isVisible={modal.visibility}
        setVisibility={() => setModal({ ...modal, visibility: !modal.visibility })}
        title={modal.title}
        content={modal.content}
        onConfirm={() =>
          changeStatusCampaign(choosenCampaign.id, () => {
            setModal({ ...modal, visibility: !modal.visibility });
            listCampaigns(page);
          })
        }
      />
    </>
  );
};

export default Campaigns;
