import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export type ContainerProps = {
  isSelected: boolean;
  isSecondary: boolean;
  haveSubOptions?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export type TitleProps = {
  isSelected: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Container = styled.button<ContainerProps>`
  ${({ theme: { colors, typography, spacing }, isSelected, isSecondary, haveSubOptions }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 8px);
    margin-left: ${spacing.xs};
    margin-bottom: ${isSecondary ? '0px' : spacing.sm};
    height: 42px;
    padding-left: ${spacing.xs};
    border: 0;
    font-size: ${typography.fontSize.lg};
    font-weight: ${typography.fontWeight.bold};
    cursor: pointer;
    text-align: left;
    background-color: transparent;
    z-index: 1;

    :hover {
      background-color: ${isSecondary ? colors.primary.lightest : 'transparent'};
    }

    ${isSecondary &&
    css`
      :last-of-type {
        margin-bottom: ${spacing.sm};
      }
    `}

    ${!isSecondary &&
    isSelected &&
    haveSubOptions &&
    css`
      margin-bottom: 0px;
    `}

    ${isSecondary &&
    isSelected &&
    css`
      background-color: ${colors.primary.lightest};
    `}
  `};
`;

export const Text = styled.p<TitleProps>`
  ${({ theme: { colors, typography }, isSelected }) => css`
    color: ${colors.neutral.darkest};
    font-size: ${typography.fontSize.xm};
    line-height: 24px;
    margin-left: 10px;
    color: ${isSelected ? colors.primary.default : colors.white};
    background-color: transparent;
  `};
`;

export const OptionWrapper = styled(motion.div)`
  width: 85%;
  display: flex;
  flex-direction: column;
`;
