import * as yup from 'yup';
import i18n from '../../../../services/translations';
import customYup from '../../../../utils/customValidation';

export const validationSchema = yup
  .object({
    name: yup.string().required(i18n.t('errors.name')),
    email: yup.string().required(i18n.t('errors.mail')),
    companyName: yup.string().required(i18n.t('errors.name')),
    nit: customYup.string().verifyNIT().required(i18n.t('validations.invalidInfo')),
    department: yup.string().required(i18n.t('validations.required')),
    city: customYup.string().choosenCity().required(i18n.t('errors.selectCity')),
    fullAddress: yup.string().required(i18n.t('errors.address')),
    contactLandline: yup
      .array()
      .of(customYup.string().colombiaPhone())
      .min(1, i18n.t('validations.required'))
      .required(),
    contactMobile: yup
      .array()
      .of(customYup.string().colombiaMobilePhone())
      .min(1, i18n.t('validations.required'))
      .required(),
    contactEmails: yup.array().of(
      yup
        .string()
        .matches(/^.*@.*$/, i18n.t('errors.mail'))
        .required(i18n.t('errors.mail'))
    )
  })
  .required();
