import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation, SearchField, EmptyPage, Button, Pagination } from '@basfagro/core';

import { useEvaluationForms, useAuth } from '../../../hooks/';

import * as S from './Evaluation.styles';
import { Header, ConfirmModal, FilterButton, CampaignsTable } from '../../../components';
import Loading from '../../../assets/Loading.gif';

import { creationDateFilter } from '../../../types/filters';

const filterParams = {
  sortBy: 1
};

export const Evaluation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { forms, totalPages, listEvaluationForms, changeStatusForm, isLoading } =
    useEvaluationForms();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Administrator');
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [choosenForm, setChoosenForm] = useState({ id: 0, status: false, name: 'N/A' });
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [selectedFilter, setFilter] = useState(filterParams);

  useEffect(() => {
    if (searchText === '') {
      listEvaluationForms(page, 10, '', selectedFilter['sortBy']);
    } else {
      listEvaluationForms(page, 10, searchText, selectedFilter['sortBy'], () => {
        setPage(prev => (totalPages < page ? 1 : prev));
      });
    }
  }, [page, listEvaluationForms, searchText, selectedFilter, totalPages]);

  const onPressView = useCallback(
    item => navigate(`form`, { state: { editForm: item } }),
    [navigate]
  );

  const onPressChangeStatus = (form, active, name) => {
    setChoosenForm({ id: form, status: active, name: name });
    setShowConfirmModal(true);
  };

  const onConfirm = () => {
    setShowConfirmModal(false);
    changeStatusForm(choosenForm.id, !choosenForm.status);
  };

  const [actions, setActions] = useState([
    {
      action: onPressView,
      icon: 'IcEye',
      title: t('buttons.view')
    },
    {
      action: ({ id, active, name }) => onPressChangeStatus(id, active, name),
      icon: 'IcEye',
      title: t('buttons.status')
    }
  ]);

  useEffect(() => {
    if (!hasEditPermission('Administrator')) {
      setActions([
        {
          action: onPressView,
          icon: 'IcEye',
          title: t('buttons.view')
        }
      ]);
    }
  }, [hasEditPermission]);

  return (
    <>
      <S.EvaluationWrapper>
        <Header title={t('evaluationForm.title')} subtitle={t('evaluationForm.subtitle')} />
        {hasPermission && (
          <S.StyledButtonContainer>
            <Button
              buttonType='primary'
              height='42px'
              customColor='secondary.lightest'
              onClick={() => navigate('form')}
              marginTop='16px'
            >
              + {t('evaluationForm.newForm')}
            </Button>
          </S.StyledButtonContainer>
        )}
        <S.StyledSearchContainer>
          <SearchField
            placeholder={t('evaluationForm.searchForm')}
            onChangeText={setSearchText}
            marginTop='24px'
            marginBottom='16px'
            marginRight='16px'
          />
          <FilterButton
            filterOptions={creationDateFilter}
            selectedDefault={selectedFilter}
            onClickFilter={a => setFilter(a)}
          />
        </S.StyledSearchContainer>
        {isLoading ? (
          <S.StyledLoading src={Loading} />
        ) : forms.length ? (
          <CampaignsTable
            headers={{
              name: t('evaluationForm.description'),
              createdBy: t('evaluationForm.createdBy'),
              creationDate: t('evaluationForm.creationDate'),
              updateDate: t('evaluationForm.updateDate')
            }}
            formatRow={{
              creationDate: v => moment(v).format('DD/MM/YYYY'),
              updateDate: v => (v ? moment(v).format('DD/MM/YYYY') : '--/--/----')
            }}
            actions={actions}
            tableData={forms}
          />
        ) : (
          <EmptyPage />
        )}
        {!!totalPages && <Pagination page={page} pagesTotal={totalPages} setPage={setPage} />}
      </S.EvaluationWrapper>
      {showConfirmModal && (
        <ConfirmModal
          title={
            choosenForm.status
              ? t('evaluationForm.disableFormTitle')
              : t('evaluationForm.enableFormTitle')
          }
          content={
            choosenForm.status
              ? t('evaluationForm.disableFormSubtitle', { name: choosenForm.name })
              : t('evaluationForm.enableFormSubtitle', { name: choosenForm.name })
          }
          isVisible={showConfirmModal}
          setVisibility={setShowConfirmModal}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default Evaluation;
