import { useKeycloak } from '@react-keycloak/web';

import Router from './routes/router';
import GlobalStyles from './styles/global';

import ThemeProvider from './styles/ThemeProvider';
import { StyledLoading } from './pages/Dashboard/Dashboard.styles';
import { useAuth } from './hooks';

import Loading from './assets/Loading.gif';
import { LoadingWrapper } from './app.styles';

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  const { login, setAuthorizationToken } = useAuth();

  if (initialized && !keycloak.authenticated) {
    login();
  }

  setAuthorizationToken();

  if (!initialized || !keycloak.authenticated)
    return (
      <LoadingWrapper>
        <StyledLoading src={Loading} />
      </LoadingWrapper>
    );

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Router />
    </ThemeProvider>
  );
};

export default App;
