import styled, { css } from 'styled-components';

type RowProp = { flexEnd?: boolean; alignStart?: boolean };

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 16px 16px 16px;
  position: relative;
  background-color: ${({ theme: { colors } }) =>
    colors.colorWithOpacityGenerator('background.default', 0.7)};
`;

export const StyledRow = styled.div<RowProp>`
  ${({ flexEnd, alignStart }) => css`
    display: flex;
    flex-direction: row;
    align-items: ${alignStart ? 'flex-start' : 'flex-end'};
    justify-content: ${flexEnd ? 'flex-end' : 'flex-start'};
  `}
`;

export const StyledError = styled.h2`
  ${({ theme: { colors, typography, spacing } }) => css`
    width: fit-content;

    color: ${colors.tertiary.default};
    font-size: ${typography.fontSize.xm};
    font-weight: ${typography.fontWeight.semiBold};
    line-height: 28px;
    
    background-color: ${colors.colorWithOpacityGenerator('tertiary.lightest', 0.3)}};
    
    padding: ${spacing.xs};
    border-radius: ${spacing.xs};
  `};
`;
