import styled, { css } from 'styled-components';

type CardProps = {
  hasMargin?: number;
};

type TextProps = {
  bold?: boolean;
  withMargin?: boolean;
};

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardWrapper = styled.div<CardProps>`
  ${({ theme: { colors, spacing }, hasMargin }) => css`
    display: flex;
    flex-direction: column;
    width: 204px;
    border: 1px solid ${colors.neutral.default};
    border-radius: 20px;
    padding: 12px ${spacing.sm};
    margin-right: ${hasMargin ? hasMargin : 0}px;
    background-color: ${colors.white};
  `};
`;

export const Text = styled.p<TextProps>`
  ${({ theme: { colors, typography, spacing }, bold = false, withMargin = false }) => css`
    color: ${colors.neutral.darkest};
    font-size: ${typography.fontSize.xm};
    font-weight: ${bold ? typography.fontWeight.bold : typography.fontWeight.regular};
    margin-left: ${withMargin ? 8 : 0}px;
    line-height: ${spacing.md};
  `};
`;
