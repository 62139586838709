import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { maskJs } from 'mask-js';
import { useTranslation, TextField, Select, Button } from '@basfagro/core';

import * as S from './EditDistributor.styles';
import { validationSchema } from './EditDistributor.utils';

import { departmentsList } from '../../../../utils/generalInfo';

import { useDistributors, useGeneralInfo, useAuth } from '../../../../hooks';
import { Icon, Header, ConfirmModal } from '../../../../components';

const EditDistributor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedDistributor, editDistributor } = useDistributors();
  const { listCities, cities } = useGeneralInfo();
  const { hasEditPermission } = useAuth();
  const hasPermission = hasEditPermission('Distributor');

  const [changedD, setDChanged] = useState(false);
  const [confirmModalVisible, setConfirmModalVisibility] = useState<boolean>(false);

  const distributorValues = {
    name: selectedDistributor.name,
    email: selectedDistributor.email,
    companyName: selectedDistributor.companyName,
    nit: selectedDistributor.nit,
    fullAddress: selectedDistributor.address.fullAddress,
    city: selectedDistributor.address.city.name,
    department: selectedDistributor.address.city.department.name,
    contactLandline: selectedDistributor.contactPhones
      .filter(({ type }) => type === 1)
      .map(({ description }) => description),
    contactMobile: selectedDistributor.contactPhones
      .filter(({ type }) => type === 2)
      .map(({ description }) => description),
    contactEmails: selectedDistributor.contactEmails.map(({ description }) => description)
  };

  if (!selectedDistributor.name) {
    navigate('/dashboard/approvations/distributors', { replace: true });
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: distributorValues
  });

  const values = watch();

  const selectedDepartment = useMemo(
    () => departmentsList.find(({ name }) => name === values.department)?.id || -1,
    [values.department]
  );

  useEffect(() => {
    if (selectedDepartment) listCities(selectedDepartment, 1, true);
    if (changedD) {
      setValue('city', t('fields.selectCity'));
    }
  }, [selectedDepartment]);

  const onSubmit = formValues => {
    const distributor = {
      id: selectedDistributor.id,
      email: selectedDistributor.email,
      companyName: formValues.companyName,
      nit: formValues.nit,
      profilePicture: selectedDistributor.profilePicture,
      address: {
        fullAddress: formValues.fullAddress,
        city: cities.find(({ name }) => name === formValues.city)?.id
      },
      contactPhones: formValues.contactLandline
        .map(landline => ({ description: landline, type: 1 }))
        .concat(formValues.contactMobile.map(mobile => ({ description: mobile, type: 2 }))),
      contactEmails: formValues.contactEmails
    };
    editDistributor(distributor, () =>
      navigate('/dashboard/approvations/distributors', { replace: true })
    );
  };

  return (
    <S.StyledContainer>
      <Header title={t('custodyFields.headerTitle')} subtitle={t('custodyFields.headerSubTitle')} />
      <S.FormRow>
        <TextField
          label={t('fields.nit')}
          placeholder={t('textfields.typeNIT')}
          {...register('nit')}
          error={errors.nit}
          readOnly={!hasPermission}
        />
        <TextField
          label={t('distributors.nameField')}
          placeholder={t('textfields.typeName')}
          value={values.name}
          readOnly
        />
        <TextField
          label={t('farmers.nameField')}
          placeholder={t('fields.name')}
          value={values.companyName}
          {...register('companyName')}
          readOnly
        />
      </S.FormRow>
      <S.FieldRow>
        <S.FieldContainer>
          <TextField
            label={t('fields.mail')}
            placeholder={t('textfields.typeEmail')}
            {...register('email')}
            error={errors.email}
            readOnly
          />
        </S.FieldContainer>
        {values.contactEmails.map((number, index) => (
          <S.FieldContainer key={number}>
            <TextField
              label={t('fields.mail')}
              placeholder={t('textfields.typeEmail')}
              {...register(`contactEmails.${index}`)}
              error={errors.contactEmails?.[index]}
              icon='IcTrash'
              onClickIcon={() => {
                if (hasPermission)
                  reset({
                    ...values,
                    contactEmails: values.contactEmails.filter((_, i) => i !== index)
                  });
              }}
              iconColor='tertiary'
              readOnly={!hasPermission}
            />
          </S.FieldContainer>
        ))}
        <S.PlusButton
          onClick={() => {
            if (hasPermission) reset({ ...values, contactEmails: [...values.contactEmails, ''] });
          }}
          disabled={!hasPermission}
        >
          <Icon icon='IcMore' color='black' height={20} width={20} />
        </S.PlusButton>
      </S.FieldRow>
      <S.FieldRow>
        <S.FieldContainer>
          <TextField
            label={t('fields.phone')}
            placeholder={t('textfields.typePhoneNumber')}
            value={maskJs('999 9999', values.contactLandline[0])}
            {...register('contactLandline.0')}
            error={errors.contactLandline?.[0]}
            readOnly={!hasPermission}
          />
        </S.FieldContainer>

        {values.contactLandline.slice(1).map((number, index) => (
          <S.FieldContainer key={number}>
            <TextField
              label={t('fields.phone')}
              placeholder={t('textfields.typePhoneNumber')}
              value={maskJs('999 9999', values.contactLandline[index + 1])}
              {...register(`contactLandline.${index + 1}`)}
              error={errors.contactLandline?.[index + 1]}
              icon='IcTrash'
              onClickIcon={() => {
                if (hasPermission)
                  reset({
                    ...values,
                    contactLandline: values.contactLandline.filter((_, i) => i !== index + 1)
                  });
              }}
              iconColor='tertiary'
              readOnly={!hasPermission}
            />
          </S.FieldContainer>
        ))}
        <S.PlusButton
          onClick={() => {
            if (hasPermission)
              reset({ ...values, contactLandline: [...values.contactLandline, ''] });
          }}
          disabled={!hasPermission}
        >
          <Icon icon='IcMore' color='black' height={20} width={20} />
        </S.PlusButton>
      </S.FieldRow>
      <S.FieldRow>
        <S.FieldContainer>
          <TextField
            label={t('fields.cellphone')}
            placeholder={t('textfields.typeCellPhoneNumber')}
            value={maskJs('999 999 9999', values.contactMobile[0])}
            {...register('contactMobile.0')}
            error={errors.contactMobile?.[0]}
            readOnly={!hasPermission}
          />
        </S.FieldContainer>

        {values.contactMobile.slice(1).map((number, index) => (
          <S.FieldContainer key={number}>
            <TextField
              label={t('fields.cellphone')}
              placeholder={t('textfields.typeCellPhoneNumber')}
              value={maskJs('999 999 9999', values.contactMobile[index + 1])}
              {...register(`contactMobile.${index + 1}`)}
              error={errors.contactMobile?.[index + 1]}
              icon='IcTrash'
              onClickIcon={() => {
                if (hasPermission)
                  reset({
                    ...values,
                    contactMobile: values.contactMobile.filter((_, i) => i !== index + 1)
                  });
              }}
              iconColor='tertiary'
              readOnly={!hasPermission}
            />
          </S.FieldContainer>
        ))}
        <S.PlusButton
          onClick={() => {
            if (hasPermission) reset({ ...values, contactMobile: [...values.contactMobile, ''] });
          }}
          disabled={!hasPermission}
        >
          <Icon icon='IcMore' color='black' height={20} width={20} />
        </S.PlusButton>
      </S.FieldRow>
      <S.FormRow>
        <Select
          label={t('fields.department')}
          optionsList={departmentsList.map(({ name }) => name)}
          col={8}
          value={values.department}
          onSelect={v => {
            setValue('department', v);
            setDChanged(true);
          }}
          error={errors.department}
          disabled={!hasPermission}
        />
        <Select
          label={t('farmers.city')}
          optionsList={cities.map(({ name }) => name)}
          col={8}
          value={values.city}
          onSelect={v => setValue('city', v)}
          error={errors.city}
          disabled={!hasPermission}
        />
      </S.FormRow>
      <S.FormRow>
        <TextField
          label={t('fields.address')}
          placeholder={t('textfields.typeAddress')}
          {...register('fullAddress')}
          error={errors.fullAddress}
          readOnly={!hasPermission}
        />
      </S.FormRow>
      {hasPermission && (
        <S.ButtonsRow>
          <S.ButtonWrapper>
            <Button buttonType='secondary' onClick={() => setConfirmModalVisibility(true)}>
              {t('buttons.discardChanges')}
            </Button>
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button buttonType='primary' type='submit' onClick={handleSubmit(onSubmit)}>
              {t('buttons.saveChanges')}
            </Button>
          </S.ButtonWrapper>
        </S.ButtonsRow>
      )}
      {!hasPermission && (
        <S.ButtonsRow>
          <S.ButtonWrapper>
            <Button
              buttonType='secondary'
              onClick={() => navigate('/dashboard/approvations/distributors', { replace: true })}
            >
              {t('evaluationForm.goBack')}
            </Button>
          </S.ButtonWrapper>
        </S.ButtonsRow>
      )}
      <ConfirmModal
        title={t('modals.distributor.discardChangesTitle')}
        content={t('modals.distributor.discardChangesContent')}
        isVisible={confirmModalVisible}
        setVisibility={setConfirmModalVisibility}
        onConfirm={() => navigate('/dashboard/approvations/distributors', { replace: true })}
      />
    </S.StyledContainer>
  );
};

export default EditDistributor;
