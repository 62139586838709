import * as yup from 'yup';
import i18n from '../../services/translations';

export const SendTokenValidation = yup
  .object({
    email: yup
      .string()
      .matches(/^.*@.*basf[.]com.*$/, i18n.t('errors.basfMail'))
      .required(i18n.t('errors.mail'))
  })
  .required();

export const ValidateTokenValidation = yup
  .object({
    code: yup.string().min(6, i18n.t('reset.wrongCode')).required(i18n.t('reset.enterCode'))
  })
  .required();

export const NewPasswordValidate = yup
  .object({
    password: yup
      .string()
      .matches(/^(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/g, i18n.t('reset.invalidPassword'))
      .required(i18n.t('validations.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], i18n.t('reset.differentPassword'))
      .required(i18n.t('validations.required'))
  })
  .required();
