import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Dashboard from '../pages/Dashboard';
import Custody from '../pages/Approvations/Custody';
import EditCustody from '../pages/Approvations/Custody/EditCustody';
import DistributorsApprovations from '../pages/Approvations/Distributors';
import EditDistribuitor from '../pages/Approvations/Distributors/EditDistributor';
import Farmers from '../pages/Farmers';
import Distributors from '../pages/Distributors';
import AdminCategories from '../pages/Admin/Categories';
import AdminCampaigns from '../pages/Admin/Campaigns';
import AdminCampaignForm from '../pages/Admin/CampaignForm';
import EvaluationForm from '../pages/Admin/EvaluationForm';
import Evaluation from '../pages/Admin/Evaluation';
import FormCategories from '../pages/Admin/FormCategories';
import { Users } from '../pages/Admin/Users';
import FarmerProfile from '../pages/Farmers/FarmerProfile';
import Farm from '../pages/Farmers/Farm';
import SendToken from '../pages/ResetPassword/SendToken';
import GetToken from '../pages/ResetPassword/GetToken';
import Reset from '../pages/ResetPassword/Reset';

import { Menu } from '../components';

const ApprovationRoutes = () => (
  <Routes>
    <Route path='custody' element={<Custody />} />
    <Route path='custody/edit' element={<EditCustody />} />
    <Route path='distributors' element={<DistributorsApprovations />} />
    <Route path='distributors/edit' element={<EditDistribuitor />} />
  </Routes>
);

const AdminRoutes = () => (
  <Routes>
    <Route path='users' element={<Users />} />
    <Route path='categories' element={<AdminCategories />} />
    <Route path='campaigns' element={<AdminCampaigns />} />
    <Route path='campaigns/form' element={<AdminCampaignForm />} />
    <Route path='campaigns/form/:id' element={<AdminCampaignForm />} />
    <Route path='evaluations' element={<Evaluation />} />
    <Route path='evaluations/form' element={<EvaluationForm />} />
    <Route path='form-categories' element={<FormCategories />} />
  </Routes>
);

const FarmerRoutes = () => (
  <Routes>
    <Route path='' element={<Farmers />} />
    <Route path='profile/:id' element={<FarmerProfile />} />
    <Route path='profile/:id/:farm' element={<Farm />} />
  </Routes>
);

const DashboardRoutes = () => (
  <>
    <Menu />
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='approvations/*' element={<ApprovationRoutes />} />
      <Route path='farmers/*' element={<FarmerRoutes />} />
      <Route path='admin/*' element={<AdminRoutes />} />
    </Routes>
  </>
);

const ResetPRoutes = () => (
  <Routes>
    <Route path='' element={<SendToken />} />
    <Route path='/get-token' element={<GetToken />} />
    <Route path='/get-token/reset-password' element={<Reset />} />
  </Routes>
);

const Router = () => {
  return (
    <>
      <Toaster position='bottom-right' reverseOrder={false} />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to='/dashboard' />} />
          <Route path='/send-token/*' element={<ResetPRoutes />} />
          <Route path='dashboard/*' element={<DashboardRoutes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
