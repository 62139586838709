import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  min-height: 180px;
  width: 560px;
`;

export const ModalText = styled.p`
  ${({ theme: { colors, typography, spacing } }) => css`
    color: ${colors.black};
    font-size: ${typography.fontSize.xm};
    line-height: ${typography.fontSize.lg};
    font-weight: ${typography.fontWeight.semiBold};
    margin: ${spacing['2xs']} 0;
  `};
`;
